import * as React from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import {
    Sport,
    TagCategoryWithRelations,
    TagMode,
    TagCategoryCreatePayload,
    TagCreatePayload,
    TagUpdatePayload,
} from "@volley/data";

import fetchApi from "../../../util/fetchApi";
import AdminSaveButton, { SaveState } from "../AdminSaveButton";

import DeleteConfirmation from "./DeleteConfirmation";
import TagEdit from "./TagEdit";

type TagCategoryForm = Omit<
    TagCategoryWithRelations,
    "createdBy" | "updatedBy" | "createdAt" | "updatedAt" | "tags"
> & {
    sports: Sport[];
    tags: (TagCreatePayload | TagUpdatePayload)[];
};

type Action =
    | { type: "name"; value: string }
    | { type: "label"; value: string }
    | { type: "description"; value: string }
    | { type: "homeScreen"; value: boolean }
    | { type: "adminOnly"; value: boolean }
    | { type: "mode"; value: TagMode }
    | { type: "tags"; value: (TagCreatePayload | TagUpdatePayload)[] }
    | { type: "sports"; value: Sport[] }
    | { type: "set"; value: TagCategoryForm };

function reducer(state: TagCategoryForm, action: Action): TagCategoryForm {
    switch (action.type) {
        case "name":
            return { ...state, name: action.value };
        case "label":
            return { ...state, label: action.value };
        case "description":
            return { ...state, description: action.value };
        case "mode":
            return { ...state, mode: action.value };
        case "homeScreen":
            return { ...state, homeScreen: action.value };
        case "adminOnly":
            return { ...state, adminOnly: action.value };
        case "set":
            return { ...action.value };
        case "sports":
            return { ...state, sports: action.value };
        case "tags":
            return { ...state, tags: action.value };
        default:
            throw new Error("Invalid form action type");
    }
}

const defaultState: TagCategoryForm = {
    id: 0,
    name: "",
    label: "",
    description: "",
    mode: "SINGLE",
    homeScreen: false,
    adminOnly: false,
    tagCategorySports: [],
    tags: [],
    sports: [],
};

export default function WorkoutTagEdit(): React.JSX.Element {
    const { id } = useParams<"id">();
    const validId = parseInt(id ?? "", 10);
    const navigate = useNavigate();
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const [saveState, setSaveState] = React.useState(SaveState.Unsaved);
    const [errorMessage, setErrorMessage] = React.useState("");

    const [allSports, setAllSports] = React.useState<Sport[]>([]);

    const [tagEdit, setTagEdit] = React.useState<
        TagCreatePayload | TagUpdatePayload | null
    >(null);
    const [tagDelete, setTagDelete] = React.useState<
        TagCreatePayload | TagUpdatePayload | null
    >(null);

    async function fetchData(tagCategoryId: number) {
        const tagCategory = await fetchApi<TagCategoryWithRelations | null>(
            `/api/tags/categories/${tagCategoryId}`,
        );
        if (!tagCategory) {
            setErrorMessage("Content Provider not found");
            return;
        }

        dispatch({
            type: "set",
            value: {
                ...tagCategory,
                sports: tagCategory.tagCategorySports.map((tcs) => tcs.sport),
                tags: tagCategory.tags.map((t) => ({
                    description: t.description,
                    id: t.id,
                    label: t.label,
                    name: t.name,
                })),
            },
        });
    }

    React.useEffect(() => {
        setErrorMessage("");
        if (validId) {
            fetchData(validId).catch((e: Error) => setErrorMessage(e.message));
        }
    }, [validId]);

    const onSubmit = React.useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            setSaveState(SaveState.Saving);
            setErrorMessage("");
            const payload: TagCategoryCreatePayload = {
                name: state.name.trim(),
                label: state.label.trim(),
                mode: state.mode,
                description: state.description.trim(),
                homeScreen: state.homeScreen,
                adminOnly: state.adminOnly,
                tags: state.tags,
                sports: state.sports.map((s) => ({ id: s.id })),
            };

            try {
                let result: TagCategoryWithRelations;
                if (state.id) {
                    result = await fetchApi(
                        `/api/tags/categories/${state.id}`,
                        "PUT",
                        { ...payload, id: state.id },
                    );
                    setSaveState(SaveState.Saved);
                    dispatch({
                        type: "set",
                        value: {
                            ...result,
                            sports: result.tagCategorySports.map(
                                (tcs) => tcs.sport,
                            ),
                            tags: result.tags.map((t) => ({
                                description: t.description,
                                id: t.id,
                                label: t.label,
                                name: t.name,
                            })),
                        },
                    });
                } else {
                    result = await fetchApi(
                        "/api/tags/categories",
                        "POST",
                        payload,
                    );
                    setSaveState(SaveState.Saved);
                    navigate(`../${result.id.toString()}`, { replace: true });
                }
            } catch (err: unknown) {
                const error = err as Error;
                setErrorMessage(error.message);
                setSaveState(SaveState.Error);
            }
        },
        [state, navigate],
    );

    React.useEffect(() => {
        fetchApi<Sport[]>("/api/sports")
            .then(setAllSports)
            .catch((e: Error) => setErrorMessage(e.message));
    }, []);

    return (
        <Stack>
            <Paper
                sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    "& > :not(style)": { m: 1 },
                }}
                component="form"
                autoComplete="off"
                onSubmit={onSubmit}
            >
                <Grid container>
                    <Grid size={10}>
                        <Typography
                            component="h1"
                            variant="h2"
                            title={`ID: ${state.id || "(New)"}`}
                        >
                            {state.id ? `${state.label}` : "New Tag Category"}
                        </Typography>
                    </Grid>
                    <Grid sx={{ textAlign: "right" }} size={2}>
                        <IconButton size="large" component={RouterLink} to="..">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                {errorMessage && (
                    <Typography color="error.main">{errorMessage}</Typography>
                )}
                <TextField
                    id="name"
                    label="Name"
                    value={state.name}
                    onChange={(e) =>
                        dispatch({ type: "name", value: e.currentTarget.value })
                    }
                />
                <TextField
                    id="name"
                    label="Label"
                    value={state.label}
                    onChange={(e) =>
                        dispatch({
                            type: "label",
                            value: e.currentTarget.value,
                        })
                    }
                />
                <TextField
                    id="description"
                    label="Description"
                    value={state.description}
                    onChange={(e) =>
                        dispatch({
                            type: "description",
                            value: e.currentTarget.value,
                        })
                    }
                />
                <FormControl>
                    <InputLabel id="modeLabel">Mode</InputLabel>
                    <Select
                        labelId="modeLabel"
                        id="mode"
                        label="Mode"
                        onChange={({ target: { value } }) =>
                            dispatch({ type: "mode", value: value as TagMode })
                        }
                        value={state.mode}
                    >
                        <MenuItem value="SINGLE">Single</MenuItem>
                        <MenuItem value="MULTIPLE">Multiple</MenuItem>
                    </Select>
                </FormControl>
                <Autocomplete
                    id="sports"
                    multiple
                    fullWidth
                    options={allSports}
                    autoComplete
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    getOptionLabel={(s) => s.label}
                    noOptionsText="No Sports"
                    value={state.sports}
                    onChange={(_event, value: Sport[]) => {
                        dispatch({
                            type: "sports",
                            value,
                        });
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Sports"
                            slotProps={{
                                input: {
                                    ...params.InputProps,
                                },
                            }}
                        />
                    )}
                />
                <FormControl>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={state.homeScreen}
                                onChange={(e) =>
                                    dispatch({
                                        type: "homeScreen",
                                        value: e.target.checked,
                                    })
                                }
                            />
                        }
                        label="Include on Home Screen"
                    />
                </FormControl>
                <FormControl>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={state.adminOnly}
                                onChange={(e) =>
                                    dispatch({
                                        type: "adminOnly",
                                        value: e.target.checked,
                                    })
                                }
                            />
                        }
                        label="Admin Only"
                    />
                </FormControl>
                <Typography variant="h6">Associated Tags</Typography>
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    flexWrap="wrap"
                >
                    {state.tags.map((tag) => (
                        <Chip
                            key={tag.name}
                            variant="outlined"
                            label={tag.label}
                            onDelete={() => setTagDelete(tag)}
                            onClick={() => setTagEdit({ ...tag })}
                        />
                    ))}
                    {state.tags.length === 0 && (
                        <Typography variant="body2" color="text.secondary">
                            No Tags yet... Click + to add a new tag.
                        </Typography>
                    )}
                    <IconButton
                        onClick={() =>
                            setTagEdit({
                                description: "",
                                label: "",
                                name: "",
                            })
                        }
                    >
                        <AddCircleOutlineIcon />
                    </IconButton>
                </Stack>
                <Box
                    component="div"
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                    <Stack spacing={2} direction="row">
                        <AdminSaveButton
                            type="submit"
                            variant="contained"
                            saveState={saveState}
                        />
                        <Button component={RouterLink} to="..">
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Paper>
            <TagEdit
                onClose={() => setTagEdit(null)}
                onFinish={(t) => {
                    let updated = [...state.tags];
                    if ((t as TagUpdatePayload).id) {
                        updated = updated.filter(
                            (et) =>
                                (t as TagUpdatePayload).id !==
                                (et as TagUpdatePayload).id,
                        );
                        updated.push(t as TagUpdatePayload);
                    } else {
                        updated.push(t as TagCreatePayload);
                    }
                    dispatch({ type: "tags", value: updated });
                    setSaveState(SaveState.Unsaved);
                    setTagEdit(null);
                }}
                onUpdate={(t) => setTagEdit(t)}
                tag={tagEdit}
            />
            <DeleteConfirmation
                name={tagDelete?.label}
                onClose={() => setTagDelete(null)}
                onConfirm={() => {
                    const updated = state.tags.filter((t) => {
                        if (!tagDelete) return true;
                        if ((tagDelete as TagUpdatePayload).id) {
                            return (
                                (t as TagUpdatePayload).id !==
                                (tagDelete as TagUpdatePayload).id
                            );
                        }

                        return (
                            t.name !== (tagDelete as TagCreatePayload).name &&
                            t.label !== (tagDelete as TagCreatePayload).label &&
                            t.description !==
                                (tagDelete as TagCreatePayload).description
                        );
                    });
                    dispatch({ type: "tags", value: updated });
                    setTagDelete(null);
                }}
            />
        </Stack>
    );
}
