import * as React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

export default function AdminLoading({
    page = "",
}: {
    page?: string;
}): React.JSX.Element {
    return (
        <Box display="flex" alignItems="center" flexDirection="column">
            {!!page && (
                <Typography
                    variant="h3"
                    component="h2"
                >{`Loading ${page}`}</Typography>
            )}
            <CircularProgress size={60} sx={{ m: 2 }} />
        </Box>
    );
}
