import * as React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import EngineeringIcon from "@mui/icons-material/Engineering";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import LogoutIcon from "@mui/icons-material/Logout";
import PowerSettingsIcon from "@mui/icons-material/PowerSettingsNew";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import VideocamIcon from "@mui/icons-material/Videocam";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type { CoachStatus } from "@volley/shared/coach-models";

import { fetchApi } from "../../../util";
import SplitButton from "../../common/SplitButton";
import { useTrainerFeaturesAdmin } from "../../hooks/useTrainerFeatures";

const updateOptions = [
    "Update all software",
    "Update control only",
    "Update vision only",
];
const updateKeys = ["all", "control", "vision"];

export default function TrainerEditControls({
    clientId,
    ready,
    session,
    trainer,
    vision,
    update,
}: CoachStatus): React.JSX.Element {
    const availableFeatures = useTrainerFeaturesAdmin(clientId);

    const [errorMessage, setErrorMessage] = React.useState("");

    const [endingSession, setEndingSession] = React.useState(false);
    const endSession = React.useCallback(async () => {
        if (!session) {
            return false;
        }

        if (
            !window.confirm(
                `Are you sure you want to end the session for ${session.users[0]?.name ?? "unknown"}?`,
            )
        ) {
            return false;
        }

        setEndingSession(true);
        try {
            await fetchApi(`/api/sessions/${session.id}/close`, "PUT");
            return true;
        } catch (err: unknown) {
            setErrorMessage((err as Error).message);
        } finally {
            setEndingSession(false);
        }

        return false;
    }, [session]);

    const onEndSession = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            await endSession();
        },
        [endSession],
    );

    const [poweringOff, setPoweringOff] = React.useState(false);
    const onPowerOff = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            if (
                !window.confirm(
                    `Are you sure you want to power off trainer ${clientId}?`,
                )
            ) {
                return;
            }

            setPoweringOff(true);
            try {
                await fetchApi(`/trainer/${clientId}/api/shutdown`, "POST", {
                    soft: false,
                });
            } catch (err: unknown) {
                setErrorMessage((err as Error).message);
            } finally {
                setPoweringOff(false);
            }
        },
        [clientId],
    );

    const [uploadingLogs, setUploadingLogs] = React.useState(false);
    const onUploadLogs = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            setUploadingLogs(true);
            try {
                await fetchApi(`/trainer/${clientId}/api/sync-data`, "POST");
            } catch (err: unknown) {
                setErrorMessage((err as Error).message);
            } finally {
                setUploadingLogs(false);
            }
        },
        [clientId],
    );

    const [enteringMaintenanceMode, setEnteringMaintenanceMode] =
        React.useState(false);
    const onEnterMaintenance = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            try {
                if (session) {
                    const sessionEnded = await endSession();
                    if (!sessionEnded) {
                        return;
                    }
                }
                await fetchApi(`/trainer/${clientId}/api/maintenance`, "POST");
                setEnteringMaintenanceMode(true);
            } catch (err: unknown) {
                setErrorMessage((err as Error).message);
                setEnteringMaintenanceMode(false);
            }
        },
        [clientId, endSession, session],
    );

    React.useEffect(() => {
        if (enteringMaintenanceMode && ready === "MAINTENANCE") {
            setEnteringMaintenanceMode(false);
        }
    }, [ready, enteringMaintenanceMode]);

    const [exitingMaintenanceMode, setExitingMaintenanceMode] =
        React.useState(false);
    const onExitMaintenance = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            try {
                await fetchApi(
                    `/trainer/${clientId}/api/maintenance`,
                    "DELETE",
                );
                setExitingMaintenanceMode(true);
            } catch (err: unknown) {
                setErrorMessage((err as Error).message);
                setExitingMaintenanceMode(false);
            }
        },
        [clientId],
    );

    React.useEffect(() => {
        if (exitingMaintenanceMode && (ready === "READY" || ready === "IDLE")) {
            setExitingMaintenanceMode(false);
        }
    }, [ready, exitingMaintenanceMode]);

    const [updating, setUpdating] = React.useState(false);
    const onUpdate = React.useCallback(
        async (option: string) => {
            const optionIndex = updateOptions.findIndex((o) => o === option);
            const selectedOptionKey = optionIndex === -1 ? 0 : optionIndex;
            try {
                setUpdating(true);
                await fetchApi(
                    `/trainer/${clientId}/api/update-software`,
                    "POST",
                    { systems: updateKeys[selectedOptionKey] },
                );
                setUpdating(false);
            } catch (err: unknown) {
                setErrorMessage((err as Error).message);
                setUpdating(false);
            }
        },
        [clientId],
    );

    const [cancelingUpdate, setCancelingUpdate] = React.useState(false);
    const onCancelUpdate = React.useCallback(async () => {
        try {
            setCancelingUpdate(true);
            await fetchApi(`/trainer/${clientId}/api/cancel-update`, "POST");
            setCancelingUpdate(false);
        } catch (err: unknown) {
            setErrorMessage((err as Error).message);
            setCancelingUpdate(false);
        }
    }, [clientId]);

    const [rebooting, setRebooting] = React.useState(false);
    const onReboot = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            try {
                setRebooting(true);
                await fetchApi(`/trainer/${clientId}/api/reboot`, "POST");
            } catch (err: unknown) {
                setErrorMessage((err as Error).message);
            }
        },
        [clientId],
    );

    const [capturingDebugClip, setCapturingDebugClip] = React.useState(false);
    const onCaptureDebugClip = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            try {
                setCapturingDebugClip(true);
                await fetchApi(
                    `/trainer/${clientId}/api/vision/capture`,
                    "POST",
                    { duration: 10 },
                );
            } catch (err: unknown) {
                setErrorMessage((err as Error).message);
            } finally {
                setCapturingDebugClip(false);
            }
        },
        [clientId],
    );

    return (
        <Stack spacing={2}>
            <Typography variant="h2">Control</Typography>
            {!!errorMessage && (
                <Typography color="error.main">{errorMessage}</Typography>
            )}
            <Stack
                spacing={2}
                direction={{ xs: "column", md: "row" }}
                alignItems="center"
                justifyContent="space-evenly"
            >
                <Button
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={onPowerOff}
                    loading={poweringOff}
                    startIcon={<PowerSettingsIcon />}
                >
                    Power Off
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={onReboot}
                    loading={rebooting}
                    startIcon={<RestartAltIcon />}
                >
                    Reboot
                </Button>
                <Button
                    variant="contained"
                    color="info"
                    fullWidth
                    disabled={vision.serviceState !== "Running"}
                    title={
                        vision.serviceState !== "Running"
                            ? "Vision service is not running"
                            : undefined
                    }
                    onClick={onCaptureDebugClip}
                    loading={capturingDebugClip}
                    startIcon={<VideocamIcon />}
                >
                    Capture Clip
                </Button>
                {ready !== "MAINTENANCE" && (
                    <Button
                        variant="contained"
                        color="warning"
                        fullWidth
                        onClick={onEnterMaintenance}
                        loading={enteringMaintenanceMode}
                        startIcon={<EngineeringIcon />}
                        disabled={!["IDLE", "READY"].includes(ready as string)}
                    >
                        Maint. Mode
                    </Button>
                )}
                {ready === "MAINTENANCE" && (
                    <Button
                        variant="contained"
                        color="error"
                        fullWidth
                        onClick={onExitMaintenance}
                        loading={exitingMaintenanceMode}
                        startIcon={<CancelIcon />}
                    >
                        Exit Maint. Mode
                    </Button>
                )}
                {availableFeatures.includes("uploadLogsRemote") && (
                    <Button
                        variant="contained"
                        color="info"
                        fullWidth
                        onClick={onUploadLogs}
                        loading={uploadingLogs}
                        startIcon={<FolderCopyIcon />}
                    >
                        Upload Logs
                    </Button>
                )}
                {!!session && (
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={onEndSession}
                        loading={endingSession}
                        startIcon={<LogoutIcon />}
                    >
                        End Session
                    </Button>
                )}
            </Stack>
            {ready === "MAINTENANCE" && (
                <Stack
                    spacing={2}
                    direction="row"
                    alignItems="center"
                    justifyContent="start"
                >
                    {trainer.battery.level < 35 && (
                        <Typography variant="body1" color="warning.main">
                            Battery level is low. Unless configured otherwise,
                            update will fail.
                        </Typography>
                    )}
                    <>
                        <SplitButton
                            options={updateOptions}
                            onClick={onUpdate}
                            loading={updating || update.inProgress}
                        />
                        {update.inProgress && (
                            <Button
                                variant="contained"
                                color="error"
                                fullWidth
                                disabled={!update.cancelable}
                                onClick={onCancelUpdate}
                                loading={cancelingUpdate}
                                loadingPosition="end"
                            >
                                Cancel Update
                            </Button>
                        )}
                    </>
                </Stack>
            )}
        </Stack>
    );
}
