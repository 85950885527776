import * as React from "react";
import { Link as RouterLink } from "react-router";

import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import type { CoachStatus } from "@volley/shared/coach-models";

import usePaginatedData from "../../hooks/usePaginatedData";
import AdminLoading from "../AdminLoading";

import TrainerSoftwareVersionDisplay from "./TrainerSoftwareVersionDisplay";
import TrainerStatusIcon from "./TrainerStatusIcon";
import { TrainerWithStatus } from "./types";

interface Props {
    locationId?: number;
}

export default function TrainersTable({
    locationId,
}: Props): React.JSX.Element {
    const {
        rowsPerPage,
        onRowsPerPageChange,
        count,
        page,
        onPageChange,
        loading,
        data: trainers,
    } = usePaginatedData<TrainerWithStatus>("/api/trainers", {
        id: "trainers",
        params: locationId ? { locationId: String(locationId) } : undefined,
        initialRowsPerPage: 100,
    });

    if (loading) return <AdminLoading />;

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Status</TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell>Pair Code</TableCell>
                        <TableCell>Model</TableCell>
                        <TableCell>Availability</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Tag</TableCell>
                        <TableCell>Version</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {trainers.map((t) => {
                        const tag = t.softwareTagTrainers.find(
                            (stt) =>
                                stt.active &&
                                stt.softwareTag.platform.type === "control",
                        )?.softwareTag;
                        const snapshot = t.statusSnapshot?.data as
                            | Partial<CoachStatus>
                            | undefined;
                        const label = snapshot?.softwareVersion;
                        return (
                            <TableRow
                                key={t.id}
                                style={{
                                    backgroundColor: t.outOfOrderBy
                                        ? "#f1959b"
                                        : "transparent",
                                }}
                            >
                                <TableCell>
                                    <Button
                                        component={RouterLink}
                                        to={`/admin/trainers/${t.id}`}
                                    >
                                        View
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <TrainerStatusIcon status={t.status} />
                                </TableCell>
                                <TableCell>{t.clientId}</TableCell>
                                <TableCell>
                                    {t.referenceNumber ?? "–"}
                                </TableCell>
                                <TableCell>
                                    {t.trainerModel?.name ?? "–"}
                                </TableCell>
                                <TableCell>{t.availability ?? "–"}</TableCell>
                                <TableCell>
                                    <Link
                                        component={RouterLink}
                                        to={`/admin/locations/${t.locationId}`}
                                    >
                                        {t.location.name}
                                    </Link>
                                </TableCell>
                                <TableCell>{tag?.name ?? "–"}</TableCell>
                                <TableCell>
                                    <TrainerSoftwareVersionDisplay
                                        label={label}
                                        truncate
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPage={rowsPerPage}
                            count={count}
                            page={page}
                            onPageChange={onPageChange}
                            onRowsPerPageChange={onRowsPerPageChange}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}
