import * as React from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { addDays } from "date-fns";

import type { TrainerMutePayload } from "@volley/data";

import { fetchApi, usDateFormat } from "../../../util";

const DAYS_TO_MUTE = 2;

interface Props {
    trainerId: number;
    mutedUntil: Date | null;
    disabled?: boolean;
    onChangeMutedUntil: (mutedUntil: Date | null) => void;
    onError: (message: string) => void;
}

export default function TrainerEditMute({
    trainerId,
    mutedUntil,
    disabled = false,
    onChangeMutedUntil,
    onError,
}: Props): React.JSX.Element {
    const onMute = React.useCallback(async () => {
        try {
            const payload: TrainerMutePayload = {
                mutedUntil: mutedUntil
                    ? null
                    : addDays(new Date(), DAYS_TO_MUTE).toISOString(),
            };
            const result = await fetchApi<{ mutedUntil: string | null }>(
                `/api/trainers/${trainerId}/mute-until`,
                "POST",
                payload,
            );
            onChangeMutedUntil(
                result.mutedUntil ? new Date(result.mutedUntil) : null,
            );
        } catch (err: unknown) {
            if (err instanceof Error) {
                onError(err.message);
            } else {
                onError("Unknown error muting or un-muting trainer");
            }
        }
    }, [trainerId, mutedUntil, onChangeMutedUntil, onError]);

    return (
        <FormControlLabel
            control={
                <Switch
                    name="mute"
                    color="warning"
                    checked={!!mutedUntil}
                    disabled={disabled}
                    onChange={onMute}
                />
            }
            label={
                mutedUntil
                    ? `Muted until ${usDateFormat(mutedUntil)}`
                    : `Press to mute for ${DAYS_TO_MUTE} days`
            }
            disabled={disabled}
        />
    );
}
