import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export enum ButtonColor {
    Error = "error",
    Inherit = "inherit",
    Primary = "primary",
    Secondary = "secondary",
    Info = "info",
    Success = "success",
    Warning = "warning",
}

interface Props {
    agreeButtonColor?: ButtonColor;
    agreeButtonText?: string;
    autoFocus?: boolean;
    dialogContentText: string;
    dialogTitleText: string;
    disagreeButtonColor?: ButtonColor;
    disagreeButtonText?: string;
    errorMsg?: string | null;
    open: boolean;
    handleClose?: () => void;
    handleSubmit: () => void;
}

export default function AlertDialog({
    agreeButtonColor = ButtonColor.Primary,
    agreeButtonText = "agree",
    autoFocus = true,
    dialogContentText,
    dialogTitleText,
    disagreeButtonColor = ButtonColor.Primary,
    disagreeButtonText = "disagree",
    errorMsg = null,
    open,
    handleClose = () => {},
    handleSubmit,
}: Props) {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{dialogTitleText}</DialogTitle>
            <DialogContent>
                <DialogContentText align="left">
                    {errorMsg && (
                        <span style={{ color: "red" }}>{errorMsg}</span>
                    )}
                    {dialogContentText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color={disagreeButtonColor}>
                    {disagreeButtonText}
                </Button>
                <Button
                    onClick={handleSubmit}
                    color={agreeButtonColor}
                    autoFocus={autoFocus}
                >
                    {agreeButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
