import * as React from "react";

import CircleIcon from "@mui/icons-material/Circle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { formatDuration, intervalToDuration } from "date-fns";

import type { SystemStatusWifi } from "@volley/shared/coach-models";

import WifiStatusIcon from "../../common/WifiStatusIcon";

interface Props {
    wifi: SystemStatusWifi | null;
    activeInterface: string | null;
}

const WIFI_INTERFACE_NAME = "wlan0";

export default function TrainerWifiStatus({
    wifi,
    activeInterface,
}: Props): React.JSX.Element {
    const handleCopyClick = async () => {
        await navigator.clipboard.writeText(wifi?.ipAddress || "");
    };

    return (
        <Stack justifyContent="center">
            <Stack direction="row" alignItems="center">
                {activeInterface === WIFI_INTERFACE_NAME && (
                    <Box>
                        <CircleIcon color="success" sx={{ mr: 1 }} />
                    </Box>
                )}
                <Typography variant="h3" mb={1}>
                    WiFi Status
                </Typography>
                <Box component="div" sx={{ ml: 1 }}>
                    <WifiStatusIcon wifiStatus={wifi ?? null} />
                </Box>
            </Stack>

            {wifi && wifi.essid ? (
                <Table>
                    <TableBody>
                        <TableRow sx={{ "& th": { fontWeight: 500 } }}>
                            <TableCell component="th" sx={{ width: "25%" }}>
                                Connected SSID
                            </TableCell>
                            <TableCell>{wifi ? wifi.essid : "-"}</TableCell>
                        </TableRow>

                        <TableRow sx={{ "& th": { fontWeight: 500 } }}>
                            <TableCell component="th" sx={{ width: "25%" }}>
                                IP Address
                            </TableCell>
                            <TableCell>
                                {wifi ? (
                                    <Box component="div">
                                        {wifi.ipAddress}
                                        <IconButton
                                            size="small"
                                            sx={{ ml: 1 }}
                                            onClick={handleCopyClick}
                                            title="Copy IP Address to Clipboard"
                                        >
                                            <ContentCopyIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                ) : (
                                    "-"
                                )}
                            </TableCell>
                        </TableRow>

                        <TableRow sx={{ "& th": { fontWeight: 500 } }}>
                            <TableCell component="th" sx={{ width: "25%" }}>
                                Connected Time
                            </TableCell>
                            <TableCell>
                                {wifi?.connectedTime
                                    ? formatDuration(
                                          intervalToDuration({
                                              start: 0,
                                              end: wifi.connectedTime * 1000,
                                          }),
                                      )
                                    : "-"}
                            </TableCell>
                        </TableRow>

                        <TableRow sx={{ "& th": { fontWeight: 500 } }}>
                            <TableCell component="th" sx={{ width: "25%" }}>
                                Signal (dBm)
                            </TableCell>
                            <TableCell>{wifi.signal}</TableCell>
                        </TableRow>

                        <TableRow sx={{ "& th": { fontWeight: 500 } }}>
                            <TableCell component="th" sx={{ width: "25%" }}>
                                Monitor Ping (ms)
                            </TableCell>
                            <TableCell>{wifi.monitorPing}</TableCell>
                        </TableRow>

                        <TableRow sx={{ "& th": { fontWeight: 500 } }}>
                            <TableCell component="th" sx={{ width: "25%" }}>
                                Monitor Score
                            </TableCell>
                            <TableCell>{wifi.score}</TableCell>
                        </TableRow>

                        {wifi.txFailed !== undefined && (
                            <TableRow sx={{ "& th": { fontWeight: 500 } }}>
                                <TableCell component="th" sx={{ width: "25%" }}>
                                    Tx Failures
                                </TableCell>
                                <TableCell>{wifi.txFailed}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            ) : (
                <Typography>
                    No WiFi connection or WiFi status not available.
                </Typography>
            )}
        </Stack>
    );
}
