import * as React from "react";
import { Link as RouterLink, useParams } from "react-router";

import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import type { TrainerFailureWithRelations } from "@volley/data";

import { usDateFormat } from "../../../util";
import fetchApi from "../../../util/fetchApi";

type JsonValue = TrainerFailureWithRelations["status"];

const defaultState: TrainerFailureWithRelations = {
    id: 0,
    uuid: "",
    softwareVersion: "",
    trainer: { clientId: 0, name: "" },
    trainerId: 0,
    sessionId: null,
    message: "",
    source: "",
    type: "",
    timestamp: new Date(),
    context: {},
    createdAt: new Date(),
    createdBy: "",
    updatedAt: new Date(),
    updatedBy: "",
    status: {},
};

export default function FailureView(): React.JSX.Element {
    const { id } = useParams<"id">();
    const [state, setState] = React.useState(defaultState);

    async function fetchFailure(failureId: number) {
        const data = await fetchApi<TrainerFailureWithRelations>(
            `/api/failures/${failureId}`,
        );
        setState(data);
    }

    React.useEffect(() => {
        const validId = parseInt(id ?? "", 10);
        if (validId) {
            void fetchFailure(validId);
        }
    }, [id]);

    const handleCopyClick = async (obj: JsonValue) => {
        await navigator.clipboard.writeText(
            obj ? JSON.stringify(obj, undefined, 2) : "",
        );
    };

    return (
        <Grid container spacing={3}>
            <Grid size={12}>
                <Paper
                    sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        "& > :not(style)": { m: 1 },
                    }}
                >
                    <Grid container>
                        <Grid size={10}>
                            <Typography
                                component="h1"
                                variant="h2"
                                title={`ID: ${state.id}`}
                            >
                                {`Failure ${state.uuid}`}
                            </Typography>
                        </Grid>
                        <Grid sx={{ textAlign: "right" }} size={2}>
                            <IconButton
                                size="large"
                                component={RouterLink}
                                to="../.."
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container component="dl" spacing={2}>
                        <Grid
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <Typography component="dt" variant="h6">
                                Trainer ID
                            </Typography>
                            <Typography component="dd" variant="body2">
                                <Link
                                    component={RouterLink}
                                    to={`../../trainers/${state.trainerId}`}
                                    title={state.trainer.name}
                                >
                                    {state.trainer.clientId}
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <Typography component="dt" variant="h6">
                                Session
                            </Typography>
                            <Typography component="dd" variant="body2">
                                {!!state.sessionId && (
                                    <Link
                                        component={RouterLink}
                                        to={`../../sessions/${state.sessionId}`}
                                    >
                                        View
                                    </Link>
                                )}
                            </Typography>
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <Typography component="dt" variant="h6">
                                Timestamp
                            </Typography>
                            <Typography
                                component="dd"
                                variant="body2"
                                title={String(state.timestamp)}
                            >
                                {usDateFormat(new Date(state.timestamp))}
                            </Typography>
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <Typography component="dt" variant="h6">
                                Software Version
                            </Typography>
                            <Typography component="dd" variant="body2">
                                {state.softwareVersion}
                            </Typography>
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <Typography component="dt" variant="h6">
                                Source
                            </Typography>
                            <Typography component="dd" variant="body2">
                                {state.source}
                            </Typography>
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <Typography component="dt" variant="h6">
                                Type
                            </Typography>
                            <Typography component="dd" variant="body2">
                                {state.type}
                            </Typography>
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <Typography component="dt" variant="h6">
                                Message
                            </Typography>
                            <Typography component="dd" variant="body2">
                                {state.message}
                            </Typography>
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <Typography component="dt" variant="h6">
                                Created By
                            </Typography>
                            <Typography component="dd" variant="body2">
                                {state.createdBy}
                            </Typography>
                        </Grid>
                        <Grid size={12}>
                            <Typography component="dt" variant="h6">
                                Context
                                <IconButton
                                    size="small"
                                    sx={{ ml: 1 }}
                                    onClick={() =>
                                        handleCopyClick(state.context)
                                    }
                                    title="Copy IP Address to Clipboard"
                                >
                                    <ContentCopyIcon fontSize="small" />
                                </IconButton>
                            </Typography>
                            <Typography component="dd" variant="body2">
                                <pre>
                                    {JSON.stringify(
                                        state.context,
                                        undefined,
                                        2,
                                    )}
                                </pre>
                            </Typography>
                        </Grid>
                        <Grid size={12}>
                            <Typography component="dt" variant="h6">
                                Status
                                <IconButton
                                    size="small"
                                    sx={{ ml: 1 }}
                                    onClick={() =>
                                        handleCopyClick(state.status)
                                    }
                                    title="Copy IP Address to Clipboard"
                                >
                                    <ContentCopyIcon fontSize="small" />
                                </IconButton>
                            </Typography>
                            <Typography component="dd" variant="body2">
                                <pre>
                                    {JSON.stringify(state.status, undefined, 2)}
                                </pre>
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}
