import * as React from "react";
import { Link } from "react-router";

import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import type { TagCategoryWithRelations } from "@volley/data";

import { usDateFormat } from "../../../util";
import usePaginatedData from "../../hooks/usePaginatedData";

export default function WorkoutTagsTable(): React.JSX.Element {
    const {
        rowsPerPage,
        onRowsPerPageChange,
        count,
        page,
        onPageChange,
        data: tagCategories,
    } = usePaginatedData<TagCategoryWithRelations>("/api/tags/categories", {
        id: "content-providers",
    });

    return (
        <Grid container spacing={3}>
            <Grid size={12}>
                <Typography component="h1" variant="h2">
                    Workout Tag Categories
                </Typography>
            </Grid>
            <Grid size={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <Box component="div">
                        <Button
                            variant="contained"
                            component={Link}
                            to="new"
                            startIcon={<AddIcon />}
                        >
                            New Workout Tag Category
                        </Button>
                    </Box>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Label</TableCell>
                                    <TableCell>Tag Count</TableCell>
                                    <TableCell>Home Screen</TableCell>
                                    <TableCell>Sports</TableCell>
                                    <TableCell>Mode</TableCell>
                                    <TableCell>Created By</TableCell>
                                    <TableCell>Created At</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tagCategories.map((c) => (
                                    <TableRow key={c.id}>
                                        <TableCell>
                                            <Button
                                                component={Link}
                                                to={c.id.toString()}
                                            >
                                                View
                                            </Button>
                                        </TableCell>
                                        <TableCell>{c.label}</TableCell>
                                        <TableCell>{c.tags.length}</TableCell>
                                        <TableCell align="center">
                                            {c.homeScreen ? <CheckIcon /> : ""}
                                        </TableCell>
                                        <TableCell>
                                            {c.tagCategorySports.length === 0
                                                ? "All Sports"
                                                : c.tagCategorySports
                                                      .map(
                                                          (tcs) =>
                                                              tcs.sport.label,
                                                      )
                                                      .join(", ")}
                                        </TableCell>
                                        <TableCell>{c.mode}</TableCell>
                                        <TableCell>{c.createdBy}</TableCell>
                                        <TableCell>
                                            {usDateFormat(
                                                new Date(c.createdAt),
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        colSpan={6}
                                        rowsPerPage={rowsPerPage}
                                        count={count}
                                        page={page}
                                        onPageChange={onPageChange}
                                        onRowsPerPageChange={
                                            onRowsPerPageChange
                                        }
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        </Grid>
    );
}
