import * as React from "react";

import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import type { CoachStatus } from "@volley/shared/coach-models";

import { usDateFormat } from "../../../util";

import TrainerSoftwareVersionDisplay from "./TrainerSoftwareVersionDisplay";

interface Props {
    status: Partial<CoachStatus>;
    updatedAt: Date;
}

type TableKeyValue = [string, React.JSX.Element | string | number];

export default function TrainerStatusSnapshotDetails({
    status,
    updatedAt,
}: Props): React.JSX.Element {
    const { trainer, vision, softwareVersion } = status;
    const values: TableKeyValue[] = React.useMemo(
        () => [
            [
                "Model/Revision",
                trainer?.model && trainer?.series
                    ? `${trainer.model}${trainer.series}`
                    : "",
            ],
            [
                "Control Software Version",
                <TrainerSoftwareVersionDisplay
                    key="version"
                    label={softwareVersion}
                />,
            ],
            ["Vision Software Version", vision?.visionSoftwareVersion ?? ""],
            [
                "Billboard",
                <>
                    {`Firmware: ${trainer?.billboard?.firmware ?? ""}`}
                    <br />
                    {`Hardware: ${trainer?.billboard?.hardware ?? ""}`}
                </>,
            ],
            [
                "HCM",
                <>
                    {`Firmware: ${trainer?.hcm?.firmware ?? ""}`}
                    <br />
                    {`Hardware: ${trainer?.hcm?.hardware ?? ""}`}
                </>,
            ],
            [
                "PCM",
                <>
                    {`Firmware: ${trainer?.pcm?.firmware ?? ""}`}
                    <br />
                    {`Hardware: ${trainer?.pcm?.hardware ?? ""}`}
                </>,
            ],
            ["Sports", trainer?.sports?.join(", ") ?? ""],
        ],
        [trainer, vision, softwareVersion],
    );

    return (
        <Stack spacing={2}>
            <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
            >
                <Typography variant="h2">Status Snapshot</Typography>
                <Typography
                    variant="h5"
                    sx={{ mt: 2 }}
                    title={updatedAt.toISOString()}
                >
                    {`As of ${usDateFormat(updatedAt)}`}
                </Typography>
            </Stack>
            <Table>
                <TableBody>
                    {values.map(([label, value]) => (
                        <TableRow
                            key={label}
                            sx={{ "& th": { fontWeight: 500 } }}
                        >
                            <TableCell component="th" sx={{ width: "25%" }}>
                                {label}
                            </TableCell>
                            <TableCell>{value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Stack>
    );
}
