import * as React from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import SessionsTable from "./SessionsTable";

export default function SessionsPage(): React.JSX.Element {
    return (
        <Grid container spacing={3}>
            <Grid size={12}>
                <Typography component="h1" variant="h2">
                    Sessions
                </Typography>
            </Grid>
            <Grid size={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <SessionsTable />
                </Paper>
            </Grid>
        </Grid>
    );
}
