import * as React from "react";

import LaunchIcon from "@mui/icons-material/Launch";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import type { Subscription } from "@volley/data";

import config from "../../../config";
import { usDateFormat } from "../../../util";
import usePaginatedData from "../../hooks/usePaginatedData";

interface Props {
    userId: number;
}

const BASE_SUBSCRIPTION_URL = "https://dashboard.stripe.com";

function subscriptionUrl(id: string): string {
    const isTest = config.env !== "production";
    return `${BASE_SUBSCRIPTION_URL}/${isTest ? "test/subscriptions" : "subscriptions"}/${id}`;
}

export default function UserSubscriptionTable({
    userId,
}: Props): React.JSX.Element {
    const {
        rowsPerPage,
        onRowsPerPageChange,
        count,
        page,
        onPageChange,
        data: subscriptions,
    } = usePaginatedData<Subscription>(`/api/users/${userId}/subscriptions`, {
        id: "subscriptions",
    });

    return (
        <Grid container>
            <Grid size={12}>
                <Typography variant="h2">Subscriptions</Typography>
            </Grid>
            <Grid size={12}>
                <Box sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Current Period Start</TableCell>
                                    <TableCell>Current Period End</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subscriptions.map((sub) => (
                                    <TableRow key={sub.id}>
                                        <TableCell>
                                            <Link
                                                href={subscriptionUrl(sub.id)}
                                                target="blank"
                                                rel="noopener"
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    flexWrap: "wrap",
                                                }}
                                            >
                                                <LaunchIcon
                                                    fontSize="small"
                                                    sx={{ marginRight: 1 }}
                                                />
                                                {sub.id}
                                            </Link>
                                        </TableCell>
                                        <TableCell>{sub.description}</TableCell>
                                        <TableCell>{sub.status}</TableCell>
                                        <TableCell>
                                            {usDateFormat(
                                                new Date(
                                                    sub.currentPeriodStart,
                                                ),
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {usDateFormat(
                                                new Date(sub.currentPeriodEnd),
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPage={rowsPerPage}
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                </Box>
            </Grid>
        </Grid>
    );
}
