import * as React from "react";

interface Props {
    separator?: React.ReactNode;
    children: React.ReactNode;
}

export default function JoinedList({
    separator = ", ",
    children,
}: Props): React.ReactNode {
    return React.Children.toArray(children).reduce<React.ReactNode>(
        (acc, link) => (acc === null ? link : [acc, separator, link]),
        null,
    );
}
