import * as React from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import type { CoachStatus } from "@volley/shared/coach-models";

import logger from "../../../log";
import fetchApi, { FetchError } from "../../../util/fetchApi";

import TrainerCellularStatus from "./TrainerCellularStatus";
import TrainerWifiStatus from "./TrainerWifiStatus";

interface WifiCredentials {
    ssid: string;
    passphrase: string;
}

interface Props {
    trainerId: number;
    status: CoachStatus | null;
}

export default function TrainerNetworkConfigure({
    trainerId,
    status,
}: Props): React.JSX.Element {
    const [ssid, setSsid] = React.useState("");
    const [passphrase, setPassphrase] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [resetting, setResetting] = React.useState(false);
    const [error, setError] = React.useState("");

    React.useEffect(() => {
        async function loadWifiCredentials() {
            try {
                const result = await fetchApi<WifiCredentials>(
                    `/api/trainers/${trainerId}/wifi-credentials`,
                );
                if (result) {
                    setSsid(result.ssid);
                    setPassphrase(result.passphrase);
                }
            } catch (err: unknown) {
                if (err instanceof FetchError && err.statusCode === 404) {
                    setSsid("");
                    setPassphrase("");
                } else {
                    logger.error("Error getting wifi credentials", {
                        error: err,
                    });
                    setError((err as Error).message);
                }
            } finally {
                setLoading(false);
            }
        }

        void loadWifiCredentials();
    }, [trainerId]);

    const onSubmit = React.useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            setSubmitting(true);
            try {
                await fetchApi(
                    `/api/trainers/${trainerId}/wifi-credentials`,
                    "POST",
                    { ssid, passphrase },
                );
            } catch (err: unknown) {
                logger.error("Error setting wifi credentials", { error: err });
                setError((err as Error).message);
            } finally {
                setSubmitting(false);
            }
        },
        [trainerId, ssid, passphrase],
    );

    const onReset = React.useCallback(
        async (e: React.FormEvent<HTMLButtonElement>) => {
            e.preventDefault();

            if (
                !window.confirm(
                    "Are you sure you want to reset this trainer's wifi credentials?",
                )
            ) {
                return;
            }

            setResetting(true);
            try {
                await fetchApi(
                    `/api/trainers/${trainerId}/wifi-credentials`,
                    "DELETE",
                );
                setSsid("");
                setPassphrase("");
            } catch (err: unknown) {
                logger.error("Error resetting wifi credentials", {
                    error: err,
                });
                setError((err as Error).message);
            } finally {
                setResetting(false);
            }
        },
        [trainerId],
    );

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Stack spacing={2}>
            <Typography variant="h2">Network</Typography>
            {error && <Typography color="error">{error}</Typography>}
            {!loading && (
                <Stack
                    justifyContent="center"
                    component="form"
                    onSubmit={onSubmit}
                    spacing={2}
                    autoComplete="off"
                >
                    <Typography variant="h3" mb={2}>
                        Configure WiFi Credentials
                    </Typography>
                    <TextField
                        label="SSID"
                        variant="outlined"
                        value={ssid}
                        size="small"
                        sx={{ pr: 2 }}
                        onChange={(e) => setSsid(e.target.value)}
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        value={passphrase}
                        size="small"
                        sx={{ pr: 2 }}
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => setPassphrase(e.target.value)}
                        slotProps={{
                            input: {
                                endAdornment: (
                                    <IconButton onClick={toggleShowPassword}>
                                        {showPassword ? (
                                            <VisibilityOffIcon />
                                        ) : (
                                            <VisibilityIcon />
                                        )}
                                    </IconButton>
                                ),
                            },
                        }}
                    />
                    <Stack
                        spacing={2}
                        direction={{ xs: "column", md: "row" }}
                        sx={{ justifyContent: "flex-end" }}
                    >
                        <Button
                            variant="contained"
                            type="submit"
                            loading={submitting}
                        >
                            Submit
                        </Button>
                        <Button
                            variant="contained"
                            type="button"
                            loading={resetting}
                            color="warning"
                            sx={{ ml: 2 }}
                            onClick={onReset}
                        >
                            Reset
                        </Button>
                    </Stack>
                </Stack>
            )}
            {status && (
                <>
                    <TrainerWifiStatus
                        wifi={status.system.wifi}
                        activeInterface={status.system.activeInterface}
                    />
                    <TrainerCellularStatus
                        cellular={status.system.cellular}
                        activeInterface={status.system.activeInterface}
                    />
                </>
            )}
        </Stack>
    );
}
