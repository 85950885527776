import * as React from "react";
import { useSearchParams } from "react-router";

import type { CursorResult } from "@volley/data";

import fetchApi, { logFetchError } from "../../util/fetchApi";

type MapFn<F, T> = (from: F) => T;

export const DEFAULT_ROWS_PER_PAGE = 50;
const SEARCH_CURSOR_KEY = "cursor";

type Options<T, F> = Partial<{
    id: string;
    initialRowsPerPage: number;
    params: Record<string, string | undefined>;
    mapFn: MapFn<F, T>;
}>;

function searchParamName(key: string, id?: string) {
    const prefix = id ? `${id}-` : "";
    return `${prefix}${key}`;
}

export default function useCursorPaginatedData<T, F = unknown>(
    baseUrl: string,
    options: Options<T, F> = {},
) {
    const { id, params, mapFn } = options;
    const searchCursorParamName = searchParamName(SEARCH_CURSOR_KEY, id);
    const [searchParams, setSearchParams] = useSearchParams();
    const searchCursor = searchParams.get(searchCursorParamName);
    const [previous, setPrevious] = React.useState<string | undefined>();
    const [next, setNext] = React.useState<string | undefined>(
        searchCursor ?? undefined,
    );
    const [page, setPage] = React.useState(0);
    const initialRowsPerPage =
        options.initialRowsPerPage ?? DEFAULT_ROWS_PER_PAGE;
    const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);

    const [data, setData] = React.useState<T[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [fetchError, setFetchError] = React.useState<string>("");

    const url = React.useMemo(() => {
        const filteredParams = new Map(Object.entries(params ?? {}));
        Object.keys(filteredParams).forEach((key) => {
            const val = filteredParams.get(key);
            if (val === "" || val === null || val === undefined) {
                filteredParams.delete(key);
            }
        });

        const query = new URLSearchParams({
            take: rowsPerPage.toString(),
            ...Object.fromEntries(filteredParams.entries()),
        });

        if (searchCursor) {
            query.set("cursor", searchCursor);
        }

        return `${baseUrl}?${query.toString()}`;
    }, [params, rowsPerPage, searchCursor, baseUrl]);

    const fetchData = React.useCallback(async () => {
        setLoading(true);
        try {
            const responseData: CursorResult<F> = await fetchApi(url);
            setData(
                mapFn
                    ? responseData.result.map(mapFn)
                    : (responseData.result as unknown as T[]),
            );
            setPrevious(responseData.previous);
            setNext(responseData.next);
        } finally {
            setLoading(false);
        }
    }, [url, mapFn]);

    const onRowsPerPageChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const next = parseInt(e.target.value, 10);
            setPage(0);
            setRowsPerPage(next);
        },
        [],
    );

    const onPageChange = React.useCallback(
        (_e: React.MouseEvent | null, pageNumber: number) => {
            const cursor = pageNumber < page ? previous : next;
            setPage(pageNumber);
            setSearchParams((search) => {
                if (pageNumber > 0 && cursor) {
                    search.set(searchCursorParamName, cursor);
                } else {
                    search.delete(searchCursorParamName);
                }
                return search;
            });
        },
        [setSearchParams, searchCursorParamName, page, previous, next],
    );

    React.useEffect(() => {
        setFetchError("");
        fetchData().catch((e) => {
            setFetchError((e as Error).message);
            logFetchError(e);
        });
    }, [fetchData]);

    return React.useMemo(
        () => ({
            rowsPerPage,
            onRowsPerPageChange,
            page,
            onPageChange,
            count: next ? -1 : page * rowsPerPage + data.length,
            loading,
            data,
            fetchError,
        }),
        [
            next,
            rowsPerPage,
            onRowsPerPageChange,
            page,
            onPageChange,
            loading,
            data,
            fetchError,
        ],
    );
}
