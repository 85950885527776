import * as React from "react";
import { Link as RouterLink } from "react-router";

import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import type { UserWithRelations } from "@volley/data";

import { usDateFormat } from "../../../util";
import useDebounce from "../../hooks/useDebounce";
import usePaginatedData from "../../hooks/usePaginatedData";

export default function UsersTable(): React.JSX.Element {
    const [search, setSearch] = React.useState("");
    const [includeDisabled, setIncludeDisabled] = React.useState(false);
    const [includeInternal, setIncludeInternal] = React.useState(false);
    const debouncedSearch = useDebounce(search);
    const params = React.useMemo(
        () => ({
            q: debouncedSearch || undefined,
            includeDisabled: includeDisabled ? "true" : undefined,
            includeInternal: includeInternal ? "true" : undefined,
        }),
        [debouncedSearch, includeDisabled, includeInternal],
    );
    const {
        rowsPerPage,
        onRowsPerPageChange,
        count,
        page,
        onPageChange,
        data: users,
    } = usePaginatedData<UserWithRelations>("/api/users", {
        id: "users",
        params,
    });

    const onChangeSearch = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (page > 0) {
                onPageChange(e, 0);
            }
            setSearch(e.currentTarget.value);
        },
        [page, onPageChange],
    );

    return (
        <Grid container spacing={3}>
            <Grid size={12}>
                <Typography component="h1" variant="h2">
                    Users
                </Typography>
            </Grid>
            <Grid size={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <Grid container spacing={3}>
                        <Grid>
                            <Button
                                variant="contained"
                                component={RouterLink}
                                to="new"
                            >
                                New User
                            </Button>
                        </Grid>
                        <Grid>
                            <TextField
                                type="search"
                                size="small"
                                label="Search"
                                value={search}
                                onChange={onChangeSearch}
                                slotProps={{
                                    input: {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    },
                                }}
                            />
                        </Grid>
                        <Grid>
                            <FormControlLabel
                                control={
                                    <Switch
                                        onChange={() =>
                                            setIncludeDisabled(!includeDisabled)
                                        }
                                    />
                                }
                                label="Include Disabled"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        onChange={() =>
                                            setIncludeInternal(!includeInternal)
                                        }
                                    />
                                }
                                label="Include Internal"
                            />
                        </Grid>
                    </Grid>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Username</TableCell>
                                    <TableCell>First Name</TableCell>
                                    <TableCell>Last Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Roles</TableCell>
                                    <TableCell>Created At</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((u) => (
                                    <TableRow key={u.id}>
                                        <TableCell>
                                            <Button
                                                component={RouterLink}
                                                to={u.id.toString()}
                                            >
                                                View
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            <Link
                                                component={RouterLink}
                                                to={u.id.toString()}
                                            >
                                                {u.username}
                                                {!!u.disabledAt &&
                                                    " (disabled)"}
                                            </Link>
                                        </TableCell>
                                        <TableCell>{u.firstName}</TableCell>
                                        <TableCell>{u.lastName}</TableCell>
                                        <TableCell>{u.email}</TableCell>
                                        <TableCell>
                                            {u.userRoles
                                                .map((ur) => ur.role.name)
                                                .sort((a, b) =>
                                                    a.localeCompare(b),
                                                )
                                                .join(", ")}
                                        </TableCell>
                                        <TableCell>
                                            {usDateFormat(
                                                new Date(u.createdAt),
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        colSpan={7}
                                        rowsPerPage={rowsPerPage}
                                        count={count}
                                        page={page}
                                        onPageChange={onPageChange}
                                        onRowsPerPageChange={
                                            onRowsPerPageChange
                                        }
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        </Grid>
    );
}
