import * as React from "react";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { CoachStatus } from "@volley/shared/coach-models";
import { StressTestParams } from "@volley/shared/http/trainer-control";

import { fetchApi } from "../../../util";

export enum TestType {
    VOLTAGE = "VOLTAGE",
    SPINNER_MAGNETS = "SPINNER_MAGNETS",
    WHEELS = "WHEELS",
    LAUNCH = "LAUNCH",
    SERVOS = "SERVOS",
    PCM = "PCM",
    BELT_TENSION = "BELT_TENSION",
    FINAL_TEMPS = "FINAL_TEMPS",
    CAN_INTERFACES = "CAN_INTERFACES",
    BILLBOARD = "BILLBOARD",
    WIFI = "WIFI",
    FAULTS = "FAULTS",
    VIDEO_CURRENT = "VIDEO_CURRENT",
    NMI = "NMI",
}

const DEFAULT_WHEEL_TEST_LOOPS = 50;
const DEFAULT_WHEEL_TEST_ITERATIONS = 20;
const DEFAULT_LAUNCH_TEST_COUNT = 20;

export default function TrainerSelfTestControls({
    clientId,
    diagnostics,
}: CoachStatus) {
    const [skipBatteryLevelCheck, setSkipBatteryLevelCheck] =
        React.useState(false);
    const tests = Object.values(TestType);
    const [selectedTests, setSelectedTests] = React.useState<string[]>(tests);
    const [wheelsLoops, setWheelsLoops] = React.useState(
        DEFAULT_WHEEL_TEST_LOOPS,
    );
    const [wheelsIterations, setWheelsIterations] = React.useState(
        DEFAULT_WHEEL_TEST_ITERATIONS,
    );
    const [launchesNumber, setLaunchesNumber] = React.useState(
        DEFAULT_LAUNCH_TEST_COUNT,
    );

    const [runningSelfTest, setRunningSelfTest] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [selfTestRunId, setSelfTestRunId] = React.useState<string | null>(
        null,
    );

    React.useEffect(() => {
        if (diagnostics && diagnostics.activeStressTest) {
            setRunningSelfTest(true);
            setSelfTestRunId(diagnostics.activeStressTest);
        } else {
            setRunningSelfTest(false);
        }
    }, [diagnostics]);

    const onRunSelfTest = React.useCallback(async () => {
        try {
            setRunningSelfTest(true);
            const params: StressTestParams = {
                skipBatteryLevelCheck,
                testsToRun: selectedTests as TestType[],
                wheelTestParams: {
                    loops: wheelsLoops,
                    iterations: wheelsIterations,
                },
                numberOfLaunches: launchesNumber,
            };
            await fetchApi(
                `/trainer/${clientId}/api/stress-test`,
                "POST",
                params,
            );
        } catch (err: unknown) {
            setErrorMessage((err as Error).message);
            setRunningSelfTest(false);
        }
    }, [
        clientId,
        launchesNumber,
        selectedTests,
        skipBatteryLevelCheck,
        wheelsIterations,
        wheelsLoops,
    ]);

    const [cancelingSelfTest, setCancelingSelfTest] = React.useState(false);

    const onCancelSelfTest = React.useCallback(async () => {
        try {
            setCancelingSelfTest(true);
            await fetchApi(`/trainer/${clientId}/api/stress-test`, "DELETE");
        } catch (err: unknown) {
            setCancelingSelfTest(false);
            setErrorMessage((err as Error).message);
        }
    }, [clientId]);

    React.useEffect(() => {
        if (cancelingSelfTest && diagnostics && !diagnostics.activeStressTest) {
            setCancelingSelfTest(false);
        }
    }, [cancelingSelfTest, diagnostics]);

    const handleTestChange = (test: string) => {
        setSelectedTests((prevState) =>
            prevState.includes(test)
                ? prevState.filter((t) => t !== test)
                : [...prevState, test],
        );
    };

    const handleSelectAll = () => {
        setSelectedTests(selectedTests.length === tests.length ? [] : tests);
    };

    return (
        <Stack spacing={2}>
            <Typography variant="h2">Trainer Self Test</Typography>
            {errorMessage && (
                <Typography variant="body1" color="error">
                    {errorMessage}
                </Typography>
            )}
            <Typography variant="h6">Preconditions</Typography>
            <FormControlLabel
                control={
                    <Switch
                        checked={skipBatteryLevelCheck}
                        onChange={(e) =>
                            setSkipBatteryLevelCheck(e.target.checked)
                        }
                        color="primary"
                    />
                }
                label="Skip Battery Level Check"
            />
            <Divider sx={{ my: 3 }} />
            <Typography variant="h6">Tests to run</Typography>
            <FormControlLabel
                control={
                    <Switch
                        checked={selectedTests.length === tests.length}
                        onChange={() => handleSelectAll()}
                        color="primary"
                    />
                }
                label="Select All"
            />
            <Grid container spacing={1}>
                {tests.map((test) => (
                    <Grid key={test} size={4}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={selectedTests.includes(test)}
                                    onChange={() => handleTestChange(test)}
                                    name={test}
                                    color="primary"
                                />
                            }
                            label={test}
                        />
                    </Grid>
                ))}
            </Grid>
            <Divider />
            <Typography variant="h6">Test Parameters</Typography>
            <Grid container spacing={2} direction="column">
                <Grid
                    size={{
                        xs: 12,
                        md: 6,
                    }}
                >
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Wheels Test:
                    </Typography>
                    <TextField
                        label="Loops"
                        type="number"
                        value={wheelsLoops}
                        onChange={(e) => setWheelsLoops(+e.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        md: 6,
                    }}
                >
                    <TextField
                        label="Iterations"
                        type="number"
                        value={wheelsIterations}
                        onChange={(e) => setWheelsIterations(+e.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Launch Test:
                    </Typography>
                    <TextField
                        label="Launches"
                        type="number"
                        value={launchesNumber}
                        onChange={(e) => setLaunchesNumber(+e.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid
                    size={{
                        xs: 12,
                        md: 3,
                    }}
                >
                    <Button
                        variant="contained"
                        color="error"
                        fullWidth
                        onClick={onRunSelfTest}
                        loading={runningSelfTest}
                        loadingPosition="end"
                    >
                        Run Self Test
                    </Button>
                </Grid>
                {runningSelfTest && selfTestRunId && (
                    <Grid
                        size={{
                            xs: 12,
                            md: 3,
                        }}
                    >
                        <Button
                            variant="contained"
                            color="warning"
                            fullWidth
                            onClick={onCancelSelfTest}
                            loading={cancelingSelfTest}
                            loadingPosition="end"
                        >
                            Cancel Self Test
                        </Button>
                    </Grid>
                )}
                {!runningSelfTest && selfTestRunId && (
                    <Grid
                        size={{
                            xs: 12,
                            md: 3,
                        }}
                    >
                        <Button
                            variant="contained"
                            color="info"
                            fullWidth
                            component={Link}
                            href={`/admin/test-results/${selfTestRunId}`}
                            target="_blank"
                            rel="noopener"
                        >
                            See Last Result
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Stack>
    );
}
