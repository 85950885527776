import * as React from "react";

import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { formatDuration, intervalToDuration } from "date-fns";

import type { CoachStatus } from "@volley/shared/coach-models";

import { round, usDateFormat } from "../../../util";

import TrainerSoftwareVersionDisplay from "./TrainerSoftwareVersionDisplay";
import TrainerStatusUpdateEventLog from "./TrainerStatusUpdateEventLog";

type TableKeyValue = [string, React.JSX.Element | string | number];

export default function TrainerStatusDetails({
    fault,
    position,
    ready,
    softwareVersion,
    session,
    system,
    trainer,
    update,
    vision,
    web,
    timestamp,
    workouts,
}: CoachStatus): React.JSX.Element {
    const imuPitch = vision.camera.orientationIMU?.pitchDeg;
    const imuRoll = vision.camera.orientationIMU?.rollDeg;
    const imuPitchStr =
        imuPitch === -999.0 || imuPitch === undefined
            ? "None"
            : imuPitch.toFixed(2);
    const imuRollStr =
        imuRoll === -999.0 || imuRoll === undefined
            ? "None"
            : imuRoll.toFixed(2);

    const prevPitch = vision.camera.orientationPreviousLocalized?.pitchDeg;
    const prevRoll = vision.camera.orientationPreviousLocalized?.rollDeg;
    const prevPitchStr =
        prevPitch === -999.0 || prevPitch === undefined
            ? "None"
            : prevPitch.toFixed(2);
    const prevRollStr =
        prevRoll === -999.0 || prevRoll === undefined
            ? "None"
            : prevRoll.toFixed(2);

    const values: TableKeyValue[] = [
        [
            "Model/Revision",
            trainer.model && trainer.series
                ? `${trainer.model}${trainer.series}`
                : "",
        ],
        [
            "Control Software Version",
            <TrainerSoftwareVersionDisplay
                key="version"
                label={softwareVersion}
            />,
        ],
        ["Vision Software Version", vision.visionSoftwareVersion ?? ""],
        [
            "Billboard",
            <>
                {`Firmware: ${trainer.billboard?.firmware ?? ""}`}
                <br />
                {`Hardware: ${trainer.billboard?.hardware ?? ""}`}
            </>,
        ],
        [
            "HCM",
            <>
                {`Firmware: ${trainer.hcm?.firmware ?? ""}`}
                <br />
                {`Hardware: ${trainer.hcm?.hardware ?? ""}`}
            </>,
        ],
        [
            "PCM",
            <>
                {`Firmware: ${trainer.pcm?.firmware ?? ""}`}
                <br />
                {`Hardware: ${trainer.pcm?.hardware ?? ""}`}
            </>,
        ],
        [
            "Position",
            <>
                {`X: ${position?.x ?? ""}, Y: ${position?.y ?? ""}`}
                <br />
                {`Yaw: ${position?.yaw ?? ""}, Height: ${trainer.height ?? ""}"`}
            </>,
        ],
        [
            "Camera Orientation",
            <>
                {`Pitch (deg): ${vision.camera.orientation?.pitchDeg.toFixed(2) ?? ""}, Roll (deg): ${vision.camera.orientation?.rollDeg.toFixed(2) ?? ""}`}
            </>,
        ],
        [
            "Camera Orientation Mode",
            <>{`${vision.camera.orientationMode ?? ""}`}</>,
        ],
        [
            "Camera Orientation Status",
            <>{`${vision.camera.orientationMessage ?? ""}`}</>,
        ],
        [
            "Camera Orientation Last Workout Source",
            <>{`${workouts.cameraOrientationSource ?? ""}`}</>,
        ],
        [
            "Camera Orientation IMU Values",
            <>{`Pitch (deg): ${imuPitchStr}, Roll (deg): ${imuRollStr}`}</>,
        ],
        [
            "Camera Orientation Prev Localization Values",
            <>{`Pitch (deg): ${prevPitchStr}, Roll (deg): ${prevRollStr}`}</>,
        ],
        [
            "Trainer Uptime",
            formatDuration(
                intervalToDuration({
                    start: 0,
                    end: (system.uptime ?? 0) * 1000,
                }),
            ),
        ],
        ["Coach Ready", ready],
        ["Power State", trainer.powerState],
        ["Battery Level", `${trainer.battery.level}%`],
        [
            "RPC Ping Latency",
            web.connection.pingLatency !== null
                ? `${web.connection.pingLatency} ms`
                : "",
        ],
        ["Bridge Connection Count", `${web.connection.connectionCount ?? ""}`],
        ["Fault", fault?.failures[0]?.message ?? ""],
        ["Users", session ? session.users.map((u) => u.name).join(", ") : ""],
        ["Sports", trainer.sports?.join(", ") ?? ""],
    ];

    const updatedAt = React.useMemo(() => new Date(timestamp), [timestamp]);

    return (
        <Stack spacing={2}>
            <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
            >
                <Typography variant="h2">Status</Typography>
                <Typography
                    variant="h5"
                    sx={{ mt: 2 }}
                    title={updatedAt.toISOString()}
                >
                    {`As of ${usDateFormat(updatedAt)}`}
                </Typography>
            </Stack>
            <div style={{ overflow: "hidden" }}>
                <Table>
                    <TableBody>
                        {values.map(([label, value]) => (
                            <TableRow
                                key={label}
                                sx={{ "& th": { fontWeight: 500 } }}
                            >
                                <TableCell component="th" sx={{ width: "25%" }}>
                                    {label}
                                </TableCell>
                                <TableCell>{value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            {!!update.updateLog && (
                <>
                    <Typography variant="h3" gutterBottom sx={{ mt: 4 }}>
                        Update Log
                    </Typography>
                    <TrainerStatusUpdateEventLog log={update.updateLog} />
                </>
            )}
            {!!update.download && (
                <>
                    <Typography variant="h3" gutterBottom>
                        Software Download
                    </Typography>
                    <LinearProgress
                        sx={{ my: 2 }}
                        variant="determinate"
                        value={
                            (update.download.loaded / update.download.total) *
                            100
                        }
                    />
                    <Typography align="center">
                        {`${Math.round((update.download.loaded / update.download.total) * 100)}%`}
                    </Typography>
                    <Typography align="center">
                        {round(update.download.loaded / 1_000_000, 1)}
                        {" / "}
                        {round(update.download.total / 1_000_000, 1)} megabytes
                    </Typography>
                    <Typography align="center">
                        {update.download.name}
                    </Typography>
                </>
            )}
        </Stack>
    );
}
