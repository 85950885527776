import * as React from "react";

import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

interface Props {
    label?: string;
    truncate?: boolean;
}

export default function TrainerSoftwareVersionDisplay({
    label = "",
    truncate = false,
}: Props): React.JSX.Element {
    const display = truncate
        ? `${label?.split(" ").slice(0, 2).join(" ") ?? ""}${label ? "…" : ""}`
        : label;
    return (
        <Tooltip title={label || "Unknown software version"}>
            <Box component="span" sx={{ whiteSpace: "nowrap" }}>
                {display}
            </Box>
        </Tooltip>
    );
}
