import * as React from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router";

import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import {
    OffsetResult,
    ContentProvider,
    ContentProviderCreatePayload,
} from "@volley/data";
import type { Location, LocationWithRelations, User } from "@volley/data";

import fetchApi from "../../../util/fetchApi";
import LocationAutocomplete from "../../common/LocationAutocomplete";
import useDebounce from "../../hooks/useDebounce";
import AdminSaveButton, { SaveState } from "../AdminSaveButton";

type ContentProviderForm = Omit<
    ContentProvider,
    "createdBy" | "updatedBy" | "createdAt" | "updatedAt"
> & {
    publisherUsers: User[];
    locations: Location[];
};

type Action =
    | { type: "name"; value: string }
    | { type: "label"; value: string }
    | { type: "description"; value: string }
    | { type: "publisherUsers"; value: User[] }
    | { type: "locations"; value: Location[] }
    | { type: "set"; value: ContentProviderForm };

function reducer(
    state: ContentProviderForm,
    action: Action,
): ContentProviderForm {
    switch (action.type) {
        case "name":
            return { ...state, name: action.value };
        case "label":
            return { ...state, label: action.value };
        case "description":
            return { ...state, description: action.value };
        case "publisherUsers":
            return { ...state, publisherUsers: action.value };
        case "locations":
            return { ...state, locations: action.value };
        case "set":
            return { ...action.value };
        default:
            throw new Error("Invalid form action type");
    }
}

const defaultState: ContentProviderForm = {
    id: 0,
    name: "",
    label: "",
    description: "",
    publisherUsers: [],
    locations: [],
    _count: {
        appWorkouts: 0,
    },
};

export default function ContentProviderEdit(): React.JSX.Element {
    const { id } = useParams<"id">();
    const validId = parseInt(id ?? "", 10);
    const navigate = useNavigate();
    const [userSearch, setUserSearch] = React.useState("");
    const debouncedUserSearch = useDebounce(userSearch, 250);
    const [usersOpen, setUsersOpen] = React.useState(false);
    const [userMatches, setUserMatches] = React.useState<User[]>([]);
    const [usersLoading, setUsersLoading] = React.useState(false);
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const [saveState, setSaveState] = React.useState(SaveState.Unsaved);
    const [errorMessage, setErrorMessage] = React.useState("");

    async function fetchData(contentProviderId: number) {
        const [contentProvider, publisherUsers, linkedLocations] =
            await Promise.all([
                fetchApi<ContentProvider | null>(
                    `/api/content-providers/${contentProviderId}`,
                ),
                fetchApi<User[]>(
                    `/api/content-providers/${contentProviderId}/publishers`,
                ),
                fetchApi<Location[]>(
                    `/api/content-providers/${contentProviderId}/locations`,
                ),
            ]);
        if (!contentProvider) {
            setErrorMessage("Content Provider not found");
            return;
        }

        dispatch({
            type: "set",
            value: {
                ...contentProvider,
                publisherUsers,
                locations: linkedLocations,
            },
        });
    }

    React.useEffect(() => {
        setErrorMessage("");
        if (validId) {
            fetchData(validId).catch((e: Error) => setErrorMessage(e.message));
        }
    }, [validId]);

    const onSubmit = React.useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            setSaveState(SaveState.Saving);
            setErrorMessage("");
            const payload: ContentProviderCreatePayload = {
                name: state.name.trim(),
                label: state.label.trim(),
                description: state.description.trim(),
                publisherUsers: state.publisherUsers.map((p) => ({ id: p.id })),
                locations: state.locations.map((l) => ({ id: l.id })),
            };

            try {
                let result: ContentProvider;
                if (state.id) {
                    result = await fetchApi(
                        `/api/content-providers/${state.id}`,
                        "PUT",
                        { ...payload, id: state.id },
                    );
                } else {
                    result = await fetchApi(
                        "/api/content-providers",
                        "POST",
                        payload,
                    );
                }
                setSaveState(SaveState.Saved);
                navigate(`../${result.id.toString()}`, { replace: true });
            } catch (err: unknown) {
                const error = err as Error;
                setErrorMessage(error.message);
                setSaveState(SaveState.Unsaved);
            }
        },
        [state, navigate],
    );

    React.useEffect(() => {
        if (usersOpen) {
            setUsersLoading(true);
            const qParam = debouncedUserSearch || "";
            fetchApi<OffsetResult<User>>(
                `/api/users/search?q=${qParam}&limit=20&offset=0`,
            )
                .then((matches) => setUserMatches(matches.result))
                .catch((e: Error) => setErrorMessage(e.message))
                .finally(() => setUsersLoading(false));
        } else if (!debouncedUserSearch) {
            setUserMatches([]);
        }
    }, [usersOpen, debouncedUserSearch]);

    return (
        <Stack>
            <Paper
                sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    "& > :not(style)": { m: 1 },
                }}
                component="form"
                autoComplete="off"
                onSubmit={onSubmit}
            >
                <Grid container>
                    <Grid size={10}>
                        <Typography
                            component="h1"
                            variant="h2"
                            title={`ID: ${state.id || "(New)"}`}
                        >
                            {state.id
                                ? `${state.label}`
                                : "New Content Provider"}
                        </Typography>
                    </Grid>
                    <Grid sx={{ textAlign: "right" }} size={2}>
                        <IconButton size="large" component={RouterLink} to="..">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                {errorMessage && (
                    <Typography color="error.main">{errorMessage}</Typography>
                )}
                <TextField
                    id="name"
                    label="Name"
                    value={state.name}
                    onChange={(e) =>
                        dispatch({ type: "name", value: e.currentTarget.value })
                    }
                />
                <TextField
                    id="name"
                    label="Label"
                    value={state.label}
                    onChange={(e) =>
                        dispatch({
                            type: "label",
                            value: e.currentTarget.value,
                        })
                    }
                />
                <TextField
                    id="description"
                    label="Description"
                    value={state.description}
                    onChange={(e) =>
                        dispatch({
                            type: "description",
                            value: e.currentTarget.value,
                        })
                    }
                />
                <Autocomplete
                    id="publishers"
                    multiple
                    fullWidth
                    loading={usersLoading}
                    options={userMatches}
                    autoComplete
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    getOptionLabel={(u) =>
                        `${u.username} (${u.firstName} ${u.lastName})`
                    }
                    filterOptions={(o) => o}
                    noOptionsText="No publishers"
                    onOpen={() => setUsersOpen(true)}
                    onClose={() => setUsersOpen(false)}
                    value={state.publisherUsers}
                    onChange={(_event, value: User[]) => {
                        dispatch({
                            type: "publisherUsers",
                            value,
                        });
                    }}
                    onInputChange={(_e, newInputValue) => {
                        setUserSearch(newInputValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Publishers"
                            slotProps={{
                                input: {
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {usersLoading ? (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                },
                            }}
                        />
                    )}
                />
                <LocationAutocomplete
                    multiple
                    value={state.locations.map((l) => l.id)}
                    onChange={(value: LocationWithRelations[]) =>
                        dispatch({
                            type: "locations",
                            value,
                        })
                    }
                />
                <Typography variant="caption">
                    Note: Each location may only have one assigned content
                    provider; if a location is already assigned to another
                    content provider, it will be reassigned to this one when
                    saved.
                </Typography>
                <Typography>
                    {`Workout Count: ${state._count.appWorkouts}`}
                </Typography>
                <Box
                    component="div"
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                    <Stack spacing={2} direction="row">
                        <AdminSaveButton
                            type="submit"
                            variant="contained"
                            saveState={saveState}
                        />
                        <Button component={RouterLink} to="..">
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Paper>
        </Stack>
    );
}
