import * as React from "react";
import { Link as RouterLink, useParams } from "react-router";

import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HideSourceIcon from "@mui/icons-material/HideSource";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { AdminCampaignClub, CampaignClubUpdatePayload } from "@volley/data";

import { usDateFormat } from "../../../util";
import fetchApi from "../../../util/fetchApi";
import { STATES } from "../../common/data";
import AdminSaveButton, { SaveState } from "../AdminSaveButton";

type CampaignClubForm = Omit<
    AdminCampaignClub,
    "id" | "createdBy" | "updatedAt" | "trackingId"
>;

type Action =
    | { type: "name"; value: string }
    | { type: "hubspotCompanyId"; value: string }
    | { type: "city"; value: string }
    | { type: "state"; value: string }
    | { type: "news"; value: string }
    | { type: "deletedAt"; value: Date | null }
    | { type: "set"; value: CampaignClubForm };

function reducer(state: CampaignClubForm, action: Action): CampaignClubForm {
    switch (action.type) {
        case "name":
            return { ...state, name: action.value };
        case "hubspotCompanyId":
            return { ...state, hubspotCompanyId: action.value };
        case "city":
            return { ...state, city: action.value };
        case "state":
            return { ...state, state: action.value };
        case "news":
            return { ...state, news: action.value };
        case "deletedAt":
            return { ...state, deletedAt: action.value };
        case "set":
            return { ...action.value };
        default:
            throw new Error("Invalid form action type");
    }
}

const defaultState: CampaignClubForm = {
    name: "",
    hubspotCompanyId: "",
    city: "",
    state: "",
    news: "",
    createdAt: new Date(0),
    updatedBy: "",
    deletedAt: null,
    members: [],
    shareUrl: "",
};

export default function CampaignClubEdit(): React.JSX.Element {
    const { id } = useParams<"id">();
    const validId = parseInt(id ?? "", 10);
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const [saveState, setSaveState] = React.useState(SaveState.Unsaved);
    const [shareOpen, setShareOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    async function fetchData(campaignClubId: number) {
        const campaignClub = await fetchApi<AdminCampaignClub>(
            `/api/campaign-clubs/admin/clubs/${campaignClubId}`,
        );
        if (!campaignClub) {
            setErrorMessage("Campaign Club not found");
            return;
        }

        dispatch({
            type: "set",
            value: {
                name: campaignClub.name,
                hubspotCompanyId: campaignClub.hubspotCompanyId,
                city: campaignClub.city,
                state: campaignClub.state,
                news: campaignClub.news,
                createdAt: new Date(campaignClub.createdAt),
                deletedAt: campaignClub.deletedAt
                    ? new Date(campaignClub.deletedAt)
                    : null,
                updatedBy: campaignClub.updatedBy,
                members: campaignClub.members,
                shareUrl: campaignClub.shareUrl,
            },
        });
    }

    React.useEffect(() => {
        setErrorMessage("");
        if (validId) {
            fetchData(validId).catch((e: Error) => setErrorMessage(e.message));
        }
    }, [validId]);

    const onSubmit = React.useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            setSaveState(SaveState.Saving);
            setErrorMessage("");
            const payload: CampaignClubUpdatePayload = {
                id: validId,
                hubspotCompanyId: state.hubspotCompanyId?.trim() || null,
                name: state.name.trim(),
                city: state.city.trim(),
                state: state.state,
                news: state.news?.trim() || null,
            };

            try {
                await fetchApi<AdminCampaignClub>(
                    `/api/campaign-clubs/admin/clubs/${validId}`,
                    "PUT",
                    payload,
                );
                dispatch({ type: "deletedAt", value: null });
                setSaveState(SaveState.Saved);
            } catch (err: unknown) {
                const error = err as Error;
                setErrorMessage(error.message);
                setSaveState(SaveState.Unsaved);
            }
        },
        [state, validId],
    );

    const onDelete = React.useCallback(async () => {
        const confirmed = confirm(
            "Are you sure you want to hide this club? New members will not be allowed to join it unless it's unhidden.",
        );
        if (!confirmed) return;

        try {
            await fetchApi(`/api/campaign-clubs/admin/clubs/${id}`, "DELETE");
            dispatch({ type: "deletedAt", value: new Date() });
        } catch (err: unknown) {
            const error = err as Error;
            setErrorMessage(error.message);
        }
    }, [id]);

    const onCopyShareLink = (link: string) => {
        window.navigator.clipboard
            .writeText(link)
            .then(() => setShareOpen(true))
            .catch((e: Error) => setErrorMessage(e.message));
    };

    return (
        <Stack spacing={2}>
            <Paper
                sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    "& > :not(style)": { m: 1 },
                }}
                component="form"
                autoComplete="off"
                onSubmit={onSubmit}
            >
                <Grid container>
                    <Grid size={{ xs: 10 }}>
                        <Typography
                            component="h1"
                            variant="h2"
                            title={`ID: ${id}`}
                        >
                            {state.name}
                        </Typography>
                    </Grid>
                    <Grid size={{ xs: 2 }} sx={{ textAlign: "right" }}>
                        <IconButton size="large" component={RouterLink} to="..">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                {errorMessage && (
                    <Typography color="error.main">{errorMessage}</Typography>
                )}
                {state.deletedAt && (
                    <Typography color="warning">
                        {`This club was hidden on ${usDateFormat(state.deletedAt)} by ${state.updatedBy}. To unhide it, save this form.`}
                    </Typography>
                )}
                <TextField
                    id="name"
                    label="Name"
                    value={state.name}
                    onChange={(e) =>
                        dispatch({ type: "name", value: e.currentTarget.value })
                    }
                />
                <TextField
                    id="hubspotCompanyId"
                    label="Hubspot Company ID"
                    value={state.hubspotCompanyId}
                    onChange={(e) =>
                        dispatch({
                            type: "hubspotCompanyId",
                            value: e.currentTarget.value,
                        })
                    }
                />
                <TextField
                    id="city"
                    label="City"
                    autoComplete="address-level2"
                    value={state.city}
                    onChange={(e) =>
                        dispatch({ type: "city", value: e.currentTarget.value })
                    }
                />
                <FormControl fullWidth>
                    <InputLabel id="state-label">State</InputLabel>
                    <Select
                        id="state"
                        label="State"
                        autoComplete="address-level1"
                        value={state.state || ""}
                        labelId="state-label"
                        onChange={(e) =>
                            dispatch({ type: "state", value: e.target.value })
                        }
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {STATES.filter((s) => s.country === "USA").map((s) => (
                            <MenuItem value={s.abbreviation} key={s.name}>
                                {s.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    id="news"
                    label="News"
                    multiline
                    rows={8}
                    value={state.news}
                    onChange={(e) =>
                        dispatch({ type: "news", value: e.currentTarget.value })
                    }
                />
                <Stack
                    spacing={2}
                    direction={{ xs: "column", sm: "row" }}
                    justifyContent={{ sm: "flex-end" }}
                >
                    <AdminSaveButton
                        type="submit"
                        variant="contained"
                        saveState={saveState}
                    />
                    <Button
                        onClick={() => onCopyShareLink(state.shareUrl)}
                        title="Copy share link to clipboard"
                        startIcon={<ContentCopyIcon />}
                    >
                        Copy Share Link
                    </Button>
                    {!state.deletedAt && (
                        <Button
                            color="warning"
                            startIcon={<HideSourceIcon />}
                            onClick={onDelete}
                        >
                            Hide
                        </Button>
                    )}
                    <Button component={RouterLink} to="..">
                        Cancel
                    </Button>
                </Stack>
            </Paper>
            <Paper
                sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    "& > :not(style)": { m: 1 },
                }}
            >
                <Typography
                    variant="h3"
                    component="h2"
                >{`Members (${state.members.length})`}</Typography>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell>Referred By</TableCell>
                                <TableCell>Share Link</TableCell>
                                <TableCell>Created At</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.members.map((m) => (
                                <TableRow key={m.id}>
                                    <TableCell>{m.firstName}</TableCell>
                                    <TableCell>{m.lastName}</TableCell>
                                    <TableCell>{m.email}</TableCell>
                                    <TableCell>{m.phone ?? ""}</TableCell>
                                    <TableCell>
                                        {m.referredByMemberName}
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            onClick={() =>
                                                onCopyShareLink(
                                                    `${window.location.origin}/campaigns/t/member_${m.trackingId}`,
                                                )
                                            }
                                            title="Copy user share link to clipboard"
                                            startIcon={<ContentCopyIcon />}
                                        >
                                            Copy Link
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        {usDateFormat(new Date(m.createdAt))}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Snackbar
                open={shareOpen}
                autoHideDuration={3_000}
                onClose={() => setShareOpen(false)}
            >
                <Alert severity="info" variant="filled">
                    Link Copied!
                </Alert>
            </Snackbar>
        </Stack>
    );
}
