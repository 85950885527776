import * as React from "react";
import { useParams, Link as RouterLink } from "react-router";

import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Paper, { PaperProps } from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { formatDuration, intervalToDuration } from "date-fns";

import type {
    SessionWithDeepRelationsJson,
    WorkoutPlayWithResults,
} from "@volley/data";

import { fetchApi, usDateFormat } from "../../../util";
import JoinedList from "../../common/JoinedList";
import AdminLoading from "../AdminLoading";

import WorkoutPlayVisualizer from "./WorkoutPlayVisualizer";
import WorkoutResultsTable from "./WorkoutResultsTable";
import WorkoutThrowsTable from "./WorkoutThrowsTable";

interface DialogPaperProps {
    children: React.ReactNode;
    sx: PaperProps["sx"];
}
export function DialogPaper({
    children,
    sx,
}: DialogPaperProps): React.JSX.Element {
    const [expanded, setExpanded] = React.useState(false);

    return (
        <Box>
            <Paper sx={{ ...sx }}>
                <Grid container>
                    <Grid
                        sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "end",
                        }}
                    >
                        <IconButton onClick={() => setExpanded(!expanded)}>
                            <FullscreenIcon />
                        </IconButton>
                    </Grid>
                    <Grid sx={{ width: "100%" }}>
                        <Box>{children}</Box>
                    </Grid>
                </Grid>
            </Paper>
            <Dialog
                open={expanded}
                onClose={() => setExpanded(false)}
                fullScreen
            >
                <Paper sx={{ ...sx }}>
                    <Grid container>
                        <Grid
                            sx={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "end",
                            }}
                        >
                            <IconButton onClick={() => setExpanded(!expanded)}>
                                <FullscreenExitIcon />
                            </IconButton>
                        </Grid>
                        <Grid sx={{ width: "100%" }}>
                            <Box>{children}</Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Dialog>
        </Box>
    );
}

export default function WorkoutPlayDetails(): React.JSX.Element {
    const { id } = useParams<"id">();
    const validId = parseInt(id ?? "", 10);
    const [workoutPlay, setWorkoutPlay] =
        React.useState<WorkoutPlayWithResults>();
    const [session, setSession] =
        React.useState<SessionWithDeepRelationsJson>();
    const [errorMessage, setErrorMessage] = React.useState("");

    React.useEffect(() => {
        async function fetchData(workoutPlayId: number) {
            const data = await fetchApi<WorkoutPlayWithResults>(
                `/api/workout-plays/${workoutPlayId}`,
            );
            const sessionData = await fetchApi<SessionWithDeepRelationsJson>(
                `/api/sessions/${data.sessionId}`,
            );
            setWorkoutPlay(data);
            setSession(sessionData);
        }

        if (validId) {
            fetchData(validId).catch((e: Error) => {
                setErrorMessage(e.message);
            });
        }
    }, [validId]);

    if ((!workoutPlay || !session) && !errorMessage) {
        return <AdminLoading page="Workout play" />;
    }

    const name = (
        workoutPlay?.workout as unknown as { name: string } | undefined
    )?.name;

    return (
        <Grid container spacing={3}>
            <Grid size={12}>
                <Paper sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                        <Grid size={12}>
                            <Typography component="h1" variant="h2">
                                Workout Play
                            </Typography>
                            {!!errorMessage && (
                                <Typography color="error.main">
                                    {errorMessage}
                                </Typography>
                            )}
                        </Grid>
                        {workoutPlay && session && (
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 6,
                                }}
                            >
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Session</TableCell>
                                            <TableCell>
                                                <Link
                                                    component={RouterLink}
                                                    to={`../../sessions/${session.id}`}
                                                >
                                                    View
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                        {session.location && (
                                            <TableRow>
                                                <TableCell>Location</TableCell>
                                                <TableCell>
                                                    <Link
                                                        component={RouterLink}
                                                        to={`../../locations/${session.location?.id}`}
                                                    >
                                                        {session.location?.name}
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <TableCell>Users</TableCell>
                                            <TableCell>
                                                <JoinedList>
                                                    {session.sessionUsers.map(
                                                        ({ user }) => (
                                                            <Link
                                                                component={
                                                                    RouterLink
                                                                }
                                                                to={`../../users/${user.id}`}
                                                                key={user.id}
                                                            >
                                                                {`${user.firstName} ${user.lastName}`}
                                                            </Link>
                                                        ),
                                                    )}
                                                </JoinedList>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Trainers</TableCell>
                                            <TableCell>
                                                {session.sessionTrainers.map(
                                                    ({ trainer }, i) => (
                                                        <Link
                                                            component={
                                                                RouterLink
                                                            }
                                                            to={`../../trainers/${trainer.id}`}
                                                            key={trainer.id}
                                                        >
                                                            {`${i > 0 ? " ," : ""}#${trainer.clientId}`}
                                                        </Link>
                                                    ),
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Workout</TableCell>
                                            <TableCell>
                                                <Link
                                                    component={RouterLink}
                                                    to={`../../app-workouts/${workoutPlay.workout.id}`}
                                                >
                                                    {name || "-"}
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Start Time</TableCell>
                                            <TableCell
                                                title={new Date(
                                                    workoutPlay.startTime,
                                                ).toISOString()}
                                            >
                                                {usDateFormat(
                                                    new Date(
                                                        workoutPlay.startTime,
                                                    ),
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>End Time</TableCell>
                                            <TableCell
                                                title={
                                                    workoutPlay.endTime
                                                        ? new Date(
                                                              workoutPlay.endTime,
                                                          ).toISOString()
                                                        : undefined
                                                }
                                            >
                                                {workoutPlay.endTime
                                                    ? usDateFormat(
                                                          new Date(
                                                              workoutPlay.endTime,
                                                          ),
                                                      )
                                                    : ""}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Duration</TableCell>
                                            <TableCell>
                                                {workoutPlay.endTime
                                                    ? formatDuration(
                                                          intervalToDuration({
                                                              start: new Date(
                                                                  workoutPlay.startTime,
                                                              ),
                                                              end: new Date(
                                                                  workoutPlay.endTime,
                                                              ),
                                                          }),
                                                      )
                                                    : "-"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Throw Count</TableCell>
                                            <TableCell>
                                                {workoutPlay.throwCount}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        )}
                        <Grid
                            size={{
                                xs: 12,
                                md: 1,
                            }}
                        />
                        {workoutPlay && (
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 5,
                                }}
                            >
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Parameter</TableCell>
                                            <TableCell>Value</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.entries(
                                            workoutPlay.params as Record<
                                                string,
                                                string
                                            >,
                                        ).map(([key, value]) => (
                                            <TableRow key={key}>
                                                <TableCell>{key}</TableCell>
                                                <TableCell>
                                                    {String(value)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
                <DialogPaper sx={{ my: 1, p: 2 }}>
                    <Grid container>
                        <Grid sx={{ marginBottom: 4 }} size={10}>
                            <Typography component="h1" variant="h2">
                                Visualizer
                            </Typography>
                        </Grid>
                        {workoutPlay && (
                            <Grid size={12}>
                                <WorkoutPlayVisualizer
                                    workoutPlay={workoutPlay}
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogPaper>
                <DialogPaper sx={{ my: 1, p: 2 }}>
                    <Grid container>
                        <Grid sx={{ marginBottom: 4 }} size={10}>
                            <Typography component="h1" variant="h2">
                                Throws
                            </Typography>
                        </Grid>
                        {workoutPlay && (
                            <Grid size={12}>
                                <WorkoutThrowsTable
                                    workoutPlayId={workoutPlay.id}
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogPaper>
                <DialogPaper sx={{ my: 1, p: 2 }}>
                    <Grid container>
                        <Grid sx={{ marginBottom: 4 }} size={10}>
                            <Typography component="h1" variant="h2">
                                Results
                            </Typography>
                        </Grid>
                        {workoutPlay?.workoutResult && (
                            <Grid size={12}>
                                <WorkoutResultsTable
                                    results={
                                        workoutPlay.workoutResult.resultData
                                    }
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogPaper>
            </Grid>
        </Grid>
    );
}
