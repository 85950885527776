import * as React from "react";
import { Link as RouterLink } from "react-router";

import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import {
    ContentProvider,
    OffsetResult,
    Sport,
    type AppWorkoutWithPlayCount,
} from "@volley/data";

import logger from "../../../log";
import { fetchApi, usDateFormat } from "../../../util";
import useDebounce from "../../hooks/useDebounce";
import usePaginatedData from "../../hooks/usePaginatedData";

export default function AppWorkoutsTable(): React.JSX.Element {
    const [appId, setAppId] = React.useState<number | null>(null);
    const [availableSports, setAvailableSports] = React.useState<Sport[]>([]);
    const [availableProviders, setAvailableProviders] = React.useState<
        ContentProvider[]
    >([]);
    const [sport, setSport] = React.useState<string | null>(null);
    const [provider, setProvider] = React.useState<number | null>(null);
    const [search, setSearch] = React.useState("");
    const debouncedSearch = useDebounce(search);

    React.useEffect(() => {
        async function fetchFilters() {
            const [sports, contentProviders] = await Promise.allSettled([
                fetchApi<Sport[]>("/api/sports"),
                fetchApi<OffsetResult<ContentProvider>>(
                    "/api/content-providers?limit=100&offset=0",
                ),
            ]);

            if (sports.status === "fulfilled") {
                setAvailableSports(sports.value);
            } else {
                logger.info("Failed to fetch sports", sports.reason);
            }
            if (contentProviders.status === "fulfilled") {
                setAvailableProviders(contentProviders.value.result);
            } else {
                logger.info(
                    "Failed to fetch content providers",
                    contentProviders.reason,
                );
            }
        }
        fetchFilters().catch((err) => logger.error(err));
    }, []);

    const {
        rowsPerPage,
        onRowsPerPageChange,
        count,
        page,
        onPageChange,
        data: workouts,
    } = usePaginatedData<AppWorkoutWithPlayCount>(`/api/app-workouts/admin`, {
        id: "workouts",
        params: {
            appId: appId ? appId.toString() : undefined,
            includePlayCount: "true",
            includeSoftDelete: "true",
            provider: provider ? provider.toString() : undefined,
            sport: sport ? sport : undefined,
            q: debouncedSearch || undefined,
        },
    });

    const onChangeSearch = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (page > 0) {
                onPageChange(e, 0);
            }
            setSearch(e.currentTarget.value);
        },
        [page, onPageChange],
    );

    return (
        <Grid container spacing={3}>
            <Grid size={12}>
                <Typography component="h1" variant="h2">
                    Workouts
                </Typography>
            </Grid>
            <Grid size={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <Grid container spacing={3}>
                        <Grid size={3}>
                            <TextField
                                size="small"
                                type="search"
                                label="Workout Name"
                                value={search}
                                onChange={onChangeSearch}
                                slotProps={{
                                    input: {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    },
                                }}
                            />
                        </Grid>
                        <Grid size={3}>
                            <Select
                                size="small"
                                value={appId ? appId : "ALL"}
                                onChange={(e) => {
                                    onPageChange(e, 0);
                                    if (e.target.value === "ALL") {
                                        setAppId(null);
                                    } else {
                                        setAppId(e.target.value as number);
                                    }
                                }}
                            >
                                <MenuItem value="ALL">
                                    All Workout Types
                                </MenuItem>
                                <MenuItem value={11}>Module</MenuItem>
                                <MenuItem value={9}>Multi-Level</MenuItem>
                                <MenuItem value={4}>
                                    Multi-Shot Workout
                                </MenuItem>
                                <MenuItem value={5}>
                                    Single-Shot Workouts
                                </MenuItem>
                                <MenuItem value={7}>Shared</MenuItem>
                                <MenuItem value={8}>Third Shot Drop</MenuItem>
                                <MenuItem value={2}>Responsive</MenuItem>
                            </Select>
                        </Grid>
                        <Grid size={3}>
                            <Select
                                size="small"
                                value={sport ? sport : "ALL"}
                                onChange={(e) => {
                                    onPageChange(e, 0);
                                    if (e.target.value === "ALL") {
                                        setSport(null);
                                    } else {
                                        setSport(e.target.value);
                                    }
                                }}
                            >
                                <MenuItem value="ALL">All Sports</MenuItem>
                                {availableSports.map((s) => (
                                    <MenuItem key={s.name} value={s.name}>
                                        {s.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid size={3}>
                            <Select
                                size="small"
                                value={provider ? provider : "ALL"}
                                onChange={(e) => {
                                    onPageChange(e, 0);
                                    if (e.target.value === "ALL") {
                                        setProvider(null);
                                    } else {
                                        setProvider(e.target.value as number);
                                    }
                                }}
                            >
                                <MenuItem value="ALL">
                                    All Content Providers
                                </MenuItem>
                                {availableProviders.map((p) => (
                                    <MenuItem key={p.id} value={p.id}>
                                        {p.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Name</TableCell>
                                    <TableCell>App</TableCell>
                                    <TableCell>Sport</TableCell>
                                    <TableCell>Play Count</TableCell>
                                    <TableCell>Created By</TableCell>
                                    <TableCell>Created At</TableCell>
                                    <TableCell>Published?</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {workouts.map((w) => (
                                    <TableRow key={w.id}>
                                        <TableCell>
                                            <Button
                                                component={RouterLink}
                                                to={w.id.toString()}
                                            >
                                                View
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            <Link
                                                component={RouterLink}
                                                to={w.id.toString()}
                                            >
                                                {w.name}
                                            </Link>
                                        </TableCell>
                                        <TableCell>{w.appName}</TableCell>
                                        <TableCell>{w.sport.label}</TableCell>
                                        <TableCell>
                                            {w._count?.workoutPlays}
                                        </TableCell>
                                        <TableCell>{w.createdBy}</TableCell>
                                        <TableCell>
                                            {usDateFormat(
                                                new Date(w.createdAt),
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {w.contentProviderId ? "Yes" : "No"}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        colSpan={11}
                                        rowsPerPage={rowsPerPage}
                                        count={count}
                                        page={page}
                                        onPageChange={onPageChange}
                                        onRowsPerPageChange={
                                            onRowsPerPageChange
                                        }
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        </Grid>
    );
}
