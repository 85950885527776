import * as React from "react";
import { Link as RouterLink } from "react-router";

import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import type { LocationWithRelations } from "@volley/data";

import useDebounce from "../../hooks/useDebounce";
import usePaginatedData from "../../hooks/usePaginatedData";
import AdminLoading from "../AdminLoading";

export default function LocationsTable(): React.JSX.Element {
    const [search, setSearch] = React.useState("");
    const debouncedSearch = useDebounce(search);
    const params = React.useMemo(
        () => ({ q: debouncedSearch || undefined }),
        [debouncedSearch],
    );
    const {
        rowsPerPage,
        onRowsPerPageChange,
        count,
        page,
        onPageChange,
        data: locations,
        loading,
    } = usePaginatedData<LocationWithRelations>("/api/locations", {
        id: "locations",
        params,
    });

    const onChangeSearch = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (page > 0) {
                onPageChange(e, 0);
            }
            setSearch(e.currentTarget.value);
        },
        [page, onPageChange],
    );

    return (
        <Grid container spacing={3}>
            <Grid size={12}>
                <Typography component="h1" variant="h2">
                    Locations
                </Typography>
            </Grid>
            <Grid size={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <Grid container spacing={3}>
                        <Grid>
                            <Button
                                variant="contained"
                                component={RouterLink}
                                to="new"
                            >
                                New Location
                            </Button>
                        </Grid>
                        <Grid>
                            <TextField
                                type="search"
                                size="small"
                                label="Search"
                                value={search}
                                onChange={onChangeSearch}
                                slotProps={{
                                    input: {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                    {loading ? (
                        <AdminLoading />
                    ) : (
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>Name</TableCell>
                                        <TableCell>City</TableCell>
                                        <TableCell>State</TableCell>
                                        <TableCell>Leasing Location?</TableCell>
                                        <TableCell>Home Location?</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {locations.map((l) => (
                                        <TableRow key={l.id}>
                                            <TableCell>
                                                <Button
                                                    component={RouterLink}
                                                    to={l.id.toString()}
                                                >
                                                    View
                                                </Button>
                                            </TableCell>
                                            <TableCell>{l.name}</TableCell>
                                            <TableCell>
                                                {l.mailingAddress?.city}
                                            </TableCell>
                                            <TableCell>
                                                {l.mailingAddress?.state}
                                            </TableCell>
                                            <TableCell>
                                                {l.leaseAccessAt ? "✓" : ""}
                                            </TableCell>
                                            <TableCell>
                                                {l.homeLocation ? "✓" : ""}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={7}
                                            rowsPerPage={rowsPerPage}
                                            count={count}
                                            page={page}
                                            onPageChange={onPageChange}
                                            onRowsPerPageChange={
                                                onRowsPerPageChange
                                            }
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
}
