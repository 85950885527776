import * as React from "react";
import { Link as RouterLink } from "react-router";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { TestReport } from "@volley/shared/coach-models";

import { usDateFormat } from "../../../util";
import usePaginatedData from "../../hooks/usePaginatedData";
import AdminLoading from "../AdminLoading";

interface Props {
    trainerId?: number;
}

export default function TestReportTable({
    trainerId,
}: Props): React.JSX.Element {
    const {
        rowsPerPage,
        onRowsPerPageChange,
        count,
        page,
        onPageChange,
        loading,
        data: reports,
    } = usePaginatedData<TestReport>("/api/test-results", {
        id: "test-results",
        params: trainerId ? { trainerId: String(trainerId) } : undefined,
    });

    return (
        <Grid container spacing={3}>
            <Grid size={12}>
                <Typography component="h1" variant="h2">
                    Self-Test Reports
                </Typography>
            </Grid>
            <Grid size={12}>
                <Paper
                    sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {loading ? (
                        <AdminLoading />
                    ) : (
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>Client ID</TableCell>
                                        <TableCell>Timestamp</TableCell>
                                        <TableCell>Result</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reports.map((report) => (
                                        <TableRow key={report.id}>
                                            <TableCell>
                                                <Button
                                                    component={RouterLink}
                                                    to={`/admin/test-results/${report.runId}`}
                                                >
                                                    View
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Link
                                                    component={RouterLink}
                                                    to={`/admin/trainers/${report.trainerId ?? ""}`}
                                                >
                                                    {report.clientId}
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                {usDateFormat(
                                                    new Date(report.timestamp),
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                >
                                                    {report.outcome ===
                                                    "Passed" ? (
                                                        <CheckCircleIcon
                                                            style={{
                                                                color: "green",
                                                            }}
                                                        />
                                                    ) : (
                                                        <CancelIcon
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        />
                                                    )}

                                                    <Typography
                                                        variant="body1"
                                                        sx={{ ml: 1 }}
                                                    >
                                                        {report.outcome}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={4}
                                            rowsPerPage={rowsPerPage}
                                            count={count}
                                            page={page}
                                            onPageChange={onPageChange}
                                            onRowsPerPageChange={
                                                onRowsPerPageChange
                                            }
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
}
