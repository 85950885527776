import * as React from "react";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { UserWithRelations } from "@volley/data";

interface Props {
    handleSubmitParent: (user: UserWithRelations) => void;
    locationName: string;
    user: UserWithRelations;
}

export default function ProAddDialog({
    handleSubmitParent,
    locationName,
    user,
}: Props) {
    const [open, setOpen] = React.useState(false);
    const fullName = `${user.firstName} ${user.lastName}`;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRemove = () => {
        setOpen(false);
        handleSubmitParent(user);
    };

    return (
        <>
            <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                    handleClickOpen();
                }}
            >
                <AddIcon color="success" />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-assign-pro-to-location"
                aria-describedby="alert-dialog-assign-pro-to-location"
            >
                <DialogTitle id="alert-dialog-assign-pro">
                    {`Assign Pro to ${locationName}?`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-assign-pro-to-location"
                        align="left"
                    >
                        {`${fullName} (${user.username}) will be assigned to ${locationName}.
                        This will open their club summary feature.`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleRemove} color="success">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
