import * as React from "react";

import Button, { ButtonProps } from "@mui/material/Button";

export enum SaveState {
    Unsaved,
    Error,
    Saving,
    Saved,
}

interface Props {
    saveState: SaveState;
}

export default function AdminSaveButton({
    saveState,
    ...buttonProps
}: Props & ButtonProps): React.JSX.Element {
    return (
        <Button
            loading={saveState === SaveState.Saving}
            color={saveState === SaveState.Saved ? "success" : "primary"}
            {...buttonProps}
        >
            {saveState === SaveState.Saved ? "Saved!" : "Save"}
        </Button>
    );
}
