import * as React from "react";
import { useParams } from "react-router";

import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import type {
    ExtractVolleyEvent,
    TrainerControlAction,
    TrainerEvent,
    VolleyEvent,
} from "@volley/shared/events/volley-event";

type State = ExtractVolleyEvent<"trainer.status">["data"];
type ErrorState = ExtractVolleyEvent<"trainer.error">["data"];

function TrainerThinClient({
    state,
    sendAction,
}: {
    state: State;
    sendAction: (a: TrainerControlAction) => void;
}): React.JSX.Element {
    return (
        <>
            <Typography gutterBottom variant="h2">
                {`Trainer #${state.clientId}`}
            </Typography>
            <Stack p={2} spacing={2} direction="row">
                <Button
                    variant="contained"
                    disabled={!state.controls.includes("workout.pause")}
                    onClick={() => sendAction("workout.pause")}
                >
                    Pause
                </Button>
                <Button
                    variant="contained"
                    disabled={!state.controls.includes("workout.resume")}
                    onClick={() => sendAction("workout.resume")}
                >
                    Resume
                </Button>
                <Button
                    variant="contained"
                    disabled={!state.controls.includes("workout.stop")}
                    onClick={() => sendAction("workout.stop")}
                >
                    Stop
                </Button>
                <Button
                    variant="contained"
                    disabled={!state.controls.includes("workout.capture")}
                    onClick={() => sendAction("workout.capture")}
                >
                    Capture
                </Button>
            </Stack>
            <Stack p={2} spacing={2}>
                {state.workout && (
                    <>
                        <Typography>{state.workout.name}</Typography>
                        {state.workout.throws && (
                            <Typography>
                                {state.workout.throws.thrown}
                                {" / "}
                                {state.workout.throws.isEndless
                                    ? "∞"
                                    : state.workout.throws.requested}
                            </Typography>
                        )}
                    </>
                )}
                {!state.workout && state.previousWorkout && (
                    <Typography>{`Previous: ${state.previousWorkout.name}`}</Typography>
                )}
            </Stack>
        </>
    );
}

const defaultState: ExtractVolleyEvent<"trainer.status">["data"] = {
    clientId: 0,
    controls: [],
    previousWorkout: null,
    workout: null,
};

function reducer(state: State | null, event: VolleyEvent): State | null {
    switch (event.type) {
        case "trainer.status":
            return event.data;
        case "trainer.status.controls":
            return {
                ...(state ?? defaultState),
                clientId: event.data.clientId,
                controls: event.data.controls,
            };
        case "trainer.status.workout":
            return {
                ...(state ?? defaultState),
                clientId: event.data.clientId,
                workout: event.data.workout,
                previousWorkout: event.data.previousWorkout,
            };
        case "user.status":
            return event.data.trainers[0]
                ? { ...event.data.trainers[0] }
                : state;
        case "user.unpaired":
            return null;
        default:
            return state;
    }
}

export default function UserThinClient(): React.JSX.Element {
    const { id: rawUserId } = useParams<"id">();
    const userId = parseInt(rawUserId ?? "", 10);
    const [socket, setSocket] = React.useState<WebSocket | null>(null);
    const [reconnectAttempts, setReconnectAttempts] = React.useState(0);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [state, dispatch] = React.useReducer(reducer, null);
    const [lastError, setLastError] = React.useState<ErrorState | null>(null);
    const [messages, setMessages] = React.useState<[Date, MessageEvent][]>([]);

    const sendAction = (action: TrainerControlAction) => {
        if (!socket || !state) return;
        const event: ExtractVolleyEvent<"trainer.control"> = {
            type: "trainer.control",
            data: {
                clientId: state.clientId,
                action,
                parameters: null,
            },
        };
        socket.send(JSON.stringify(event));
    };

    React.useEffect(() => {
        const protocol = window.location.protocol === "https:" ? "wss:" : "ws:";
        const newSocket = new WebSocket(
            `${protocol}//${window.location.host}/api/ws/volley-events?emulateUserId=${userId}`,
        );
        newSocket.onopen = () => {
            setMessages([]);
        };
        newSocket.onmessage = (rawEvent) => {
            const event = JSON.parse(rawEvent.data as string) as VolleyEvent;
            if (event.type === "trainer.error") {
                setLastError(event.data);
            } else {
                dispatch(event as TrainerEvent);
            }
            setMessages((prev) => {
                const next = [
                    ...prev,
                    [new Date(), rawEvent] as [Date, MessageEvent],
                ];
                return next.length > 100 ? next.slice(next.length - 100) : next;
            });
        };
        newSocket.onerror = () => {
            newSocket.close();
            if (reconnectAttempts < 5) {
                setTimeout(
                    () => {
                        setReconnectAttempts((current) => current + 1);
                    },
                    reconnectAttempts + 1 * 2000,
                );
            } else {
                setErrorMessage(
                    "Connection to WebSocket lost. Please refresh the page to try again.",
                );
            }
        };
        setSocket(newSocket);

        return () => {
            newSocket.close();
        };
    }, [reconnectAttempts, userId]);

    return (
        <Paper sx={{ p: 2 }}>
            {errorMessage && (
                <Typography gutterBottom color="error.main" align="center">
                    {errorMessage}
                </Typography>
            )}
            {lastError && (
                <Typography gutterBottom color="error.main" align="center">
                    Last Error
                    {`${lastError.code} - ${lastError.message}`}
                </Typography>
            )}
            {state && (
                <TrainerThinClient state={state} sendAction={sendAction} />
            )}
            <Typography gutterBottom variant="h2">
                {`User ID ${userId} Events`}
            </Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableCell>Timestamp</TableCell>
                        <TableCell>Data</TableCell>
                    </TableHead>
                    <TableBody>
                        {messages
                            .slice()
                            .reverse()
                            .map(([date, m]) => (
                                <TableRow key={date.getTime()}>
                                    <TableCell>
                                        {date.toLocaleString()}
                                    </TableCell>
                                    <TableCell>
                                        <pre>{m.data}</pre>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
