import * as React from "react";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { PartnerLocationWithRelations } from "@volley/data";

interface Props {
    handleSubmitParent: (pro: PartnerLocationWithRelations) => void;
    locationName: string;
    pro: PartnerLocationWithRelations;
}

export default function ProRemoveDialog({
    handleSubmitParent,
    locationName,
    pro,
}: Props) {
    const [open, setOpen] = React.useState(false);
    const fullName = `${pro.user.firstName} ${pro.user.lastName}`;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRemove = () => {
        setOpen(false);
        handleSubmitParent(pro);
    };

    return (
        <>
            <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                    handleClickOpen();
                }}
            >
                <RemoveCircleOutlineIcon color="error" />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-remove-pro-from-location"
                aria-describedby="alert-dialog-remove-pro-from-location"
            >
                <DialogTitle id="alert-dialog-title">
                    {`Remove Pro From ${locationName}?`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-remove-pro-from-location"
                        align="left"
                    >
                        {`${fullName} (${pro.user.username}) will no longer be assigned to ${locationName}.
                        This will remove their club summary feature.`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleRemove} color="error">
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
