import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { WorkoutPlayWithResults } from "@volley/data";
import { ServeResult } from "@volley/shared/apps/serveandvolley-models";

interface ResultsTableProps {
    results: NonNullable<WorkoutPlayWithResults["workoutResult"]>["resultData"];
}

function isServeResultArray(result: unknown): result is ServeResult[] {
    if (
        Array.isArray(result) &&
        result.length &&
        "inAoi" in (result.at(0) as unknown as ServeResult)
    ) {
        return true;
    }
    return false;
}

export default function ResultsTable({
    results,
}: ResultsTableProps): React.JSX.Element {
    if (!isServeResultArray(results)) {
        return (
            <Typography variant="body1" sx={{ my: 2 }}>
                No results available.
            </Typography>
        );
    }

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Cycle</TableCell>
                    <TableCell>Track ID</TableCell>
                    <TableCell>Score Method</TableCell>
                    <TableCell>Serve Trigger</TableCell>
                    <TableCell>In AOI</TableCell>
                    <TableCell>In Service Box</TableCell>
                    <TableCell>Target</TableCell>
                    <TableCell>Position (x, y, z)</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {results.map((result, index) => (
                    <TableRow key={index}>
                        <TableCell>{result.debug.cycle}</TableCell>
                        <TableCell>{result.debug.trackId ?? "-"}</TableCell>
                        <TableCell>{result.debug.scoreMethod}</TableCell>
                        <TableCell>
                            {result.debug.serveTrigger ?? "-"}
                        </TableCell>
                        <TableCell>{result.inAoi ? "Yes" : "No"}</TableCell>
                        <TableCell>
                            {result.inServiceBox ? "Yes" : "No"}
                        </TableCell>
                        <TableCell>{result.target as string}</TableCell>
                        <TableCell>
                            {result.position
                                ? `${result.position.x}, ${result.position.y}, ${result.position.z}`
                                : "-"}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
