import * as React from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router";

import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { formatDuration, intervalToDuration } from "date-fns";

import type { ClipWithRelationsJson } from "@volley/data";
import { FramePrimitive } from "@volley/shared/vision-models";

import { round, usDateFormat } from "../../../util";
import fetchApi from "../../../util/fetchApi";
import ClipVideoOnCanvas from "../../Clips/ClipVideoOnCanvas";
import JoinedList from "../../common/JoinedList";
import Loading from "../../common/Loading";

async function fetchPrimitives(
    url: string,
    contentType: string,
): Promise<FramePrimitive[]> {
    const response = await fetch(url, {
        headers: { accept: `${contentType}, text/plain, */*` },
    });
    const primitives = (await response.json()) as FramePrimitive[];
    return primitives;
}

export default function ClipView(): React.JSX.Element {
    const navigate = useNavigate();
    const { id } = useParams<"id">();
    const [state, setState] = React.useState<ClipWithRelationsJson | null>(
        null,
    );
    const [primitives, setPrimitives] = React.useState<FramePrimitive[] | null>(
        null,
    );
    const [errorMessage, setErrorMessage] = React.useState("");

    React.useEffect(() => {
        async function fetchData(clipId: number) {
            const data = await fetchApi<ClipWithRelationsJson>(
                `/api/clips/${clipId}`,
            );

            let primitivesData: FramePrimitive[] | null = null;
            if (data.clipPrimitives?.length) {
                const file = data.clipPrimitives[0].objectStoreFile;
                if (!file.url) return;

                try {
                    primitivesData = await fetchPrimitives(
                        file.url,
                        file.contentType,
                    );
                } catch (e: unknown) {
                    setErrorMessage((e as Error).message);
                }
            }

            setState({
                ...data,
                startedAt: new Date(data.startedAt),
                finishedAt: new Date(data.finishedAt),
            });

            if (primitivesData) {
                setPrimitives(primitivesData);
            }
        }

        const validId = parseInt(id ?? "", 10);
        if (validId) {
            fetchData(validId).catch((e: unknown) =>
                setErrorMessage(String(e)),
            );
        }
    }, [id]);

    if (!state) {
        if (errorMessage) {
            return <Typography color="error.main">{errorMessage}</Typography>;
        }

        return <Loading />;
    }

    const { workoutThrow } = state;
    const session = workoutThrow?.workoutPlay.session;
    const trainers = session?.sessionTrainers.map((t) => t.trainer);
    const users = session?.sessionUsers.map((u) => u.user);
    const posterUrl =
        state.clipThumbnails.at(0)?.objectStoreFile.url ?? undefined;
    const primitivesUrl =
        state.clipPrimitives?.at(0)?.objectStoreFile.url ?? undefined;

    return (
        <Grid container spacing={3}>
            <Grid size={12}>
                <Paper
                    sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        "& > :not(style)": { m: 1 },
                    }}
                >
                    <Grid container>
                        <Grid size={10}>
                            <Typography
                                component="h1"
                                variant="h3"
                                title={`ID: ${state.id}`}
                            >
                                Workout Throw Clip
                            </Typography>
                        </Grid>
                        <Grid sx={{ textAlign: "right" }} size={2}>
                            <IconButton
                                size="large"
                                onClick={() => navigate(-1)}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {!primitives && state.objectStoreFile.url && (
                        <video
                            autoPlay
                            loop
                            muted
                            // The `playsInline` attribute forces Safari to not fullscreen auto-playing videos like this
                            // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video#playsinline
                            playsInline
                            src={state.objectStoreFile.url}
                            controls
                            poster={posterUrl}
                        />
                    )}
                    {primitives && state.objectStoreFile.url && (
                        <ClipVideoOnCanvas
                            videoUrl={state.objectStoreFile.url}
                            posterUrl={posterUrl}
                            primitives={primitives}
                        />
                    )}
                    {primitivesUrl && (
                        <Button component="a" href={primitivesUrl} download>
                            Download Primitives JSON
                        </Button>
                    )}
                    {!!errorMessage && (
                        <Typography color="error.main">
                            {errorMessage}
                        </Typography>
                    )}
                    {workoutThrow && session && trainers && users && (
                        <Grid container component="dl" spacing={2}>
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 6,
                                }}
                            >
                                <Typography component="dt" variant="h6">
                                    Trainer
                                </Typography>
                                <Typography component="dd" variant="body2">
                                    <JoinedList>
                                        {trainers.map((trainer) => (
                                            <Link
                                                component={RouterLink}
                                                to={`../../trainers/${trainer.id}`}
                                                key={trainer.id}
                                            >
                                                {`#${trainer.clientId}`}
                                            </Link>
                                        ))}
                                    </JoinedList>
                                </Typography>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 6,
                                }}
                            >
                                <Typography component="dt" variant="h6">
                                    User
                                </Typography>
                                <Typography component="dd" variant="body2">
                                    <JoinedList>
                                        {users.map((user) => (
                                            <Link
                                                component={RouterLink}
                                                to={`../../users/${user.id}`}
                                                key={user.id}
                                            >
                                                {`${user.firstName} ${user.lastName}`}
                                            </Link>
                                        ))}
                                    </JoinedList>
                                </Typography>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 6,
                                }}
                            >
                                <Typography component="dt" variant="h6">
                                    Session
                                </Typography>
                                <Typography component="dd" variant="body2">
                                    <Link
                                        component={RouterLink}
                                        to={`../../sessions/${session.id}`}
                                    >
                                        View Session
                                    </Link>
                                </Typography>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 6,
                                }}
                            >
                                <Typography component="dt" variant="h6">
                                    Workout Play
                                </Typography>
                                <Typography component="dd" variant="body2">
                                    <Link
                                        component={RouterLink}
                                        to={`../../workout-plays/${workoutThrow.workoutPlay.id}`}
                                    >
                                        View Workout Play
                                    </Link>
                                </Typography>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 6,
                                }}
                            >
                                <Typography component="dt" variant="h6">
                                    Duration
                                </Typography>
                                <Typography
                                    component="dd"
                                    variant="body2"
                                    title={`${String(state.startedAt)} – ${String(state.finishedAt)}`}
                                >
                                    {formatDuration(
                                        intervalToDuration({
                                            start: state.startedAt,
                                            end: state.finishedAt,
                                        }),
                                    )}
                                    {", starting at "}
                                    {usDateFormat(state.startedAt)}
                                </Typography>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 6,
                                }}
                            >
                                <Typography component="dt" variant="h6">
                                    Position
                                </Typography>
                                <Typography component="dd" variant="body2">
                                    {`X: ${round(workoutThrow.positionX ?? 0, 1)}`}
                                    <br />
                                    {`Y: ${round(workoutThrow.positionY ?? 0, 1)}`}
                                    <br />
                                    {`Yaw: ${round(workoutThrow.positionYaw ?? 0, 1)}`}
                                </Typography>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 6,
                                }}
                            >
                                <Typography component="dt" variant="h6">
                                    Pan
                                </Typography>
                                <Typography component="dd" variant="body2">
                                    {`Target: ${round(workoutThrow.targetPan, 1)}`}
                                    <br />
                                    {`Actual: ${round(workoutThrow.actualPan ?? 0, 1)}`}
                                </Typography>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 6,
                                }}
                            >
                                <Typography component="dt" variant="h6">
                                    Tilt
                                </Typography>
                                <Typography component="dd" variant="body2">
                                    {`Target: ${round(workoutThrow.targetTilt, 1)}`}
                                    <br />
                                    {`Actual: ${round(workoutThrow.actualTilt ?? 0, 1)}`}
                                </Typography>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 6,
                                }}
                            >
                                <Typography component="dt" variant="h6">
                                    Height
                                </Typography>
                                <Typography component="dd" variant="body2">
                                    {`Target: ${round(workoutThrow.targetHeight ?? 0, 1)}`}
                                    <br />
                                    {`Actual: ${round(workoutThrow.actualHeight ?? 0, 1)}`}
                                </Typography>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 6,
                                }}
                            >
                                <Typography component="dt" variant="h6">
                                    RPM (Top)
                                </Typography>
                                <Typography component="dd" variant="body2">
                                    {`Target: ${round(workoutThrow.targetRpmTop, 0)}`}
                                    <br />
                                    {`Actual: ${round(workoutThrow.actualRpmTop ?? 0, 0)}`}
                                </Typography>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 6,
                                }}
                            >
                                <Typography component="dt" variant="h6">
                                    RPM (Left)
                                </Typography>
                                <Typography component="dd" variant="body2">
                                    {`Target: ${round(workoutThrow.targetRpmLeft, 0)}`}
                                    <br />
                                    {`Actual: ${round(workoutThrow.actualRpmLeft ?? 0, 0)}`}
                                </Typography>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 6,
                                }}
                            >
                                <Typography component="dt" variant="h6">
                                    RPM (Right)
                                </Typography>
                                <Typography component="dd" variant="body2">
                                    {`Target: ${round(workoutThrow.targetRpmRight, 0)}`}
                                    <br />
                                    {`Actual: ${round(workoutThrow.actualRpmRight ?? 0, 0)}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
}
