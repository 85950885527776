import * as React from "react";

import CircleIcon from "@mui/icons-material/Circle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { SystemStatusCellular } from "@volley/shared/coach-models";

import CellularStatusIcon from "../../common/CellularStatusIcon";

interface Props {
    cellular: SystemStatusCellular | null;
    activeInterface: string | null;
}

const CELLULAR_INTERFACE_NAME = "usb0";

export default function TrainerCellularStatus({
    cellular,
    activeInterface,
}: Props): React.JSX.Element {
    const handleCopyClick = async (toCopy: string) => {
        await navigator.clipboard.writeText(toCopy || "");
    };

    return (
        <Stack justifyContent="center">
            <Stack direction="row" alignItems="center">
                {activeInterface === CELLULAR_INTERFACE_NAME && (
                    <Box component="div">
                        <CircleIcon color="success" sx={{ mr: 1 }} />
                    </Box>
                )}
                <Typography variant="h3" mb={1}>
                    Cellular Status
                </Typography>
                <Box sx={{ ml: 1 }} component="div">
                    <CellularStatusIcon cellularStatus={cellular} />
                </Box>
            </Stack>

            {cellular && cellular.imei ? (
                <Table>
                    <TableBody>
                        <TableRow sx={{ "& th": { fontWeight: 500 } }}>
                            <TableCell component="th" sx={{ width: "25%" }}>
                                Connected Network
                            </TableCell>
                            <TableCell>
                                {cellular.network ? cellular.network : "-"}
                            </TableCell>
                        </TableRow>

                        <TableRow sx={{ "& th": { fontWeight: 500 } }}>
                            <TableCell component="th" sx={{ width: "25%" }}>
                                IMEI
                            </TableCell>
                            <TableCell>
                                <Box component="div">
                                    {cellular.imei}
                                    <IconButton
                                        size="small"
                                        sx={{ ml: 1 }}
                                        onClick={() =>
                                            handleCopyClick(cellular.imei)
                                        }
                                        title="Copy IMEI to Clipboard"
                                    >
                                        <ContentCopyIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            </TableCell>
                        </TableRow>

                        <TableRow sx={{ "& th": { fontWeight: 500 } }}>
                            <TableCell component="th" sx={{ width: "25%" }}>
                                ICCID
                            </TableCell>
                            <TableCell>
                                <Box component="div">
                                    {cellular.iccid}
                                    <IconButton
                                        size="small"
                                        sx={{ ml: 1 }}
                                        onClick={() =>
                                            handleCopyClick(cellular.iccid)
                                        }
                                        title="Copy ICCID to Clipboard"
                                    >
                                        <ContentCopyIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            </TableCell>
                        </TableRow>

                        <TableRow sx={{ "& th": { fontWeight: 500 } }}>
                            <TableCell component="th" sx={{ width: "25%" }}>
                                Signal (RSSI)
                            </TableCell>
                            <TableCell>{cellular.rssi}</TableCell>
                        </TableRow>

                        <TableRow sx={{ "& th": { fontWeight: 500 } }}>
                            <TableCell component="th" sx={{ width: "25%" }}>
                                BER
                            </TableCell>
                            <TableCell>{cellular.ber}</TableCell>
                        </TableRow>

                        <TableRow sx={{ "& th": { fontWeight: 500 } }}>
                            <TableCell component="th" sx={{ width: "25%" }}>
                                Monitor Ping (ms)
                            </TableCell>
                            <TableCell>{cellular.monitorPing}</TableCell>
                        </TableRow>

                        <TableRow sx={{ "& th": { fontWeight: 500 } }}>
                            <TableCell component="th" sx={{ width: "25%" }}>
                                Monitor score
                            </TableCell>
                            <TableCell>{cellular.score}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            ) : (
                <Typography>
                    No cellular connection or cellular status not available.
                </Typography>
            )}
        </Stack>
    );
}
