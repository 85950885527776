import * as React from "react";

import RefreshIcon from "@mui/icons-material/Refresh";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { expand } from "../../../util/buildLabel";
import fetchApi from "../../../util/fetchApi";

interface TempUpdateStatusType {
    name: string;
    platform: {
        name: string;
    };
    trainerSoftwareUpdateDownloadStatuses: {
        loaded: string;
        total: string;
        softwareBuildId: number;
    }[];
}

function formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) return "0 B";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

function ProgressIndicator({
    loaded,
    total,
}: {
    loaded: string;
    total: string | null;
}): React.JSX.Element {
    if (total === null) return <Typography>-</Typography>;

    // loaded and total are BigInt so come as strings over the wire
    const loadedNum = parseInt(loaded, 10);
    const totalNum = parseInt(total, 10);

    return (
        <Box component="div">
            <LinearProgress
                variant="determinate"
                value={(loadedNum / totalNum) * 100}
            />
            <Typography variant="caption">
                {Math.round((loadedNum / totalNum) * 100)}% &nbsp;&#183;&nbsp;
                {formatBytes(loadedNum)}/{formatBytes(totalNum)}
            </Typography>
        </Box>
    );
}

export default function TrainerUpdateDownloadStatus({
    trainerId,
}: {
    trainerId: number;
}): React.JSX.Element {
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState("");
    const [status, setStatus] = React.useState<TempUpdateStatusType[] | null>(
        null,
    );

    const refreshData = React.useCallback(async () => {
        setLoading(true);
        try {
            const result = await fetchApi<TempUpdateStatusType[]>(
                `/api/trainers/${trainerId}/update-download-status`,
            );
            if (result) {
                setStatus(result);
                setError("");
            }
        } catch (err: unknown) {
            setError((err as Error).message);
        } finally {
            setLoading(false);
        }
    }, [trainerId]);

    React.useEffect(() => {
        void refreshData();
    }, [refreshData, trainerId]);

    return (
        <Stack spacing={2}>
            <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
            >
                <Typography variant="h2" sx={{ flexGrow: 1 }}>
                    Update Download Status
                </Typography>
                <IconButton onClick={refreshData} aria-label="refresh">
                    <RefreshIcon />
                </IconButton>
            </Stack>
            {loading && <Typography>Loading...</Typography>}
            {error && <Typography color="error">{error}</Typography>}
            {!loading && !error && (
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        aria-label="update status table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>System</TableCell>
                                <TableCell>Progress</TableCell>
                                <TableCell>Build</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {status &&
                                status.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                { border: 0 },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.platform.name}
                                        </TableCell>
                                        <TableCell>
                                            {row
                                                .trainerSoftwareUpdateDownloadStatuses
                                                .length === 0 && (
                                                <ProgressIndicator
                                                    loaded="0"
                                                    total={null}
                                                />
                                            )}
                                            {row.trainerSoftwareUpdateDownloadStatuses.map(
                                                (progress) => (
                                                    <ProgressIndicator
                                                        key={
                                                            progress.softwareBuildId
                                                        }
                                                        loaded={progress.loaded}
                                                        total={progress.total}
                                                    />
                                                ),
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {row.name.length === 36
                                                ? expand(row.name)
                                                : row.name}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Stack>
    );
}
