import * as React from "react";
import { Link as RouterLink, useParams } from "react-router";

import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { formatDuration, intervalToDuration } from "date-fns";

import type { SessionWithDeepRelations } from "@volley/data";

import { usDateFormat } from "../../../util";
import fetchApi from "../../../util/fetchApi";
import JoinedList from "../../common/JoinedList";
import AdminLoadingPage from "../AdminLoading";
import ClipsTable from "../Clips/ClipsTable";
import FailuresTable from "../Failures/FailuresTable";
import IssueReportTable from "../IssueReportTable";
import SnapshotsGrid from "../Snapshots/SnapshotsGrid";
import WorkoutPlaysTable from "../WorkoutPlays/WorkoutPlayTable";

export default function SessionDetails(): React.JSX.Element {
    const { id } = useParams<"id">();
    const validId = parseInt(id ?? "", 10);
    const [session, setSession] =
        React.useState<SessionWithDeepRelations | null>(null);
    const [errorMessage, setErrorMessage] = React.useState("");

    React.useEffect(() => {
        async function fetchSession(sessionId: number) {
            const data = await fetchApi<SessionWithDeepRelations>(
                `/api/sessions/${sessionId}`,
            );
            setSession(data);
        }

        if (validId) {
            fetchSession(validId).catch((e: Error) => {
                setErrorMessage(e.message);
            });
        }
    }, [validId]);

    const usersDisplay = React.useMemo(
        () =>
            session?.sessionUsers.map(({ user }) => (
                <Link
                    component={RouterLink}
                    to={`/admin/users/${user.id}`}
                    key={user.id}
                >
                    {`${user.firstName} ${user.lastName}`}
                </Link>
            )),
        [session?.sessionUsers],
    );

    const trainersDisplay = React.useMemo(
        () =>
            session?.sessionTrainers.map(({ trainer }) => (
                <Link
                    component={RouterLink}
                    to={`/admin/trainers/${trainer.id}`}
                    key={trainer.id}
                >
                    {`${trainer.name} (#${trainer.clientId})`}
                </Link>
            )),
        [session?.sessionTrainers],
    );

    if (errorMessage) {
        return (
            <Typography color="error.main">
                {`Error loading session: ${errorMessage}`}
            </Typography>
        );
    }

    if (!session) {
        return <AdminLoadingPage page="Session" />;
    }

    return (
        <Grid container spacing={3}>
            <Grid
                container
                component={Paper}
                sx={{
                    ml: 3,
                    my: 3,
                    px: 1,
                    py: 4,
                }}
                size={12}
            >
                <Grid size={10}>
                    <Typography component="h1" variant="h2">
                        Session
                    </Typography>
                </Grid>
                <Grid sx={{ textAlign: "right" }} size={2}>
                    <IconButton size="large" component={RouterLink} to="..">
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        md: 6,
                    }}
                >
                    <Table size="small">
                        <TableBody>
                            {session.location && (
                                <TableRow>
                                    <TableCell>Location</TableCell>
                                    <TableCell>
                                        <Link
                                            component={RouterLink}
                                            to={`../../locations/${session.location?.id}`}
                                        >
                                            {session.location.name ?? "-"}
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            )}
                            <TableRow>
                                <TableCell>Users</TableCell>
                                <TableCell>
                                    <JoinedList>{usersDisplay}</JoinedList>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Trainers</TableCell>
                                <TableCell>
                                    <JoinedList>{trainersDisplay}</JoinedList>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Start Time</TableCell>
                                <TableCell
                                    title={new Date(
                                        session.startTime,
                                    ).toISOString()}
                                >
                                    {usDateFormat(new Date(session.startTime))}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>End Time</TableCell>
                                <TableCell
                                    title={
                                        session.endTime
                                            ? new Date(
                                                  session.endTime,
                                              ).toISOString()
                                            : undefined
                                    }
                                >
                                    {session.endTime
                                        ? usDateFormat(
                                              new Date(session.endTime),
                                          )
                                        : "-"}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Duration</TableCell>
                                <TableCell>
                                    {session.endTime
                                        ? formatDuration(
                                              intervalToDuration({
                                                  start: new Date(
                                                      session.startTime,
                                                  ),
                                                  end: new Date(
                                                      session.endTime,
                                                  ),
                                              }),
                                          )
                                        : "In progress"}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <Grid size={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <ClipsTable
                        entity="session"
                        id={validId}
                        initialRowsPerPage={12}
                    />
                </Paper>
            </Grid>
            <Grid size={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <SnapshotsGrid
                        entity="session"
                        id={validId}
                        title="Snapshots"
                    />
                </Paper>
            </Grid>
            <Grid size={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <FailuresTable entity="session" id={validId} />
                </Paper>
            </Grid>
            <Grid size={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <Grid container>
                        <Grid size={12}>
                            <Typography variant="h2">Workout Plays</Typography>
                        </Grid>
                        <Grid size={12}>
                            <Box
                                sx={{
                                    p: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <WorkoutPlaysTable sessionId={validId} />
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid size={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <Grid container>
                        <Grid size={12}>
                            <Typography variant="h2">
                                Reported Issues
                            </Typography>
                        </Grid>
                        <Grid size={12}>
                            <Box
                                sx={{
                                    p: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <IssueReportTable sessionId={validId} />
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}
