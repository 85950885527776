import * as React from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router";

import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import type {
    Location,
    LocationCreatePayload,
    LocationWithRelations,
    PartnerLocationWithRelations,
    UserWithRelations,
} from "@volley/data";

import { usDateFormat } from "../../../util";
import fetchApi, { logFetchError } from "../../../util/fetchApi";
import { COUNTRIES, STATES } from "../../common/data";
import AdminLoadingPage from "../AdminLoading";
import AdminSaveButton, { SaveState } from "../AdminSaveButton";
import IssueReportTable from "../IssueReportTable";
import SessionsTable from "../Sessions/SessionsTable";
import TrainersTable from "../Trainers/TrainersTable";

import LocationProTable from "./ProLocation/LocationProTable";
import ProRemoveDialog from "./ProLocation/ProRemoveDialog";

type Action =
    | { type: "name"; value: string }
    | { type: "hubspotCompanyId"; value: string }
    | { type: "address1"; value: string }
    | { type: "address2"; value: string }
    | { type: "city"; value: string }
    | { type: "state"; value: string }
    | { type: "postalCode"; value: string }
    | { type: "country"; value: string }
    | { type: "notes"; value: string }
    | { type: "isHomeLocation"; value: boolean }
    | { type: "leaseAccessAt"; value: string | null }
    | { type: "set"; value: FormState };

function reducer(state: FormState, action: Action): FormState {
    switch (action.type) {
        case "name":
            return { ...state, name: action.value };
        case "hubspotCompanyId":
            return { ...state, hubspotCompanyId: action.value };
        case "address1":
            return { ...state, address1: action.value };
        case "address2":
            return { ...state, address2: action.value };
        case "city":
            return { ...state, city: action.value };
        case "state":
            return { ...state, state: action.value };
        case "country":
            return { ...state, country: action.value, state: "" };
        case "postalCode":
            return { ...state, postalCode: action.value };
        case "notes":
            return { ...state, notes: action.value };
        case "isHomeLocation":
            return { ...state, isHomeLocation: action.value };
        case "leaseAccessAt":
            return { ...state, leaseAccessAt: action.value };
        case "set":
            return { ...action.value };
        default:
            throw new Error("Invalid form action type");
    }
}

interface FormState {
    id: number;
    name: string;
    hubspotCompanyId: string;
    notes: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    addressId: number | null;
    isHomeLocation: boolean;
    leaseAccessAt: string | null;
    deletedAt: Date | null;
    updatedBy: string;
}

type ErrorState = Partial<{
    name: string;
    address1: string;
    city: string;
    state: string;
    postalCode: string;
}>;

const defaultState: FormState = {
    id: 0,
    name: "",
    notes: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "USA",
    hubspotCompanyId: "",
    addressId: null,
    isHomeLocation: false,
    leaseAccessAt: null,
    deletedAt: null,
    updatedBy: "",
};

function validateAddress(state: FormState): ErrorState {
    const errors: ErrorState = {};

    if (state.address1.length < 5) {
        errors.address1 = "Address is required";
    }

    if (!state.city.length) {
        errors.city = "City is required";
    }

    if (!state.state) {
        errors.state = "State is required";
    }

    if (!state.postalCode) {
        errors.postalCode =
            state.country === "USA"
                ? "Zip Code is required"
                : "Postal Code is required";
    } else {
        if (
            state.country === "USA" &&
            !state.postalCode.match(/^\d{5}(?:[-\s]\d{4})?$/)
        ) {
            errors.postalCode =
                "Zip Code must be patterned XXXXX or XXXXX-XXXX";
        } else if (
            state.country === "CAN" &&
            !state.postalCode.match(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/)
        ) {
            errors.postalCode =
                "Postal Code must follow the pattern A1A 1A1 or A1A-1A1";
        }
    }

    return errors;
}

export default function LocationEdit(): React.JSX.Element {
    const { id } = useParams<"id">();
    const navigate = useNavigate();
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const [errorState, setErrorState] = React.useState<ErrorState>({});
    const [loading, setLoading] = React.useState(true);
    const [saveState, setSaveState] = React.useState(SaveState.Unsaved);
    const [users, setUsers] = React.useState<PartnerLocationWithRelations[]>(
        [],
    );
    const [newUser, setNewUser] = React.useState<UserWithRelations>();
    const [errorMessage, setErrorMessage] = React.useState("");

    React.useEffect(() => {
        async function fetchLocation() {
            const validId = parseInt(id ?? "", 10);
            if (!validId) {
                setLoading(false);
                return;
            }
            const data = await fetchApi<LocationWithRelations>(
                `/api/locations/${validId}`,
            );
            dispatch({
                type: "set",
                value: {
                    id: data.id,
                    name: data.name,
                    notes: data.notes ?? "",
                    address1: data.mailingAddress?.address1 ?? "",
                    address2: data.mailingAddress?.address2 ?? "",
                    city: data.mailingAddress?.city ?? "",
                    state: data.mailingAddress?.state ?? "",
                    postalCode: data.mailingAddress?.postalCode ?? "",
                    country: data.mailingAddress?.country ?? "USA",
                    hubspotCompanyId: data.hubspotCompanyId ?? "",
                    addressId: data.mailingAddress?.id ?? null,
                    isHomeLocation: data.homeLocation !== null,
                    leaseAccessAt: data.leaseAccessAt
                        ? new Date(data.leaseAccessAt).toISOString()
                        : null,
                    deletedAt: data.deletedAt ? new Date(data.deletedAt) : null,
                    updatedBy: data.updatedBy,
                },
            });
            setErrorMessage("");
            setLoading(false);
        }

        fetchLocation().catch((error: Error) => {
            setLoading(false);
            setErrorMessage(error.message);
        });
    }, [id]);

    const onSubmit = React.useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            const hasMailingAddress =
                state.address1 || state.city || state.state || state.postalCode;
            if (hasMailingAddress) {
                const nextErrorState = validateAddress(state);
                setErrorState(nextErrorState);
                if (Object.keys(nextErrorState).length) {
                    setSaveState(SaveState.Error);
                    return;
                }
            }

            setSaveState(SaveState.Saving);
            setErrorMessage("");
            const payload: LocationCreatePayload = {
                name: state.name.trim(),
                notes: state.notes?.trim() || null,
                hubspotCompanyId: state.hubspotCompanyId.trim() || null,
                mailingAddress: hasMailingAddress
                    ? {
                          id: state.addressId ?? undefined,
                          address1: state.address1.trim(),
                          address2: state.address2.trim(),
                          city: state.city.trim(),
                          state: state.state,
                          postalCode: state.postalCode.trim(),
                          country: state.country,
                      }
                    : null,
                isHomeLocation: state.isHomeLocation,
                leaseAccessAt: state.leaseAccessAt
                    ? new Date().toISOString()
                    : null,
            };

            let result: Location;
            try {
                if (state.id) {
                    result = await fetchApi(
                        `/api/locations/${state.id}`,
                        "PUT",
                        { ...payload, id: state.id },
                    );
                } else {
                    result = await fetchApi("/api/locations", "POST", payload);
                }
                setSaveState(SaveState.Saved);
                navigate(`../${result.id.toString()}`, { replace: true });
            } catch (error: unknown) {
                setErrorMessage((error as Error).message);
                setSaveState(SaveState.Error);
            }
        },
        [navigate, state],
    );

    const onDeactivate = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            const sure = window.confirm(
                "Are you sure you want to deactivate this location? " +
                    "Also, please remember to move any associated trainers beforehand.",
            );
            if (sure && id) {
                setErrorMessage("");
                try {
                    await fetchApi(`/api/locations/${id}`, "DELETE");
                    navigate("../", { replace: true });
                } catch (error: unknown) {
                    setErrorMessage((error as Error).message);
                }
            }
        },
        [navigate, id],
    );

    React.useEffect(() => {
        // location id is 'new' when creating a new location
        if (!id || Number.isNaN(parseInt(id, 10))) {
            return;
        }

        fetchApi<PartnerLocationWithRelations[]>(
            `/api/partner-locations/locations/${id}`,
        )
            .then((response) => setUsers(response))
            .catch((error: Error) => {
                setErrorMessage(error.message);
                logFetchError(
                    error,
                    `GET /api/partner-locations/locations/${id} failed`,
                );
            });
    }, [id, newUser]);

    const handleRemovePro = (pro: PartnerLocationWithRelations) => {
        if (!id) {
            return;
        }

        fetchApi(
            `/api/partner-locations/users/${pro.userId}/locations/${id}`,
            "DELETE",
        )
            .then(() =>
                setUsers((current) => {
                    const remainingPros = current.filter(
                        (currPro) => currPro.userId !== pro.userId,
                    );
                    return [...remainingPros];
                }),
            )
            .catch((error: Error) => {
                setErrorMessage(error.message);
                logFetchError(
                    error,
                    `DELETE /api/partner-locations/users/${pro.userId}/locations/${id} failed`,
                );
            });
    };

    const handleAddUser = (user: UserWithRelations) => {
        fetchApi("/api/partner-locations", "POST", {
            locationId: parseInt(id || "", 10),
            userId: user.id,
        })
            .then(() => setNewUser({ ...user }))
            .catch((error: Error) => {
                setErrorMessage(error.message);
                logFetchError(error, "POST /api/partner-locations failed");
            });
    };

    const labels = React.useMemo<{ postalCode: string; state: string }>(() => {
        switch (state.country) {
            case "CAN":
                return {
                    postalCode: "Postal Code",
                    state: "Province/Territory",
                };
            case "USA":
            default:
                return {
                    postalCode: "Zip Code",
                    state: "State",
                };
        }
    }, [state.country]);

    if (loading) {
        return <AdminLoadingPage page="Location" />;
    }

    const isNew = !state.id;

    return (
        <Stack spacing={3}>
            <Paper
                sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    "& > :not(style)": { m: 1 },
                }}
                component="form"
                autoComplete="off"
                onSubmit={onSubmit}
            >
                <Grid container>
                    {!!state.deletedAt && (
                        <Grid size={{ xs: 12 }}>
                            <Typography color="warning.main">
                                {`This location was deactivated by ${state.updatedBy} ` +
                                    `on ${usDateFormat(state.deletedAt)} and cannot be edited.`}
                            </Typography>
                        </Grid>
                    )}
                    <Grid size={{ xs: 10 }}>
                        <Typography
                            component="h1"
                            variant="h2"
                            title={`ID: ${state.id || "(New)"}`}
                        >
                            {state.id ? state.name : "New Location"}
                        </Typography>
                    </Grid>
                    <Grid size={{ xs: 2 }} sx={{ textAlign: "right" }}>
                        <IconButton size="large" component={RouterLink} to="..">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                {!!errorMessage && (
                    <Typography color="error.main">{errorMessage}</Typography>
                )}
                <TextField
                    id="name"
                    label="Name"
                    value={state.name}
                    onChange={(e) =>
                        dispatch({
                            type: "name",
                            value: e.currentTarget.value,
                        })
                    }
                />
                <TextField
                    id="hubspotCompanyId"
                    label="HubSpot Company ID"
                    value={state.hubspotCompanyId}
                    onChange={(e) =>
                        dispatch({
                            type: "hubspotCompanyId",
                            value: e.currentTarget.value,
                        })
                    }
                />
                <Grid
                    container
                    spacing={2}
                    sx={{ ".MuiGrid-item": { pl: 0, pr: 4 } }}
                >
                    <Grid size={{ xs: 12, md: 6 }}>
                        <TextField
                            label="Street address"
                            type="text"
                            autoComplete="address-line1"
                            name="address1"
                            id="address1"
                            value={state.address1}
                            onChange={(e) =>
                                dispatch({
                                    type: "address1",
                                    value: e.currentTarget.value,
                                })
                            }
                            error={!!errorState.address1}
                            helperText={errorState.address1}
                            fullWidth
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <TextField
                            label="Street address line 2"
                            type="text"
                            autoComplete="address-line2"
                            name="address2"
                            id="address2"
                            value={state.address2}
                            onChange={(e) =>
                                dispatch({
                                    type: "address2",
                                    value: e.currentTarget.value,
                                })
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <TextField
                            label="City"
                            type="text"
                            name="city"
                            id="city"
                            autoComplete="address-level2"
                            value={state.city}
                            onChange={(e) =>
                                dispatch({
                                    type: "city",
                                    value: e.currentTarget.value,
                                })
                            }
                            error={!!errorState.city}
                            helperText={errorState.city}
                            fullWidth
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 3 }}>
                        <FormControl fullWidth error={!!errorState.state}>
                            <InputLabel id="state-label">
                                {labels.state}
                            </InputLabel>
                            <Select
                                label={labels.state}
                                autoComplete="address-level1"
                                value={state.state || ""}
                                labelId="state-label"
                                id="state"
                                onChange={(e) =>
                                    dispatch({
                                        type: "state",
                                        value: e.target.value,
                                    })
                                }
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {STATES.filter(
                                    (s) => s.country === state.country,
                                ).map((s) => (
                                    <MenuItem
                                        value={s.abbreviation}
                                        key={s.name}
                                    >
                                        {s.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid size={{ xs: 12, md: 3 }}>
                        <TextField
                            label={labels.postalCode}
                            type="text"
                            name="postal-code"
                            id="postal-code"
                            autoComplete="postal-code"
                            value={state.postalCode}
                            onChange={(e) =>
                                dispatch({
                                    type: "postalCode",
                                    value: e.currentTarget.value,
                                })
                            }
                            error={!!errorState.postalCode}
                            helperText={errorState.postalCode}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <FormControl fullWidth error={!!errorState.state}>
                    <InputLabel id="country-label">Country</InputLabel>
                    <Select
                        label="Country"
                        autoComplete="country"
                        value={state.country}
                        labelId="country-label"
                        id="country"
                        onChange={(e) =>
                            dispatch({
                                type: "country",
                                value: e.target.value,
                            })
                        }
                    >
                        {COUNTRIES.map((s) => (
                            <MenuItem value={s.abbreviation} key={s.name}>
                                {s.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    id="notes"
                    label="Notes"
                    value={state.notes ?? ""}
                    onChange={(e) =>
                        dispatch({
                            type: "notes",
                            value: e.currentTarget.value,
                        })
                    }
                    multiline
                    maxRows={4}
                    fullWidth
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={state.isHomeLocation}
                            onChange={() =>
                                dispatch({
                                    type: "isHomeLocation",
                                    value: !state.isHomeLocation,
                                })
                            }
                            name="isHomeLocation"
                        />
                    }
                    label="Available as a home location?"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={state.leaseAccessAt !== null}
                            onChange={() =>
                                dispatch({
                                    type: "leaseAccessAt",
                                    value: state.leaseAccessAt
                                        ? null
                                        : new Date().toISOString(),
                                })
                            }
                            name="leaseAccessAt"
                        />
                    }
                    title={
                        state.leaseAccessAt
                            ? `Lease access added at ${state.leaseAccessAt}`
                            : undefined
                    }
                    label="Leasing Location?"
                />
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Stack spacing={2} direction="row">
                        <AdminSaveButton
                            type="submit"
                            variant="contained"
                            saveState={saveState}
                        />
                        <Button component={RouterLink} to="..">
                            Cancel
                        </Button>
                        {!isNew && !state.deletedAt && (
                            <Button
                                variant="contained"
                                color="warning"
                                onClick={onDeactivate}
                            >
                                Deactivate
                            </Button>
                        )}
                    </Stack>
                </Box>
            </Paper>
            {!isNew && (
                <>
                    <Paper
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Stack>
                            <Typography variant="h2">Trainers</Typography>
                            <Box
                                sx={{
                                    p: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <TrainersTable locationId={state.id} />
                            </Box>
                        </Stack>
                    </Paper>

                    <Paper
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography variant="h6">
                            Club Summary Feature Access. The following pros have
                            access to the club summary feature.
                        </Typography>
                        <Grid size={{ xs: 12, md: 5 }}>
                            {!users.length ? (
                                <Typography
                                    variant="body2"
                                    sx={{ padding: 1, mt: 1, mb: 1 }}
                                >
                                    (No pros are set to have access to this
                                    feature)
                                </Typography>
                            ) : (
                                <List dense>
                                    {users.map((p) => (
                                        <ListItem
                                            key={p.userId.toString()}
                                            secondaryAction={
                                                <ProRemoveDialog
                                                    handleSubmitParent={
                                                        handleRemovePro
                                                    }
                                                    locationName={state.name}
                                                    pro={p}
                                                />
                                            }
                                        >
                                            <ListItemAvatar>
                                                <PersonIcon />
                                            </ListItemAvatar>
                                            <ListItemText
                                                key={p.userId.toString()}
                                                primary={
                                                    <>
                                                        {p.user.firstName}
                                                        &nbsp;
                                                        {p.user.lastName}
                                                        <br />
                                                        {p.user.username}
                                                    </>
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            )}
                        </Grid>
                        <LocationProTable
                            handleSubmitParent={handleAddUser}
                            initialRowsPerPage={3}
                            locationName={state.name}
                            pros={users}
                        />
                    </Paper>

                    <Paper
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Stack>
                            <Typography variant="h2">Sessions</Typography>
                            <Box
                                sx={{
                                    p: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <SessionsTable
                                    locationId={state.id}
                                    initialRowsPerPage={10}
                                />
                            </Box>
                        </Stack>
                    </Paper>

                    <Paper
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <IssueReportTable locationId={state.id} />
                    </Paper>
                </>
            )}
        </Stack>
    );
}
