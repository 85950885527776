import * as React from "react";
import { Blurhash } from "react-blurhash";
import { Link as RouterLink } from "react-router";

import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import InputAdornment from "@mui/material/InputAdornment";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { formatDuration, intervalToDuration } from "date-fns";

import type { ClipWithWorkoutThrowJson } from "@volley/data";

import { usDateFormat } from "../../../util";
import useCursorPaginatedData from "../../hooks/useCursorPaginatedData";
import useDebounce from "../../hooks/useDebounce";

interface Props {
    entity: "all" | "session" | "trainer" | "user";
    id: number;
    title?: string;
    initialRowsPerPage?: number;
}

function mapDates(obj: unknown): ClipWithWorkoutThrowJson {
    const clip = obj as ClipWithWorkoutThrowJson;
    return {
        ...clip,
        startedAt: new Date(clip.startedAt),
        finishedAt: new Date(clip.finishedAt),
    };
}

function ThumbnailImageListItem({
    clip,
}: {
    clip: ClipWithWorkoutThrowJson;
}): React.JSX.Element {
    const [loading, setLoading] = React.useState(true);

    const users = clip.workoutThrow?.workoutPlay.session.sessionUsers
        .map(({ user: { username } }) => username)
        .join(", ");
    const clientIds = clip.workoutThrow?.workoutPlay.session.sessionTrainers
        .map((t) => `#${t.trainer.clientId}`)
        .join(", ");
    const location = clip.workoutThrow?.workoutPlay.session.location?.name;
    const barTitle =
        users && clientIds && location
            ? `${users} on ${clientIds} at ${location}`
            : "Raw Clip";
    const barSubtitle = `${usDateFormat(clip.startedAt)}, ${formatDuration(
        intervalToDuration({
            start: clip.startedAt,
            end: clip.finishedAt,
        }),
    )}`;
    const to = `/admin/clips/${clip.id}`;
    const thumbnail = clip.clipThumbnails[0];

    return (
        <ImageListItem
            component={RouterLink}
            to={to}
            sx={{ textDecoration: "none", color: "text.primary" }}
        >
            {thumbnail?.objectStoreFile.url ? (
                <img
                    src={thumbnail.objectStoreFile.url}
                    alt="clip thumbnail"
                    style={{ display: loading ? "none" : undefined }}
                    onLoad={() => setLoading(false)}
                />
            ) : (
                <Typography
                    sx={{ aspectRatio: "16 / 9", pt: 2 }}
                    variant="caption"
                    align="center"
                >
                    Preview unavailable
                </Typography>
            )}
            {loading && thumbnail && (
                <Blurhash
                    hash={thumbnail.blurHash}
                    width="auto"
                    height="100%"
                    style={{ aspectRatio: "16 / 9" }}
                />
            )}
            <ImageListItemBar title={barTitle} subtitle={barSubtitle} />
        </ImageListItem>
    );
}

export default function ClipsTable({
    entity,
    id,
    initialRowsPerPage,
    title = "Clips",
}: Props): React.JSX.Element {
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
    const [search, setSearch] = React.useState("");
    const debouncedSearch = useDebounce(search);

    const params = React.useMemo(() => {
        const stringId = id.toString();
        const base: Record<string, string> = {};
        if (debouncedSearch) {
            base.q = debouncedSearch;
        }
        if (entity === "session") {
            return { ...base, sessionId: stringId };
        }
        if (entity === "trainer") {
            return { ...base, trainerId: stringId };
        }
        if (entity === "user") {
            return { ...base, userId: stringId };
        }
        return base;
    }, [entity, id, debouncedSearch]);

    const {
        rowsPerPage,
        onRowsPerPageChange,
        count,
        page,
        onPageChange,
        data: clips,
    } = useCursorPaginatedData<ClipWithWorkoutThrowJson>("/api/clips", {
        id: "clips",
        params: params as Record<string, string>,
        mapFn: mapDates,
        initialRowsPerPage,
    });

    const onChangeSearch = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (page > 0) {
                onPageChange(null, 0);
            }
            setSearch(e.currentTarget.value);
        },
        [page, onPageChange],
    );

    const cols = isLgUp ? 4 : 1;

    return (
        <Grid container>
            <Grid size={12}>
                {title && (
                    <Typography variant="h2" gutterBottom>
                        {title}
                    </Typography>
                )}
            </Grid>
            <Grid size={12}>
                <TextField
                    type="search"
                    size="small"
                    label="Search by username"
                    value={search}
                    onChange={onChangeSearch}
                    slotProps={{
                        input: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        },
                    }}
                />
                <Box
                    component="div"
                    p={2}
                    display="flex"
                    flexDirection="column"
                >
                    <ImageList cols={cols}>
                        {clips.map((clip) => (
                            <ThumbnailImageListItem key={clip.id} clip={clip} />
                        ))}
                    </ImageList>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        rowsPerPage={rowsPerPage}
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                </Box>
            </Grid>
        </Grid>
    );
}
