import * as React from "react";
import { Link as RouterLink } from "react-router";

import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";

import {
    WorkoutThrowWithRelations,
    WorkoutThrowWithRelationsJson,
} from "@volley/data";

import { round, usDateFormat } from "../../../util";
import usePaginatedData from "../../hooks/usePaginatedData";

import Duration from "./Duration";

const TableCellWithRightBorder = styled(TableCell)(
    ({ theme }) => `
    border-right-color: ${theme.palette.grey["300"]};
    border-right-style: solid;
`,
);

function workoutThrowFromJson(
    workoutThrow: WorkoutThrowWithRelationsJson,
): WorkoutThrowWithRelations {
    return {
        ...workoutThrow,
        targetLaunchTime: new Date(workoutThrow.targetLaunchTime),
        actualLaunchTime: workoutThrow.actualLaunchTime
            ? new Date(workoutThrow.actualLaunchTime)
            : null,
    };
}

// https://stackoverflow.com/questions/49752151/typescript-keyof-returning-specific-type
type KeysOfType<T, TProp> = {
    [P in keyof T]: T[P] extends TProp ? P : never;
}[keyof T];
interface WorkoutThrowTargetActualSet {
    title: string;
    targetProperty: KeysOfType<WorkoutThrowWithRelations, number | Date | null>;
    actualProperty: KeysOfType<WorkoutThrowWithRelations, number | Date | null>;
    displayPrecision?: number;
}

const workoutThrowTargetActualSets: WorkoutThrowTargetActualSet[] = [
    {
        title: "Launch Time",
        actualProperty: "actualLaunchTime",
        targetProperty: "targetLaunchTime",
    },
    {
        title: "RPM Top",
        actualProperty: "actualRpmTop",
        targetProperty: "targetRpmTop",
    },
    {
        title: "RPM Left",
        actualProperty: "actualRpmLeft",
        targetProperty: "targetRpmLeft",
    },
    {
        title: "RPM Right",
        actualProperty: "actualRpmRight",
        targetProperty: "targetRpmRight",
    },
    {
        title: "Pan",
        actualProperty: "actualPan",
        targetProperty: "targetPan",
        displayPrecision: 1,
    },
    {
        title: "Tilt",
        actualProperty: "actualTilt",
        targetProperty: "targetTilt",
        displayPrecision: 1,
    },
    {
        title: "Height",
        actualProperty: "actualHeight",
        targetProperty: "targetHeight",
        displayPrecision: 1,
    },
];

interface WorkoutThrowTargetActualColumnSetProps {
    target: number | Date | null;
    actual: number | Date | null;
    displayPrecision: number;
}

function WorkoutThrowTargetActualNumberColumnSet(
    props: WorkoutThrowTargetActualColumnSetProps,
): React.JSX.Element {
    const target = props.target as number;

    const actual = props.actual as number;
    const { displayPrecision } = props;

    const diff: number | null =
        target !== null && actual !== null ? actual - target : null;

    return (
        <>
            <TableCell>{target}</TableCell>
            <TableCell>
                {actual !== null ? round(actual, displayPrecision) : "-"}
            </TableCell>
            <TableCellWithRightBorder>
                {diff !== null ? round(diff, displayPrecision) : "-"}
            </TableCellWithRightBorder>
        </>
    );
}

function WorkoutThrowTargetActualDateColumnSet({
    target,
    actual,
}: Omit<
    WorkoutThrowTargetActualColumnSetProps,
    "displayPrecision"
>): React.JSX.Element {
    return (
        <>
            <TableCell>{target ? usDateFormat(target) : "-"}</TableCell>
            <TableCell>{actual ? usDateFormat(actual) : "-"}</TableCell>
            <TableCellWithRightBorder>
                <Duration start={target as Date} end={actual as Date} />
            </TableCellWithRightBorder>
        </>
    );
}

function WorkoutThrowTargetActualColumnSet({
    target,
    actual,
    displayPrecision = 0,
}: WorkoutThrowTargetActualColumnSetProps): React.JSX.Element {
    if (target instanceof Date) {
        return (
            <WorkoutThrowTargetActualDateColumnSet
                target={target}
                actual={actual}
            />
        );
    }

    return (
        <WorkoutThrowTargetActualNumberColumnSet
            target={target}
            actual={actual}
            displayPrecision={displayPrecision}
        />
    );
}

interface WorkoutThrowsTableProps {
    workoutPlayId: number;
}

export default function WorkoutThrowsTable({
    workoutPlayId,
}: WorkoutThrowsTableProps): React.JSX.Element {
    const {
        rowsPerPage,
        onRowsPerPageChange,
        count,
        page,
        onPageChange,
        data: throws,
    } = usePaginatedData(`/api/workout-plays/${workoutPlayId}/throws`, {
        id: "throws",
        params: { sortField: "createdAt", sortDirection: "asc" },
        mapFn: workoutThrowFromJson,
    });

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCellWithRightBorder align="center" colSpan={1} />
                        <TableCellWithRightBorder align="center" colSpan={1}>
                            Speed
                        </TableCellWithRightBorder>
                        <TableCellWithRightBorder align="center" colSpan={1}>
                            Spin
                        </TableCellWithRightBorder>
                        <TableCellWithRightBorder align="center" colSpan={1}>
                            Spin Direction
                        </TableCellWithRightBorder>

                        {workoutThrowTargetActualSets.map(({ title }) => (
                            <TableCellWithRightBorder
                                align="center"
                                colSpan={3}
                                key={title}
                            >
                                {title}
                            </TableCellWithRightBorder>
                        ))}
                    </TableRow>
                    <TableRow>
                        <TableCellWithRightBorder />

                        <TableCellWithRightBorder>
                            Target
                        </TableCellWithRightBorder>

                        <TableCellWithRightBorder>
                            Target
                        </TableCellWithRightBorder>

                        <TableCellWithRightBorder>
                            Target
                        </TableCellWithRightBorder>

                        {workoutThrowTargetActualSets.map(({ title }) => (
                            <React.Fragment key={title}>
                                <TableCell>Target</TableCell>
                                <TableCell>Actual</TableCell>
                                <TableCellWithRightBorder>
                                    Delta
                                </TableCellWithRightBorder>
                            </React.Fragment>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {throws.map((t, i) => (
                        <TableRow key={t.id}>
                            <TableCellWithRightBorder>
                                {t.clipId ? (
                                    <Link
                                        color="info.main"
                                        component={RouterLink}
                                        to={`../../clips/${t.clipId}`}
                                        sx={{ fontWeight: 600 }}
                                    >
                                        {i + 1 + page * rowsPerPage}
                                    </Link>
                                ) : (
                                    `${i + 1 + page * rowsPerPage}`
                                )}
                            </TableCellWithRightBorder>

                            <TableCellWithRightBorder>
                                {t.targetSpeed}
                            </TableCellWithRightBorder>
                            <TableCellWithRightBorder>
                                {t.targetSpinRpm}
                            </TableCellWithRightBorder>
                            <TableCellWithRightBorder>
                                {t.targetSpinDirection}
                            </TableCellWithRightBorder>

                            {workoutThrowTargetActualSets.map(
                                ({
                                    title,
                                    targetProperty,
                                    actualProperty,
                                    displayPrecision,
                                }) => (
                                    <WorkoutThrowTargetActualColumnSet
                                        key={title}
                                        target={t[targetProperty]}
                                        actual={t[actualProperty]}
                                        displayPrecision={displayPrecision ?? 0}
                                    />
                                ),
                            )}
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPage={rowsPerPage}
                            count={count}
                            page={page}
                            onPageChange={onPageChange}
                            onRowsPerPageChange={onRowsPerPageChange}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}
