import * as React from "react";
import { Link as RouterLink } from "react-router";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { TrainerFailure } from "@volley/data";

import { usDateFormat } from "../../../util";
import usePaginatedData from "../../hooks/usePaginatedData";
import TrainerSoftwareVersionDisplay from "../Trainers/TrainerSoftwareVersionDisplay";

interface Props {
    entity: "session" | "trainer";
    id: number;
}

export default function FailuresTable({
    entity,
    id,
}: Props): React.JSX.Element {
    const url = React.useMemo(() => {
        if (entity === "session") {
            return `/api/sessions/${id}/failures`;
        }
        return `/api/trainers/${id}/failures`;
    }, [entity, id]);

    const {
        rowsPerPage,
        onRowsPerPageChange,
        count,
        page,
        onPageChange,
        data: failures,
    } = usePaginatedData<TrainerFailure>(url, { id: "failures" });

    return (
        <Grid container>
            <Grid size={12}>
                <Typography variant="h2">Failures</Typography>
            </Grid>
            <Grid size={12}>
                <Box sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Version</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Source</TableCell>
                                    <TableCell>Message</TableCell>
                                    <TableCell>Created By</TableCell>
                                    <TableCell>Timestamp</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {failures.map((f) => (
                                    <TableRow key={f.id}>
                                        <TableCell>
                                            <Button
                                                component={RouterLink}
                                                to={`../../failures/${f.id}`}
                                            >
                                                View
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            <TrainerSoftwareVersionDisplay
                                                label={f.softwareVersion}
                                                truncate
                                            />
                                        </TableCell>
                                        <TableCell>{f.type}</TableCell>
                                        <TableCell>{f.source}</TableCell>
                                        <TableCell>
                                            <Tooltip
                                                title={JSON.stringify(
                                                    f.context,
                                                    undefined,
                                                    4,
                                                )}
                                            >
                                                <Box>{f.message}</Box>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>{f.createdBy}</TableCell>
                                        <TableCell title={String(f.timestamp)}>
                                            {usDateFormat(
                                                new Date(f.timestamp),
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        colSpan={7}
                                        rowsPerPage={rowsPerPage}
                                        count={count}
                                        page={page}
                                        onPageChange={onPageChange}
                                        onRowsPerPageChange={
                                            onRowsPerPageChange
                                        }
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Box>
            </Grid>
        </Grid>
    );
}
