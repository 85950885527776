import * as React from "react";
import { Link as RouterLink } from "react-router";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import type {
    AdminPendingConfirmation,
    CampaignClubWithMemberCount,
} from "@volley/data";

import { fetchApi, usDateFormat } from "../../../util";
import { logFetchError } from "../../../util/fetchApi";
import usePaginatedData from "../../hooks/usePaginatedData";

export default function CampaignClubsTable(): React.JSX.Element {
    const [includeDeleted, setIncludeDeleted] = React.useState(false);
    const {
        rowsPerPage,
        onRowsPerPageChange,
        count,
        page,
        onPageChange,
        data: campaignClubs,
    } = usePaginatedData<CampaignClubWithMemberCount>(
        "/api/campaign-clubs/admin/clubs",
        {
            id: "campaign-clubs",
            params: includeDeleted ? { includeDeleted: "true" } : undefined,
        },
    );

    const [pendingConfirmations, setPendingConfirmations] = React.useState<
        AdminPendingConfirmation[]
    >([]);
    React.useEffect(() => {
        fetchApi<AdminPendingConfirmation[]>(
            "/api/campaign-clubs/admin/pending-confirmations",
        )
            .then((data) => setPendingConfirmations(data))
            .catch((e) => logFetchError(e));
        return () => setPendingConfirmations([]);
    }, []);

    const onCopyLink = async (link: string) => {
        await window.navigator.clipboard.writeText(link);
    };

    return (
        <Stack spacing={2}>
            <Typography component="h1" variant="h2">
                Campaign Clubs
            </Typography>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={includeDeleted}
                                onChange={() =>
                                    setIncludeDeleted(!includeDeleted)
                                }
                            />
                        }
                        label="Show Hidden?"
                    />
                </div>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>City</TableCell>
                                <TableCell>State</TableCell>
                                <TableCell>Members</TableCell>
                                <TableCell>Created</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {campaignClubs.map((cc) => (
                                <TableRow key={cc.id}>
                                    <TableCell>
                                        <Link
                                            component={RouterLink}
                                            to={cc.id.toString()}
                                            color={
                                                cc.deletedAt
                                                    ? "warning"
                                                    : undefined
                                            }
                                            title={
                                                cc.deletedAt
                                                    ? `Deleted on ${usDateFormat(new Date(cc.deletedAt))}`
                                                    : undefined
                                            }
                                        >
                                            {cc.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{cc.city}</TableCell>
                                    <TableCell>{cc.state}</TableCell>
                                    <TableCell>
                                        {cc._count.campaignClubMembers}
                                    </TableCell>
                                    <TableCell>
                                        {usDateFormat(new Date(cc.createdAt))}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={5}
                                    rowsPerPage={rowsPerPage}
                                    count={count}
                                    page={page}
                                    onPageChange={onPageChange}
                                    onRowsPerPageChange={onRowsPerPageChange}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Typography component="h2" variant="h3">
                    Pending Members
                </Typography>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Club</TableCell>
                                <TableCell>Confirmation Link</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pendingConfirmations.map((pc) => (
                                <TableRow key={pc.confirmationUrl}>
                                    <TableCell>
                                        {`${pc.member.firstName} ${pc.member.lastName}`}
                                    </TableCell>
                                    <TableCell>
                                        <Link
                                            href={`mailto:${pc.member.email}`}
                                        >
                                            {pc.member.email}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        {`${pc.club.name} (${pc.club.city}, ${pc.club.state})`}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() =>
                                                onCopyLink(pc.confirmationUrl)
                                            }
                                            title="Copy confirmation link to clipboard"
                                        >
                                            <ContentCopyIcon />
                                        </IconButton>
                                        <Typography variant="caption">
                                            {pc.confirmationUrl}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Stack>
    );
}
