import * as React from "react";
import { Link as RouterLink } from "react-router";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import TrainersTable from "./TrainersTable";

export default function TrainersPage(): React.JSX.Element {
    const [exportError, setExportError] = React.useState(false);

    return (
        <Stack spacing={2}>
            <Typography component="h1" variant="h2">
                Trainers
            </Typography>
            <Paper sx={{ p: 2 }}>
                <Stack spacing={2} direction="row">
                    <Button variant="contained" component={RouterLink} to="new">
                        New Trainer
                    </Button>
                    <Button
                        variant="contained"
                        href="/api/reports/trainers"
                        download
                    >
                        Export Report
                    </Button>
                    <Snackbar
                        autoHideDuration={5000}
                        open={exportError}
                        onClose={() => setExportError(false)}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                    >
                        <Alert severity="error">
                            There was an error exporting your report.
                        </Alert>
                    </Snackbar>
                </Stack>
                <TrainersTable />
            </Paper>
        </Stack>
    );
}
