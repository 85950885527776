import * as React from "react";
import { Link as RouterLink, Navigate, useParams } from "react-router";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import type { TrainerWithRelations } from "@volley/data";

import fetchApi, { logFetchError } from "../../../util/fetchApi";
import AdminLoading from "../AdminLoading";

export default function TrainerClientIdRedirect(): React.JSX.Element {
    const { clientId } = useParams<"clientId">();
    const [trainerId, setTrainerId] = React.useState<number | null>(null);
    const [errorMessage, setErrorMessage] = React.useState("");

    React.useEffect(() => {
        if (!clientId) {
            return;
        }

        fetchApi<TrainerWithRelations>(`/api/trainers/clientId_${clientId}`)
            .then((trainer) => {
                setTrainerId(trainer.id);
            })
            .catch((err: Error) => {
                logFetchError(err);
                setErrorMessage(err.message);
            });
    }, [clientId]);

    if (!trainerId) {
        return <AdminLoading />;
    }

    if (errorMessage) {
        return (
            <Typography color="error.main" gutterBottom>
                {errorMessage}{" "}
                <Link component={RouterLink} to={-1 as unknown as string}>
                    Go back
                </Link>
            </Typography>
        );
    }

    return <Navigate to={`/admin/trainers/${trainerId}`} replace />;
}
