import * as React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

import type { OffsetResult, LocationWithRelations } from "@volley/data";

import { fetchApi } from "../../util";
import { logFetchError } from "../../util/fetchApi";
import useDebounce from "../hooks/useDebounce";

type Props =
    | {
          multiple: true;
          value: number[];
          onChange: (locations: LocationWithRelations[]) => void;
          error?: string;
          fullWidth?: boolean;
      }
    | {
          multiple?: false;
          value: number | null;
          onChange: (location: LocationWithRelations | null) => void;
          error?: string;
          fullWidth?: boolean;
      };

function getOptionLabel(location: LocationWithRelations): string {
    if (!location.mailingAddress) {
        return location.name;
    }
    return `${location.name} (${location.mailingAddress.city}, ${location.mailingAddress.state})`;
}

export default function LocationAutocomplete({
    multiple,
    value,
    onChange,
    error,
    fullWidth,
}: Props): React.JSX.Element {
    const [inputValue, setInputValue] = React.useState("");
    const debouncedInputValue = useDebounce(inputValue, 400);
    const [loading, setLoading] = React.useState(false);
    const [locations, setLocations] = React.useState<
        readonly LocationWithRelations[]
    >([]);
    const [valueLocations, setValueLocations] = React.useState<
        LocationWithRelations[]
    >([]);
    const hasLoadedValueLocations = React.useRef(false);

    React.useEffect(() => {
        async function fetchLocations() {
            setLoading(true);
            try {
                const res = await fetchApi<OffsetResult<LocationWithRelations>>(
                    `/api/locations?limit=10&offset=0&q=${debouncedInputValue.trim()}`,
                );
                setLocations(res.result);
            } finally {
                setLoading(false);
            }
        }

        fetchLocations().catch((err: Error) => logFetchError(err));
    }, [debouncedInputValue]);

    React.useEffect(() => {
        async function fetchValues() {
            setLoading(true);
            try {
                if (
                    value === null ||
                    (typeof value !== "number" && !value.length)
                )
                    return;
                const toFetchIds = typeof value === "number" ? [value] : value;
                const fetchedValues = await Promise.all(
                    toFetchIds.map((id) =>
                        fetchApi<LocationWithRelations>(`/api/locations/${id}`),
                    ),
                );
                setValueLocations(fetchedValues);
                hasLoadedValueLocations.current = true;
            } finally {
                setLoading(false);
            }
        }

        if (!hasLoadedValueLocations.current) {
            fetchValues().catch((err: Error) => logFetchError(err));
        }
    }, [value]);

    return (
        <Autocomplete
            id="locationId"
            fullWidth={fullWidth}
            loading={loading}
            autoComplete
            options={[...valueLocations, ...locations]}
            filterSelectedOptions
            getOptionLabel={getOptionLabel}
            filterOptions={(v) => v}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            noOptionsText="Begin typing to search"
            value={multiple ? valueLocations : (valueLocations.at(0) ?? null)}
            multiple={multiple}
            onChange={(_event, newValue) => {
                if (multiple) {
                    onChange(newValue as LocationWithRelations[]);
                    setValueLocations(newValue as LocationWithRelations[]);
                } else {
                    onChange(newValue as LocationWithRelations | null);
                    setValueLocations(
                        newValue ? [newValue as LocationWithRelations] : [],
                    );
                }
            }}
            onInputChange={(_, v) => setInputValue(v)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Location"
                    error={!!error}
                    helperText={error}
                    slotProps={{
                        input: {
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        },
                    }}
                />
            )}
        />
    );
}
