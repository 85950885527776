import * as React from "react";
import { Link as RouterLink } from "react-router";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type { ImageWithRelationsJson } from "@volley/data";

import { usDateFormat } from "../../../util";

interface Props {
    image: ImageWithRelationsJson;
    onNextClick?: () => void;
    onPreviousClick?: () => void;
    onClose: () => void;
}

export default function SnapshotView({
    image,
    onNextClick,
    onPreviousClick,
    onClose,
}: Props): React.JSX.Element {
    const { sessionId, trainerId } = image;
    return (
        <Stack spacing={2} p={2}>
            <Grid container>
                <Grid size={10}>
                    <Typography component="h1" variant="h3">
                        Snapshot
                    </Typography>
                </Grid>
                <Grid sx={{ textAlign: "right" }} size={2}>
                    <IconButton size="large" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <img
                style={{ maxWidth: "100%", height: "auto" }}
                src={image.objectStoreFile.url!}
                alt="snapshot"
            />
            <Stack direction="row" justifyContent="space-between">
                <IconButton
                    onClick={onPreviousClick}
                    disabled={!onPreviousClick}
                >
                    <ArrowLeftIcon />
                </IconButton>
                <IconButton onClick={onNextClick} disabled={!onNextClick}>
                    <ArrowRightIcon />
                </IconButton>
            </Stack>
            {trainerId && (
                <Grid container component="dl" spacing={2}>
                    <Grid
                        size={{
                            xs: 12,
                            md: 4,
                        }}
                    >
                        <Typography component="dt" variant="h6">
                            Trainer
                        </Typography>
                        <Typography component="dd" variant="body2">
                            <Link
                                component={RouterLink}
                                to={`/admin/trainers/${trainerId}`}
                            >
                                {`#${image.trainer.clientId}`}
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid
                        size={{
                            xs: 12,
                            md: 4,
                        }}
                    >
                        <Typography component="dt" variant="h6">
                            Time
                        </Typography>
                        <Typography component="dd" variant="body2">
                            {usDateFormat(new Date(image.timestamp))}
                        </Typography>
                    </Grid>
                    {sessionId && (
                        <Grid
                            size={{
                                xs: 12,
                                md: 4,
                            }}
                        >
                            <Typography component="dt" variant="h6">
                                Session
                            </Typography>
                            <Typography component="dd" variant="body2">
                                <Link
                                    component={RouterLink}
                                    to={`/admin/sessions/${sessionId}`}
                                    title={sessionId.toString()}
                                >
                                    View
                                </Link>
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            )}
        </Stack>
    );
}
