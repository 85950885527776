import * as React from "react";
import { Link as RouterLink } from "react-router";

import RefreshIcon from "@mui/icons-material/Refresh";
import StreamIcon from "@mui/icons-material/Stream";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ToggleButton from "@mui/material/ToggleButton";
import Typography from "@mui/material/Typography";

import type {
    SessionWithDeepRelations,
    SessionWithDeepRelationsJson,
} from "@volley/data";

import { usDateFormat, fetchApi } from "../../util";
import ConfirmationDialog from "../common/ConfirmationDialog";
import JoinedList from "../common/JoinedList";
import { useCurrentUser } from "../hooks/currentUser";

import ClipsTable from "./Clips/ClipsTable";
import SnapshotsGrid from "./Snapshots/SnapshotsGrid";
import TrainerSearch from "./Trainers/TrainerSearch";

export function sessionFromJson(
    session: SessionWithDeepRelationsJson,
): SessionWithDeepRelations {
    return {
        ...session,
        startTime: new Date(session.startTime),
        endTime: session.endTime ? new Date(session.endTime) : null,
        createdAt: new Date(session.createdAt),
    };
}

export default function AdminDashboard(): React.JSX.Element {
    const { currentUser } = useCurrentUser();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [sessionToEnd, setSessionToEnd] =
        React.useState<SessionWithDeepRelations | null>(null);
    const [activeSessions, setActiveSessions] = React.useState<
        SessionWithDeepRelations[]
    >([]);
    const [onlyMyClips, setOnlyMyClips] = React.useState(false);

    const fetchActiveSessions = React.useCallback(async () => {
        const data = await fetchApi<SessionWithDeepRelationsJson[]>(
            "/api/sessions/active",
        );
        setActiveSessions(data.map((d) => sessionFromJson(d)));
    }, []);

    React.useEffect(() => {
        void fetchActiveSessions();
    }, [fetchActiveSessions]);

    const onClickEnd = React.useCallback(
        (
            e: React.MouseEvent<HTMLButtonElement>,
            session: SessionWithDeepRelations,
        ) => {
            e.preventDefault();
            setErrorMessage("");
            setSessionToEnd(session);
        },
        [],
    );

    const handleDialogResult = React.useCallback(
        async (result: boolean) => {
            if (result && sessionToEnd) {
                try {
                    await fetchApi(
                        `/api/sessions/${sessionToEnd.id}/close`,
                        "PUT",
                    );
                    await fetchActiveSessions();
                } catch (err: unknown) {
                    setErrorMessage(
                        (err as Error).message ??
                            "Unknown error closing session",
                    );
                }
            }
            setSessionToEnd(null);
        },
        [sessionToEnd, fetchActiveSessions],
    );

    return (
        <Stack spacing={4}>
            <Box>
                <Typography component="h1" variant="h2">
                    Dashboard
                </Typography>
                {errorMessage && (
                    <Typography color="error.main">{errorMessage}</Typography>
                )}
            </Box>
            <Paper sx={{ p: 2 }}>
                <TrainerSearch />
            </Paper>
            <Box>
                <Box
                    component="div"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    my={1}
                >
                    <Typography component="h2" variant="h3">
                        Active Sessions
                    </Typography>
                    <IconButton
                        size="large"
                        onClick={() => fetchActiveSessions()}
                        title="Refresh"
                    >
                        <RefreshIcon />
                    </IconButton>
                </Box>
                <Paper sx={{ p: 2 }}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Actions</TableCell>
                                    <TableCell>Users</TableCell>
                                    <TableCell>Location</TableCell>
                                    <TableCell>Trainers</TableCell>
                                    <TableCell>Start</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {activeSessions.map((s) => (
                                    <TableRow key={s.id}>
                                        <TableCell>
                                            <Button
                                                color="info"
                                                component={RouterLink}
                                                to={`sessions/${s.id.toString()}`}
                                                title="View this session"
                                            >
                                                View
                                            </Button>
                                            <Button
                                                color="error"
                                                onClick={(e) =>
                                                    onClickEnd(e, s)
                                                }
                                                title="End this session"
                                            >
                                                End
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            <JoinedList>
                                                {s.sessionUsers.map((su) => (
                                                    <Link
                                                        component={RouterLink}
                                                        to={`users/${su.userId}`}
                                                        title={su.user.email}
                                                        key={su.userId}
                                                    >
                                                        {`${su.user.firstName} ${su.user.lastName}`}
                                                    </Link>
                                                ))}
                                            </JoinedList>
                                        </TableCell>
                                        <TableCell>
                                            <Link
                                                component={RouterLink}
                                                to={`locations/${s.location?.id ?? ""}`}
                                                title={s.location?.name ?? ""}
                                            >
                                                {s.location?.name ?? ""}
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            {s.sessionTrainers.map((st) => (
                                                <Button
                                                    component={RouterLink}
                                                    to={`trainer-event-streams/${st.trainer.clientId}`}
                                                    color="info"
                                                    title="View trainer event stream"
                                                    startIcon={<StreamIcon />}
                                                    key={st.trainerId}
                                                >
                                                    {`#${st.trainer.clientId} Live`}
                                                </Button>
                                            ))}
                                        </TableCell>
                                        <TableCell>
                                            {usDateFormat(s.startTime)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {sessionToEnd && (
                        <ConfirmationDialog
                            open
                            onClose={handleDialogResult}
                            title={`End session for user ${sessionToEnd.sessionUsers[0]?.user.email ?? ""}?`}
                        >
                            This will end the session for{" "}
                            {sessionToEnd.sessionUsers[0]?.user.firstName}{" "}
                            {sessionToEnd.sessionUsers[0]?.user.lastName}
                            {" started on trainer #"}
                            {sessionToEnd.sessionTrainers[0]?.trainer.clientId}
                            {" at "}
                            {usDateFormat(sessionToEnd.startTime)}. If this user
                            is currently playing, they will have to re-pair.
                        </ConfirmationDialog>
                    )}
                </Paper>
            </Box>
            <Box>
                <Typography component="h2" variant="h3" mb={2}>
                    Recent Images
                </Typography>
                <Paper sx={{ p: 2 }}>
                    <SnapshotsGrid />
                </Paper>
            </Box>
            <Box>
                <Box
                    component="div"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    my={1}
                >
                    <Typography component="h2" variant="h3">
                        Recent Clips
                    </Typography>
                    <ToggleButton
                        value="only-my-clips"
                        onChange={() => setOnlyMyClips(!onlyMyClips)}
                        selected={onlyMyClips}
                        size="small"
                    >
                        Only my clips
                    </ToggleButton>
                </Box>
                <Paper sx={{ p: 2 }}>
                    <ClipsTable
                        entity={onlyMyClips ? "user" : "all"}
                        id={onlyMyClips ? (currentUser?.id ?? 0) : 0}
                        title=""
                        initialRowsPerPage={12}
                    />
                </Paper>
            </Box>
        </Stack>
    );
}
