import * as React from "react";

import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";

import type { SoftwareTagWithRelations } from "@volley/data";

import * as buildLabel from "../../../util/buildLabel";
import fetchApi, { logFetchError } from "../../../util/fetchApi";
import { SaveState } from "../AdminSaveButton";

import TrainerSoftwareVersionDisplay from "./TrainerSoftwareVersionDisplay";
import { TrainerForm } from "./types";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface Props {
    form: TrainerForm;
    disabled?: boolean;
    setControlTagIds: (ids: number[]) => void;
    setActiveControlTagId: (id: number | null) => void;
    setActiveVisionTagId: (id: number | null) => void;
}

export default function TrainerSoftwareEdit({
    form,
    disabled = false,
    setControlTagIds,
    setActiveControlTagId,
    setActiveVisionTagId,
}: Props): React.JSX.Element {
    const [controlTags, setControlTags] = React.useState<
        SoftwareTagWithRelations[]
    >([]);
    const [visionTags, setVisionTags] = React.useState<
        SoftwareTagWithRelations[]
    >([]);
    const controlPlatformId = form.controlPlatformId.value;
    const visionPlatformId = form.visionPlatformId.value;

    React.useEffect(() => {
        async function fetchSoftwareTags() {
            const [controlTagsData, visionTagsData] = await Promise.all([
                fetchApi<SoftwareTagWithRelations[]>(
                    `/api/trainers/software-tags?platformId=${controlPlatformId}`,
                ),
                fetchApi<SoftwareTagWithRelations[]>(
                    `/api/trainers/software-tags?platformId=${visionPlatformId}`,
                ),
            ]);
            setControlTags(controlTagsData);
            if (!controlTagsData.length) setActiveControlTagId(null);

            setVisionTags(visionTagsData);
            if (!visionPlatformId.length) setActiveVisionTagId(null);
        }

        if (controlPlatformId && visionPlatformId) {
            fetchSoftwareTags().catch((e) => logFetchError(e));
        }
    }, [
        controlPlatformId,
        visionPlatformId,
        setActiveControlTagId,
        setActiveVisionTagId,
    ]);

    const activeControlTagId = React.useMemo(() => {
        if (
            form.controlTagIds.value.indexOf(
                form.activeControlTagId.value ?? -1,
            ) > -1
        ) {
            return form.activeControlTagId.value;
        }

        return null;
    }, [form.controlTagIds.value, form.activeControlTagId.value]);

    const showErrors = form.saveState === SaveState.Error;

    return (
        <>
            <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                <FormControl
                    fullWidth
                    error={showErrors && !!form.controlTagIds.error}
                    disabled={disabled}
                >
                    <InputLabel id="controlTagIdsLabel">
                        Software Tags
                    </InputLabel>
                    <Select
                        labelId="controlTagIdsLabel"
                        id="controlTagIds"
                        multiple
                        value={
                            controlTags.length
                                ? form.controlTagIds.value || ""
                                : ""
                        }
                        label="Software Tags"
                        MenuProps={MenuProps}
                        renderValue={(selected: number[]) =>
                            controlTags
                                .filter((t) => selected.indexOf(t.id) > -1)
                                .map((t) => t.name)
                                .join(", ")
                        }
                        onChange={({ target: { value } }) => {
                            const nextControlTags =
                                typeof value === "string"
                                    ? value
                                          .split(",")
                                          .map((t) => parseInt(t, 10))
                                    : value;
                            setControlTagIds(nextControlTags);
                            setActiveControlTagId(
                                nextControlTags.indexOf(
                                    activeControlTagId ?? -1,
                                ) > -1
                                    ? activeControlTagId
                                    : (nextControlTags[0] ?? null),
                            );
                        }}
                    >
                        {controlTags.map((t) => (
                            <MenuItem key={t.id} value={t.id}>
                                <Checkbox
                                    checked={
                                        form.controlTagIds.value.indexOf(t.id) >
                                        -1
                                    }
                                />
                                <ListItemText
                                    primary={
                                        <>
                                            {`${t.name} -> `}
                                            <TrainerSoftwareVersionDisplay
                                                label={buildLabel.expand(
                                                    t.softwareBuild.name,
                                                )}
                                                truncate
                                            />
                                        </>
                                    }
                                />
                            </MenuItem>
                        ))}
                    </Select>
                    {showErrors && !!form.controlTagIds.error && (
                        <FormHelperText>
                            {form.controlTagIds.error}
                        </FormHelperText>
                    )}
                </FormControl>
                <FormControl
                    fullWidth
                    error={showErrors && !!form.activeControlTagId.error}
                    disabled={disabled}
                >
                    <InputLabel id="activeControlTagIdLabel">
                        Active Software Tag
                    </InputLabel>
                    <Select
                        labelId="activeControlTagIdLabel"
                        id="activeTagId"
                        value={
                            activeControlTagId && controlTags.length
                                ? activeControlTagId || ""
                                : ""
                        }
                        label="Active Software Tag"
                        onChange={({ target: { value } }) =>
                            setActiveControlTagId(
                                typeof value === "string"
                                    ? parseInt(value, 10)
                                    : value,
                            )
                        }
                    >
                        {controlTags
                            .filter((t) =>
                                new Set(form.controlTagIds.value).has(t.id),
                            )
                            .map((t) => (
                                <MenuItem key={t.id} value={t.id}>
                                    <ListItemText
                                        primary={
                                            <>
                                                {`${t.name} -> `}
                                                <TrainerSoftwareVersionDisplay
                                                    label={buildLabel.expand(
                                                        t.softwareBuild.name,
                                                    )}
                                                    truncate
                                                />
                                            </>
                                        }
                                    />
                                </MenuItem>
                            ))}
                    </Select>
                    {showErrors && !!form.activeControlTagId.error && (
                        <FormHelperText>
                            {form.activeControlTagId.error}
                        </FormHelperText>
                    )}
                </FormControl>
            </Stack>
            <Stack>
                <FormControl
                    fullWidth
                    error={showErrors && !!form.activeVisionTagId.error}
                    disabled={disabled}
                >
                    <InputLabel id="activeVisionTagIdLabel">
                        Vision Software Tag
                    </InputLabel>
                    <Select
                        labelId="activeVisionTagIdLabel"
                        id="activeVisionTagId"
                        value={
                            form.activeVisionTagId.value && visionTags.length
                                ? form.activeVisionTagId.value || ""
                                : ""
                        }
                        label="Vision Software Tag"
                        onChange={({ target: { value } }) =>
                            setActiveVisionTagId(
                                typeof value === "string"
                                    ? parseInt(value, 10)
                                    : value,
                            )
                        }
                    >
                        {visionTags.map((t) => (
                            <MenuItem key={t.id} value={t.id}>
                                <ListItemText
                                    primary={
                                        <>
                                            {`${t.name} -> `}
                                            {t.softwareBuild.name.length ===
                                            36 ? (
                                                <TrainerSoftwareVersionDisplay
                                                    label={buildLabel.expand(
                                                        t.softwareBuild.name,
                                                    )}
                                                    truncate
                                                />
                                            ) : (
                                                t.softwareBuild.name
                                            )}
                                        </>
                                    }
                                />
                            </MenuItem>
                        ))}
                    </Select>
                    {showErrors && !!form.activeVisionTagId.error && (
                        <FormHelperText>
                            {form.activeVisionTagId.error}
                        </FormHelperText>
                    )}
                </FormControl>
            </Stack>
        </>
    );
}
