import type {
    SessionWithRelations,
    SessionWithRelationsJson,
} from "@volley/data";

export function sessionFromJson(
    session: SessionWithRelationsJson,
): SessionWithRelations {
    return {
        ...session,
        startTime: new Date(session.startTime),
        endTime: session.endTime ? new Date(session.endTime) : null,
        createdAt: new Date(session.createdAt),
    };
}
