import * as React from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router";

import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import {
    Feature,
    FeatureCreatePayload,
    FeatureWithRelations,
} from "@volley/data";

import fetchApi from "../../../util/fetchApi";
import AdminSaveButton, { SaveState } from "../AdminSaveButton";

type FormFeature = Feature & { featureRoles: string[] };

type Action =
    | { type: "name"; value: string }
    | { type: "description"; value: string }
    | { type: "featureRoles"; value: string[] }
    | { type: "set"; value: FormFeature };

function reducer(state: FormFeature, action: Action): FormFeature {
    switch (action.type) {
        case "name":
            return { ...state, name: action.value };
        case "description":
            return { ...state, description: action.value };
        case "featureRoles":
            return { ...state, featureRoles: action.value };
        case "set":
            return { ...action.value };
        default:
            throw new Error("Invalid form action type");
    }
}

const defaultState: FormFeature = {
    name: "",
    description: "",
    id: 0,
    featureRoles: [],
    createdAt: new Date(),
    createdBy: "",
    updatedAt: new Date(),
    updatedBy: "",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function FeatureEdit(): React.JSX.Element {
    const { id } = useParams<"id">();
    const validId = parseInt(id ?? "", 10);
    const navigate = useNavigate();
    const [roleList, setRoleList] = React.useState<string[]>([]);
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const [saveState, setSaveState] = React.useState(SaveState.Unsaved);
    const [errorMessage, setErrorMessage] = React.useState("");

    async function fetchFeature(userId: number) {
        const data = await fetchApi<FeatureWithRelations>(
            `/api/features/${userId}`,
        );
        const value = {
            ...data,
            featureRoles: data.featureRoles.map((fr) => fr.role.name),
        };
        dispatch({ type: "set", value });
    }

    async function fetchRoles() {
        const roles = await fetchApi<string[]>("/api/users/roles");
        setRoleList(roles);
    }

    React.useEffect(() => {
        void fetchRoles();
    }, []);

    React.useEffect(() => {
        setErrorMessage("");
        if (validId) {
            void fetchFeature(validId);
        }
    }, [validId]);

    const onSubmit = React.useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            setSaveState(SaveState.Saving);
            setErrorMessage("");
            const payload: FeatureCreatePayload = {
                name: state.name,
                description: state.description,
                featureRoles: state.featureRoles.map((r: string) => ({
                    role: { name: r },
                })),
            };

            try {
                let result: FeatureWithRelations;
                if (state.id) {
                    result = await fetchApi(
                        `/api/features/${state.id}`,
                        "PUT",
                        { ...payload, id: state.id },
                    );
                } else {
                    result = await fetchApi("/api/features", "POST", payload);
                }
                setSaveState(SaveState.Saved);
                navigate(`../${result.id.toString()}`, { replace: true });
            } catch (err: unknown) {
                const error = err as Error;
                setErrorMessage(error.message);
                setSaveState(SaveState.Unsaved);
            }
        },
        [state, navigate],
    );

    const onDelete = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            const sure = window.confirm(
                `Are you sure you want to delete the app feature: '${state.name}'?`,
            );
            if (sure) {
                try {
                    await fetchApi(`/api/features/${state.id}`, "DELETE");
                    navigate("../", { replace: true });
                } catch (err: unknown) {
                    const error = err as Error;
                    setErrorMessage(error.message);
                }
            }
        },
        [state, navigate],
    );

    const isNew = !state.id;
    return (
        <Grid container spacing={3}>
            <Grid size={12}>
                <Paper
                    sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        "& > :not(style)": { m: 1 },
                    }}
                    component="form"
                    autoComplete="off"
                    onSubmit={onSubmit}
                >
                    <Grid container>
                        <Grid size={10}>
                            <Typography
                                component="h1"
                                variant="h2"
                                title={`ID: ${state.id || "(New)"}`}
                            >
                                {state.id ? `${state.name}` : "New App Feature"}
                            </Typography>
                        </Grid>
                        <Grid sx={{ textAlign: "right" }} size={2}>
                            <IconButton
                                size="large"
                                component={RouterLink}
                                to=".."
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {errorMessage && (
                        <Typography color="error.main">
                            {errorMessage}
                        </Typography>
                    )}
                    <TextField
                        id="name"
                        label="Name"
                        value={state.name}
                        onChange={(e) =>
                            dispatch({
                                type: "name",
                                value: e.currentTarget.value,
                            })
                        }
                    />
                    <TextField
                        id="description"
                        label="Description"
                        value={state.description}
                        onChange={(e) =>
                            dispatch({
                                type: "description",
                                value: e.currentTarget.value,
                            })
                        }
                    />
                    <FormControl>
                        <InputLabel id="featureRolesLabel">Roles</InputLabel>
                        <Select
                            labelId="featureRolesLabel"
                            id="featureRoles"
                            multiple
                            value={state.featureRoles}
                            label="Roles"
                            MenuProps={MenuProps}
                            onChange={({ target: { value } }) => {
                                const roles =
                                    typeof value === "string"
                                        ? value.split(",")
                                        : value;
                                dispatch({
                                    type: "featureRoles",
                                    value: roles,
                                });
                            }}
                        >
                            {roleList.map((r: string) => (
                                <MenuItem key={r} value={r}>
                                    {r}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Stack spacing={2} direction="row">
                            <AdminSaveButton
                                type="submit"
                                variant="contained"
                                saveState={saveState}
                            />
                            <Button component={RouterLink} to="..">
                                Cancel
                            </Button>
                            {!isNew && (
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={onDelete}
                                >
                                    Delete
                                </Button>
                            )}
                        </Stack>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
}
