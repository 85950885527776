import * as React from "react";
import { Link as RouterLink } from "react-router";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { IssueReportWithRelations } from "@volley/data";

import { usDateFormat } from "../../util";
import usePaginatedData from "../hooks/usePaginatedData";

function TopAlignedTableCell(props: TableCellProps): React.JSX.Element {
    return <TableCell style={{ verticalAlign: "top" }} {...props} />;
}

interface CommentCellProps {
    comment: string;
}
function CommentCell({ comment }: CommentCellProps) {
    const [isCollapsed, setIsCollapsed] = React.useState<boolean>(true);
    const isCommentLong = (text: string) => text.length > 100;

    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    if (isCommentLong(comment)) {
        return (
            <TopAlignedTableCell>
                <Box display="flex" alignItems="flex-start" component="div">
                    <Collapse in={!isCollapsed} collapsedSize={40}>
                        {comment}
                    </Collapse>
                    <IconButton size="small" onClick={handleToggleCollapse}>
                        {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                    </IconButton>
                </Box>
            </TopAlignedTableCell>
        );
    }

    return <TopAlignedTableCell>{comment}</TopAlignedTableCell>;
}

interface Props {
    userId?: number;
    sessionId?: number;
    locationId?: number;
    trainerId?: number;
}
export default function IssueReportTable({
    userId,
    sessionId,
    locationId,
    trainerId,
}: Props): React.JSX.Element {
    const params = React.useMemo(() => {
        if (userId) {
            return { userId: String(userId) };
        }
        if (sessionId) {
            return { sessionId: String(sessionId) };
        }
        if (locationId) {
            return { locationId: String(locationId) };
        }
        if (trainerId) {
            return { trainerId: String(trainerId) };
        }
        return {};
    }, [userId, sessionId, locationId, trainerId]);
    const {
        rowsPerPage,
        onRowsPerPageChange,
        count,
        page,
        onPageChange,
        data: responses,
    } = usePaginatedData<IssueReportWithRelations>("/api/issue-reports", {
        params,
        initialRowsPerPage: 10,
    });

    return (
        <Grid container>
            {!sessionId && (
                <Grid size={12}>
                    <Typography variant="h2">Issues Reported</Typography>
                </Grid>
            )}
            <Grid size={12}>
                <Box
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    component="div"
                >
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Comments</TableCell>
                                    <TableCell>Issue Types</TableCell>
                                    <TableCell>Explanation of Other</TableCell>
                                    {!locationId && (
                                        <TableCell>Location</TableCell>
                                    )}
                                    {!userId && (
                                        <TableCell>Reporting User</TableCell>
                                    )}
                                    <TableCell>Previous User</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {responses.map(
                                    (response: IssueReportWithRelations) => (
                                        <TableRow key={response.id}>
                                            <TopAlignedTableCell>
                                                {usDateFormat(
                                                    new Date(
                                                        response.createdAt,
                                                    ),
                                                )}
                                            </TopAlignedTableCell>
                                            <CommentCell
                                                comment={response.comment ?? ""}
                                            />
                                            <TopAlignedTableCell>
                                                {response.issueReportIssues
                                                    .map(
                                                        (i) =>
                                                            i.issueType.label,
                                                    )
                                                    .join(", ")}
                                            </TopAlignedTableCell>
                                            <TopAlignedTableCell>
                                                {response.issueReportIssues
                                                    .filter(
                                                        (i) =>
                                                            i.issueType.name ===
                                                            "other",
                                                    )
                                                    .map(
                                                        (i) =>
                                                            i.issueDescription,
                                                    )
                                                    .join(", ")}
                                            </TopAlignedTableCell>
                                            {!locationId && (
                                                <TopAlignedTableCell>
                                                    {response.session.location
                                                        ?.name ?? ""}
                                                </TopAlignedTableCell>
                                            )}
                                            {!userId && (
                                                <TopAlignedTableCell>
                                                    <RouterLink
                                                        to={`/admin/users/${response.reportingUser.id}`}
                                                    >
                                                        {
                                                            response
                                                                .reportingUser
                                                                .username
                                                        }
                                                    </RouterLink>
                                                </TopAlignedTableCell>
                                            )}
                                            <TopAlignedTableCell>
                                                <RouterLink
                                                    to={`/admin/users/${response.previousUser.id}`}
                                                >
                                                    {
                                                        response.previousUser
                                                            .username
                                                    }
                                                </RouterLink>
                                            </TopAlignedTableCell>
                                        </TableRow>
                                    ),
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPage={rowsPerPage}
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                </Box>
            </Grid>
        </Grid>
    );
}
