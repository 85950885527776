import * as React from "react";
import { Link as RouterLink } from "react-router";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import type { VisionSettingsWithRelations } from "@volley/data";

import { usDateFormat } from "../../../util";
import fetchApi, { logFetchError } from "../../../util/fetchApi";

interface Props {
    entity: "trainer" | "workout";
    id: number;
}

export default function VisionSettingsTable({
    entity,
    id,
}: Props): React.JSX.Element {
    const [visionSettings, setVisionSettings] = React.useState<
        VisionSettingsWithRelations[]
    >([]);

    React.useEffect(() => {
        let vsettings;
        const getData = async () => {
            try {
                if (entity === "trainer") {
                    vsettings = await fetchApi<VisionSettingsWithRelations[]>(
                        `/api/vision-settings?trainerId=${id}`,
                    );
                } else {
                    vsettings = await fetchApi<VisionSettingsWithRelations[]>(
                        `/api/vision-settings?workoutId=${id}`,
                    );
                }
                setVisionSettings(vsettings);
            } catch (e) {
                logFetchError(e);
            }
        };
        void getData();
    }, [entity, id]);

    return (
        <Grid container spacing={3}>
            {entity === "trainer" && (
                <Grid size={12}>
                    <Typography component="h1" variant="h2">
                        Vision Settings
                    </Typography>
                </Grid>
            )}
            <Grid size={12}>
                <Paper
                    sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Description</TableCell>
                                    <TableCell>Trainer ID</TableCell>
                                    <TableCell>Workout</TableCell>
                                    <TableCell>Created</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {visionSettings.map((vsetting) => (
                                    <TableRow key={vsetting.trainerId}>
                                        <TableCell>
                                            <Button
                                                component={RouterLink}
                                                to={`../../vision-settings/${vsetting.id}`}
                                            >
                                                View
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            {vsetting.description}
                                        </TableCell>
                                        <TableCell>
                                            <Link
                                                component={RouterLink}
                                                to={`/admin/trainers/${vsetting.trainerId ?? ""}`}
                                            >
                                                {vsetting.trainerId}
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <Link
                                                component={RouterLink}
                                                to={`/admin/app-workouts/${vsetting.workoutId ?? ""}`}
                                            >
                                                {(vsetting.workout &&
                                                    vsetting.workout.name &&
                                                    `${vsetting.workout.name}`) ||
                                                    (vsetting.workoutId
                                                        ? `${vsetting.workoutId}`
                                                        : "")}
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            {usDateFormat(
                                                new Date(vsetting.createdAt),
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        </Grid>
    );
}
