import * as React from "react";

import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import Tooltip from "@mui/material/Tooltip";

import type { CoachStatus } from "@volley/shared/coach-models";

export interface TrainerStatusIconProps {
    status: CoachStatus;
}

export default function TrainerStatusIcon({
    status,
}: {
    status: CoachStatus | null;
}): React.JSX.Element {
    if (!status) {
        return (
            <Tooltip title="Currently offline">
                <CloudOffIcon color="disabled" />
            </Tooltip>
        );
    }
    if (status.fault) {
        if (status.fault.failures.every((f) => f.type === "BallBinEmpty")) {
            return (
                <Tooltip title="Ball bin empty">
                    <ChangeCircleIcon color="info" />
                </Tooltip>
            );
        }

        if (status.fault.failures.every((f) => f.source === "vision")) {
            return (
                <Tooltip title="Camera Failure">
                    <VideocamOffIcon color="warning" />
                </Tooltip>
            );
        }

        return (
            <Tooltip title="Fault on trainer">
                <CancelIcon color="error" />
            </Tooltip>
        );
    }

    if (status.ready === "MAINTENANCE") {
        return (
            <Tooltip title="Trainer in maintenance mode">
                <BuildCircleIcon color="warning" />
            </Tooltip>
        );
    }

    const lastPing = status.web.connection.lastPing
        ? new Date(status.web.connection.lastPing).toLocaleString()
        : "?";
    return (
        <Tooltip title={`Last ping: ${lastPing}`}>
            <CheckCircleIcon color="success" />
        </Tooltip>
    );
}
