import * as React from "react";

import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { formatDuration, intervalToDuration } from "date-fns";

import type { TrainerStats } from "@volley/data";

import { fetchApi, usDateFormat } from "../../../util";
import AdminLoading from "../AdminLoading";

function TrainerStatsView({
    trainerId,
}: {
    trainerId: number;
}): React.JSX.Element | null {
    const [stats, setStats] = React.useState<[string, string][] | null>(null);
    const [updatedAt, setUpdatedAt] = React.useState<Date | null>(null);
    const [errorMessage, setErrorMessage] = React.useState("");

    React.useEffect(() => {
        async function getStats() {
            const {
                ballsThrown,
                minutesPlayed,
                sessionsPlayed,
                workoutsPlayed,
                usersPaired,
                timestamp,
            } = await fetchApi<TrainerStats>(
                `/api/trainers/${trainerId}/stats`,
            );
            const timePlayed =
                formatDuration(
                    intervalToDuration({
                        start: 0,
                        end: minutesPlayed * 60_000,
                    }),
                    { delimiter: ", " },
                ) || "0";
            const values: [string, string][] = [
                ["Balls Thrown", ballsThrown.toLocaleString()],
                ["Time Played", timePlayed],
                ["Sessions Started", sessionsPlayed.toLocaleString()],
                ["Workouts Played", workoutsPlayed.toLocaleString()],
                ["Users Paired", usersPaired.toLocaleString()],
            ];
            setStats(values);
            setUpdatedAt(new Date(timestamp));
            setErrorMessage("");
        }

        getStats().catch((e: Error) => {
            setErrorMessage(e.message);
        });
    }, [trainerId]);

    if (errorMessage) {
        return <Typography color="error.main">{errorMessage}</Typography>;
    }

    if (!stats || !updatedAt) {
        return <AdminLoading page="Lifetime Stats" />;
    }

    return (
        <Stack spacing={2}>
            <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
            >
                <Typography variant="h2">Lifetime Statistics</Typography>
                <Typography
                    variant="h5"
                    sx={{ mt: 2 }}
                    title={updatedAt.toISOString() ?? ""}
                >
                    {`As of ${usDateFormat(updatedAt)}`}
                </Typography>
            </Stack>
            <Table>
                <TableBody>
                    {stats.map(([label, value]) => (
                        <TableRow
                            key={label}
                            sx={{ "& th": { fontWeight: 500 } }}
                        >
                            <TableCell component="th" sx={{ width: "25%" }}>
                                {label}
                            </TableCell>
                            <TableCell>{value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Stack>
    );
}

export default React.memo(TrainerStatsView);
