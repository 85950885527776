import * as React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { WeatherAPICurrentResponse } from "@volley/shared/integrations/WeatherAPI/models";

import fetchApi, { FetchError, logFetchError } from "../../../util/fetchApi";

interface TrainerWeatherConditionsProps {
    locationId: number;
}

export default function TrainerWeatherConditions({
    locationId,
}: TrainerWeatherConditionsProps): React.JSX.Element {
    const [conditions, setConditions] =
        React.useState<WeatherAPICurrentResponse | null>(null);
    const [error, setError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        async function loadConditions(): Promise<WeatherAPICurrentResponse> {
            return fetchApi<WeatherAPICurrentResponse>(
                `/api/locations/${locationId}/conditions`,
            );
        }

        if (!conditions && !loading && !error) {
            setLoading(true);
            loadConditions()
                .then((result) => {
                    setConditions(result);
                })
                .catch((err) => {
                    logFetchError(
                        err,
                        `Failed to load weather conditions for ${locationId}`,
                    );
                    if (err instanceof FetchError) {
                        setError(err.message);
                    } else {
                        setError("Unable to fetch weather conditions");
                    }
                })
                .finally(() => setLoading(false));
        }
    }, [conditions, error, loading, locationId]);

    return (
        <Stack spacing={1}>
            {error && <Box component="div">{error}</Box>}
            {loading && (
                <Box component="div">Loading Current Weather Conditions...</Box>
            )}
            {conditions && (
                <Stack direction="row" spacing={2}>
                    <Stack alignItems="center" justifyContent="space-evenly">
                        <img
                            alt={conditions.current.condition.text}
                            src={`${window.location.protocol}${conditions.current.condition.icon}`}
                            width={64}
                            height={64}
                        />
                        <Typography
                            variant="caption"
                            textAlign="center"
                            sx={{ fontWeight: "bold" }}
                        >
                            {conditions.current.condition.text}
                        </Typography>
                    </Stack>
                    <Stack justifyContent="space-evenly">
                        <Typography>
                            {`${conditions.location.name} (${conditions.location.region})`}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography
                                variant="body2"
                                sx={{ fontWeight: "bold" }}
                            >
                                Temp:
                            </Typography>
                            <Typography variant="body2">
                                {`${conditions.current.temp_f}°F (${conditions.current.temp_c}°C)`}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography
                                variant="body2"
                                sx={{ fontWeight: "bold" }}
                            >
                                Precipitation:
                            </Typography>
                            <Typography variant="body2">
                                {`${conditions.current.precip_in}in (${conditions.current.precip_mm}mm)`}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography
                                variant="body2"
                                sx={{ fontWeight: "bold" }}
                            >
                                Wind:
                            </Typography>
                            <Typography variant="body2">
                                {`${conditions.current.wind_mph}mph (${conditions.current.wind_kph}kph)`}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );

    if (conditions) {
        return (
            <Stack spacing={1}>
                <Typography variant="h3">Weather Conditions</Typography>
            </Stack>
        );
    }
    return <>Loading Weather...</>;
}
