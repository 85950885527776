import * as React from "react";
import { Link as RouterLink } from "react-router";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import {
    WorkoutPlayWithRelations,
    WorkoutPlayWithRelationsJson,
} from "@volley/data";

import { usDateFormat } from "../../../util";
import useCursorPaginatedData from "../../hooks/useCursorPaginatedData";

import Duration from "./Duration";

const workoutPlayFromJson = (
    workoutPlay: WorkoutPlayWithRelationsJson,
): WorkoutPlayWithRelations => ({
    ...workoutPlay,
    startTime: new Date(workoutPlay.startTime),
    endTime: workoutPlay.endTime ? new Date(workoutPlay.endTime) : null,
});

interface WorkoutPlaysTableProps {
    sessionId?: number;
}

export default function WorkoutPlaysTable({
    sessionId,
}: WorkoutPlaysTableProps): React.JSX.Element {
    const {
        rowsPerPage,
        onRowsPerPageChange,
        page,
        count,
        onPageChange,
        data: workoutPlays,
    } = useCursorPaginatedData("/api/workout-plays", {
        id: "workoutPlays",
        initialRowsPerPage: 10,
        params: { sessionId: String(sessionId) },
        mapFn: workoutPlayFromJson,
    });

    return (
        <>
            {sessionId && (
                <Box display="flex" justifyContent="end" mb={2}>
                    <Button
                        variant="contained"
                        disabled={!workoutPlays || workoutPlays.length === 0}
                        href={`/api/reports/session-throws/${sessionId}`}
                        download
                    >
                        Export Throws
                    </Button>
                </Box>
            )}

            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Workout</TableCell>
                            <TableCell>Start Time</TableCell>
                            <TableCell>End Time</TableCell>
                            <TableCell>Time Played</TableCell>
                            <TableCell>Throw Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {workoutPlays.map((wp) => (
                            <TableRow key={wp.id}>
                                <TableCell>
                                    <Button
                                        variant="text"
                                        component={RouterLink}
                                        to={`/admin/workout-plays/${wp.id}`}
                                    >
                                        View
                                    </Button>
                                </TableCell>
                                <TableCell>{wp.workout.name}</TableCell>
                                <TableCell>
                                    {usDateFormat(wp.startTime)}
                                </TableCell>
                                <TableCell>
                                    {wp.endTime ? usDateFormat(wp.endTime) : ""}
                                </TableCell>
                                <TableCell>
                                    <Duration
                                        start={wp.startTime}
                                        end={wp.endTime ?? undefined}
                                    />
                                </TableCell>
                                <TableCell>{wp.throwCount}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={6}
                                rowsPerPage={rowsPerPage}
                                count={count}
                                page={page}
                                onPageChange={onPageChange}
                                onRowsPerPageChange={onRowsPerPageChange}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
}
