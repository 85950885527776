import * as React from "react";

import type { LocationWithRelations } from "@volley/data";

import LocationAutocomplete from "../../common/LocationAutocomplete";

interface Props {
    value: string | null;
    onChange: (value: string | null) => void;
}

const ReportLocationParameter: React.FunctionComponent<Props> = ({
    value,
    onChange,
}) => {
    const onLocationChange = React.useCallback(
        (location: LocationWithRelations | null) => {
            onChange(location ? location.id.toString() : null);
        },
        [onChange],
    );

    return (
        <LocationAutocomplete
            value={value ? parseInt(value, 10) : null}
            onChange={onLocationChange}
            multiple={false}
        />
    );
};

export default ReportLocationParameter;
