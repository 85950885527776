import * as React from "react";

import { formatDuration, intervalToDuration } from "date-fns";

interface DurationProps {
    start?: Date;
    end?: Date;
}

export default function Duration({
    start,
    end,
}: DurationProps): React.JSX.Element | null {
    if (!start || !end) {
        return null;
    }

    const duration = intervalToDuration({ start, end });

    return <>{formatDuration(duration, {})}</>;
}
