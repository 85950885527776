import * as React from "react";
import { useSearchParams } from "react-router";

import Dialog from "@mui/material/Dialog";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { CursorResult, ImageWithRelationsJson } from "@volley/data";

import { fetchApi } from "../../../util";

import SnapshotView from "./SnapshotView";

const ROWS_PER_PAGE = 8;

interface Props {
    entity?: "trainer" | "session" | "all";
    id?: number;
    title?: string;
}

export default function SnapshotsGrid({
    entity = "all",
    id,
    title,
}: Props): React.JSX.Element {
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
    const cols = isLgUp ? 4 : 1;
    const [errorMessage, setErrorMessage] = React.useState("");
    const [images, setImages] = React.useState<ImageWithRelationsJson[]>([]);
    const [previousCursor, setPreviousCursor] = React.useState<string>();
    const [nextCursor, setNextCursor] = React.useState<string>();
    const [page, setPage] = React.useState(0);
    const [lastSelectedIndex, setLastSelectedIndex] = React.useState<
        number | null
    >(null);
    const [initiallyFetched, setInitiallyFetched] = React.useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const imageId = searchParams.get("imageId");
    const cursor = searchParams.get("imageCursor");

    const fetchImages = React.useCallback(
        async (cursorArg?: string) => {
            const query = new URLSearchParams({
                take: ROWS_PER_PAGE.toString(),
            });
            if (cursorArg) query.set("cursor", cursorArg);
            if (entity === "trainer" && id)
                query.set("clientId", id.toString());
            if (entity === "session" && id)
                query.set("sessionId", id.toString());
            try {
                const result = await fetchApi<
                    CursorResult<ImageWithRelationsJson>
                >(`/api/images?${query.toString()}`);
                setImages(result.result);
                setPreviousCursor(result.previous);
                setNextCursor(result.next);

                if (cursorArg) {
                    setSearchParams((params) => {
                        params.set("imageCursor", cursorArg);
                        return params;
                    });
                }

                return result.result;
            } catch (e) {
                setErrorMessage((e as Error).message);
            }
            return [];
        },
        [entity, id, setSearchParams],
    );

    const onImageClick = (idArg: number) => {
        setSearchParams((params) => {
            params.set("imageId", idArg.toString());
            return params;
        });
    };

    const onPageChange = async (
        _e: React.MouseEvent<HTMLButtonElement> | null,
        pageArg: number,
    ) => {
        const imageCursor = pageArg < page ? previousCursor : nextCursor;
        try {
            const fetched = await fetchImages(
                pageArg === 0 ? undefined : imageCursor,
            );
            setPage(pageArg);
            setLastSelectedIndex(null);
            return fetched;
        } catch (e: unknown) {
            setErrorMessage((e as Error).message);
            return [];
        }
    };

    const onPreviousClick = async () => {
        if (!imageId) return;

        const selectedIndex = images.findIndex(
            (image) => image.id.toString() === imageId,
        );
        if (selectedIndex === 0 && previousCursor) {
            const fetched = await onPageChange(null, page - 1);
            setSearchParams((params) => {
                params.set("imageId", fetched[images.length - 1].id.toString());
                return params;
            });
        } else {
            setSearchParams((params) => {
                params.set("imageId", images[selectedIndex - 1].id.toString());
                return params;
            });
        }
    };

    const onNextClick = async () => {
        if (!imageId) return;

        const selectedIndex = images.findIndex(
            (image) => image.id.toString() === imageId,
        );
        if (selectedIndex >= images.length - 1 && nextCursor) {
            const fetched = await onPageChange(null, page + 1);
            setSearchParams((params) => {
                params.set("imageId", fetched[0].id.toString());
                return params;
            });
        } else {
            setSearchParams((params) => {
                params.set("imageId", images[selectedIndex + 1].id.toString());
                return params;
            });
        }
    };

    React.useEffect(() => {
        if (!initiallyFetched) {
            fetchImages(cursor ?? undefined).catch((err: Error) => {
                setErrorMessage(err.message);
            });
            setInitiallyFetched(true);
        }
    }, [initiallyFetched, fetchImages, cursor]);

    const onClose = () => {
        const lastIndex = images.findIndex(
            (image) => image.id.toString() === imageId,
        );
        setLastSelectedIndex(lastIndex >= 0 ? lastIndex : null);
        setSearchParams((prev) => {
            prev.delete("imageId");
            return prev;
        });
    };

    const selectedImageIndex = React.useMemo(
        () => images.findIndex((i) => i.id.toString() === imageId),
        [images, imageId],
    );

    return (
        <>
            {title && <Typography variant="h2">{title}</Typography>}
            {errorMessage}
            <ImageList cols={cols}>
                {images.map((image, i) => {
                    const barTitle = `Trainer #${image.trainer.clientId}`;
                    const barSubtitle = new Date(
                        image.timestamp,
                    ).toLocaleString();
                    return (
                        <ImageListItem
                            key={image.id}
                            onClick={() => onImageClick(image.id)}
                            sx={{
                                cursor: "pointer",
                                fontWeight:
                                    i === lastSelectedIndex ? 600 : undefined,
                            }}
                        >
                            <img
                                src={image.objectStoreFile.url!}
                                alt={`Snapshot from #${image.trainer.clientId}`}
                                width="auto"
                                height="100%"
                                style={{ aspectRatio: "16 / 9" }}
                            />
                            <ImageListItemBar
                                position="bottom"
                                title={barTitle}
                                subtitle={barSubtitle}
                            />
                        </ImageListItem>
                    );
                })}
            </ImageList>
            <TablePagination
                rowsPerPageOptions={[]}
                rowsPerPage={ROWS_PER_PAGE}
                component="div"
                count={nextCursor ? -1 : page * ROWS_PER_PAGE + images.length}
                page={page}
                onPageChange={onPageChange}
                showFirstButton
            />
            <Dialog open={!!imageId} onClose={onClose} fullScreen={!isLgUp}>
                {selectedImageIndex > -1 && (
                    <SnapshotView
                        image={images[selectedImageIndex]}
                        onNextClick={
                            selectedImageIndex < images.length - 1 || nextCursor
                                ? onNextClick
                                : undefined
                        }
                        onPreviousClick={
                            selectedImageIndex > 0 || previousCursor
                                ? onPreviousClick
                                : undefined
                        }
                        onClose={onClose}
                    />
                )}
            </Dialog>
        </>
    );
}
