import * as React from "react";
import { Link as RouterLink } from "react-router";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { FeedbackResponseWithRelations } from "@volley/data";

import { usDateFormat } from "../../../util";
import usePaginatedData from "../../hooks/usePaginatedData";

function TopAlignedTableCell(props: TableCellProps): React.JSX.Element {
    return <TableCell style={{ verticalAlign: "top" }} {...props} />;
}

interface CommentCellProps {
    comment: string;
}
function CommentCell({ comment }: CommentCellProps) {
    const [isCollapsed, setIsCollapsed] = React.useState<boolean>(true);
    const isCommentLong = (text: string) => text.length > 100;

    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    if (isCommentLong(comment)) {
        return (
            <TopAlignedTableCell>
                <Box display="flex" alignItems="flex-start" component="div">
                    <Collapse in={!isCollapsed} collapsedSize={40}>
                        {comment}
                    </Collapse>
                    <IconButton size="small" onClick={handleToggleCollapse}>
                        {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                    </IconButton>
                </Box>
            </TopAlignedTableCell>
        );
    }

    return <TopAlignedTableCell>{comment}</TopAlignedTableCell>;
}

interface Props {
    userId: number;
}
export default function UserFeedbackTable({
    userId,
}: Props): React.JSX.Element {
    const {
        rowsPerPage,
        onRowsPerPageChange,
        count,
        page,
        onPageChange,
        data: responses,
    } = usePaginatedData<FeedbackResponseWithRelations>("/api/feedback", {
        params: { userId: String(userId) },
        initialRowsPerPage: 10,
    });

    return (
        <Grid container>
            <Grid size={12}>
                <Typography variant="h2">Feedback</Typography>
            </Grid>
            <Grid size={12}>
                <Box
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    component="div"
                >
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Rating</TableCell>
                                    <TableCell>Comments</TableCell>
                                    <TableCell>Location</TableCell>
                                    <TableCell>Session</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {responses.map(
                                    (
                                        response: FeedbackResponseWithRelations,
                                    ) => (
                                        <TableRow key={response.id}>
                                            <TopAlignedTableCell>
                                                {usDateFormat(
                                                    new Date(
                                                        response.createdAt,
                                                    ),
                                                )}
                                            </TopAlignedTableCell>
                                            <TopAlignedTableCell>
                                                {response.rating}
                                            </TopAlignedTableCell>
                                            <CommentCell
                                                comment={response.comment ?? ""}
                                            />
                                            <TopAlignedTableCell>
                                                {response.session.location
                                                    ?.name ?? ""}
                                            </TopAlignedTableCell>
                                            <TopAlignedTableCell>
                                                <Button
                                                    component={RouterLink}
                                                    to={`/admin/sessions/${response.session.id.toString()}`}
                                                >
                                                    View
                                                </Button>
                                            </TopAlignedTableCell>
                                        </TableRow>
                                    ),
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPage={rowsPerPage}
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                </Box>
            </Grid>
        </Grid>
    );
}
