import * as React from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {
    AppWorkoutCollaboratorWithUser,
    AppWorkoutWithSummary,
    OffsetResult,
    UserWithRelations,
} from "@volley/data";

import fetchApi, { logFetchError } from "../../../util/fetchApi";

interface Props {
    open: boolean;
    workout: AppWorkoutWithSummary;
    onClose: () => void;
}

export default function CollaboratorsDialog({
    onClose,
    open,
    workout,
}: Props): React.JSX.Element {
    const [loading, setLoading] = React.useState(false);
    const searchRef = React.useRef<HTMLInputElement>(null);
    const [userMatches, setUserMatches] = React.useState<
        OffsetResult<UserWithRelations>
    >({
        count: 0,
        offset: 0,
        result: [],
    });
    const [currentCollaborators, setCurrentCollaborators] = React.useState(
        workout.workoutCollaborators,
    );

    const searchForUsers = React.useCallback(
        async (query: string) => {
            if (!loading && query !== "") {
                setLoading(true);
                const url = `/api/users/search?q=${query}&limit=20&offset=0`;
                try {
                    const results =
                        await fetchApi<OffsetResult<UserWithRelations>>(url);
                    setUserMatches(results);
                } catch (e) {
                    logFetchError(e);
                } finally {
                    setLoading(false);
                }
            }
        },
        [loading],
    );

    const addCollaborator = React.useCallback(
        async (userId: number) => {
            const url = `/api/app-workouts/${workout.id}/collaborators/${userId}`;
            try {
                setLoading(true);
                const updated = await fetchApi<
                    AppWorkoutCollaboratorWithUser[]
                >(url, "POST");
                setCurrentCollaborators(
                    updated.map((c) => ({
                        role: c.role,
                        user: c.user,
                    })),
                );
            } catch (e) {
                logFetchError(e);
            } finally {
                setLoading(false);
            }
        },
        [workout.id],
    );

    const removeCollaborator = React.useCallback(
        async (userId: number) => {
            const url = `/api/app-workouts/${workout.id}/collaborators/${userId}`;
            try {
                setLoading(true);
                const updated = await fetchApi<
                    AppWorkoutCollaboratorWithUser[]
                >(url, "DELETE");
                setCurrentCollaborators(
                    updated.map((c) => ({
                        role: c.role,
                        user: c.user,
                    })),
                );
            } catch (e) {
                logFetchError(e);
            } finally {
                setLoading(false);
            }
        },
        [workout.id],
    );

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>Collaborators</DialogTitle>
            <DialogContent>
                <Stack spacing={1} paddingTop="10px">
                    <Paper
                        component="form"
                        onSubmit={(e) => {
                            e.preventDefault();
                            searchForUsers(searchRef.current?.value || "");
                        }}
                        sx={{
                            p: "4px 4px",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "24px",
                        }}
                    >
                        <InputBase
                            sx={{
                                ml: 1,
                                flex: 1,
                                borderRadius: "50%",
                            }}
                            placeholder="Search for users..."
                            autoFocus
                            inputRef={searchRef}
                        />
                        <IconButton
                            type="button"
                            sx={{
                                p: "10px",
                                borderRadius: "24px",
                                color: (t) => t.palette.secondary.contrastText,
                                backgroundColor: (t) =>
                                    t.palette.secondary.main,
                            }}
                            aria-label="search"
                            onClick={() =>
                                searchForUsers(searchRef.current?.value || "")
                            }
                        >
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Stack>
                <Card
                    sx={{
                        height: "24vh",
                        padding: "5px",
                        overflowY: "scroll",
                    }}
                >
                    <Stack spacing={1}>
                        {loading && <CircularProgress />}
                        {!loading &&
                            userMatches.result.map((u) => {
                                const collaborator = currentCollaborators.find(
                                    (c) => c.user.id === u.id,
                                );
                                return (
                                    <Stack
                                        key={`${u.id}-search-result`}
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography>{`${u.username} [${u.firstName} ${u.lastName}]${collaborator ? ` (${collaborator.role})` : ""}`}</Typography>
                                        <IconButton
                                            disabled={
                                                collaborator !== undefined
                                            }
                                            size="small"
                                            color="secondary"
                                            onClick={() => {
                                                addCollaborator(u.id);
                                            }}
                                        >
                                            <AddCircleIcon />
                                        </IconButton>
                                    </Stack>
                                );
                            })}
                    </Stack>
                </Card>
                <Typography>{`Current Collaborators:`}</Typography>
                <Card
                    sx={{
                        height: "24vh",
                        padding: "5px",
                        overflowY: "scroll",
                    }}
                >
                    <Stack height="100%" spacing={1}>
                        {currentCollaborators.map((c) => (
                            <Stack
                                key={`${c.user.id}-user-collaborator`}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography>{`${c.user.username} [${c.user.firstName} ${c.user.lastName}] (${c.role})`}</Typography>
                                <IconButton
                                    disabled={c.role === "owner"}
                                    size="small"
                                    color="warning"
                                    onClick={() => {
                                        removeCollaborator(c.user.id);
                                    }}
                                >
                                    <RemoveCircleIcon />
                                </IconButton>
                            </Stack>
                        ))}
                    </Stack>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="info"
                    onClick={() => {
                        setUserMatches({ count: 0, offset: 0, result: [] });
                        onClose();
                    }}
                >
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
}
