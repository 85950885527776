import * as React from "react";
import {
    Link as RouterLink,
    useLocation,
    useNavigate,
    useParams,
} from "react-router";

import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MuiTelInput } from "mui-tel-input";

import type {
    PasswordResetResponse,
    UserProfileDto,
    UserCreateJson,
    UserWithRelations,
} from "@volley/data";

import { usDateFormat } from "../../../util";
import fetchApi from "../../../util/fetchApi";
import { HomeLocationWithSelfDescribe } from "../../common/HomeLocationAutocomplete";
import { USER_TAG_OPTIONS } from "../../common/data";
import AdminSaveButton, { SaveState } from "../AdminSaveButton";
import IssueReportTable from "../IssueReportTable";
import SessionsTable from "../Sessions/SessionsTable";

import UserFeatureList from "./UserFeatureList";
import UserFeedbackTable from "./UserFeedbackTable";
import UserSubscriptionTable from "./UserSubscriptionsTable";

interface Form {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    homeLocationId: number | null;
    selfDescribedHomeLocationName: string | null;
    tags: string[];
    uid: string;
    phone: string;
    gender: string | null;
    birthday: Date | null;
    aptaPlayerId: string | null;
    platformTennisIndex: number | null;
    optedIntoMarketingAt: Date | null;
    userRoles: string[];
    disabledAt: Date | null;
    updatedBy: string;
    internal: boolean;
}

type Action =
    | { type: "firstName"; value: string }
    | { type: "lastName"; value: string }
    | { type: "email"; value: string }
    | { type: "username"; value: string }
    | { type: "homeLocationId"; value: number | null }
    | { type: "selfDescribedHomeLocationName"; value: string | null }
    | { type: "tags"; value: string[] }
    | { type: "uid"; value: string }
    | { type: "phone"; value: string }
    | { type: "userRoles"; value: string[] }
    | { type: "set"; value: Form }
    | { type: "aptaPlayerId"; value: string | null }
    | { type: "internal"; value: boolean };

function reducer(state: Form, action: Action): Form {
    switch (action.type) {
        case "firstName":
            return { ...state, firstName: action.value };
        case "lastName":
            return { ...state, lastName: action.value };
        case "email":
            return { ...state, email: action.value };
        case "username":
            return { ...state, username: action.value };
        case "homeLocationId":
            return { ...state, homeLocationId: action.value };
        case "selfDescribedHomeLocationName":
            return { ...state, selfDescribedHomeLocationName: action.value };
        case "tags":
            return { ...state, tags: action.value };
        case "uid":
            return { ...state, uid: action.value };
        case "phone":
            return { ...state, phone: action.value };
        case "userRoles":
            return { ...state, userRoles: action.value };
        case "set":
            return { ...action.value };
        case "aptaPlayerId":
            return { ...state, aptaPlayerId: action.value };
        case "internal":
            return { ...state, internal: action.value };
        default:
            throw new Error("Invalid form action type");
    }
}

const defaultState: Form = {
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    homeLocationId: null,
    selfDescribedHomeLocationName: null,
    tags: [],
    uid: "",
    phone: "",
    birthday: null,
    gender: null,
    optedIntoMarketingAt: null,
    aptaPlayerId: null,
    platformTennisIndex: null,
    userRoles: [],
    disabledAt: null,
    updatedBy: "",
    internal: false,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface PasswordState {
    password?: string;
}

export default function UserEdit(): React.JSX.Element {
    const { id } = useParams<"id">();
    const validId = parseInt(id ?? "", 10);
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const desktopMatch = useMediaQuery(theme.breakpoints.up("md"));
    const [roles, setRoles] = React.useState<string[]>([]);
    const [features, setFeatures] = React.useState<string[]>([]);
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const [saveState, setSaveState] = React.useState(SaveState.Unsaved);
    const [errorMessage, setErrorMessage] = React.useState("");

    React.useEffect(() => {
        fetchApi<string[]>("/api/users/roles")
            .then((rolesData) => setRoles(rolesData))
            .catch((e: Error) => setErrorMessage(e.message));
    }, []);

    const fetchUser = React.useCallback(async () => {
        if (!validId) return;
        const [data, profileData, featuresData] = await Promise.all([
            fetchApi<UserWithRelations>(`/api/users/${validId}`),
            fetchApi<NonNullable<UserProfileDto["profile"]>>(
                `/api/users/${validId}/profile`,
            ),
            fetchApi<string[]>(`/api/users/${validId}/features`),
        ]);
        setFeatures(featuresData);
        dispatch({
            type: "set",
            value: {
                id: data.id,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                username: data.username,
                uid: data.uid,
                phone: profileData.phone || "",
                birthday: profileData.birthday
                    ? new Date(profileData.birthday)
                    : null,
                gender: profileData.gender,
                optedIntoMarketingAt: profileData.optedIntoMarketingAt
                    ? new Date(profileData.optedIntoMarketingAt)
                    : null,
                aptaPlayerId: profileData.aptaPlayerId,
                platformTennisIndex: profileData.platformTennisIndex,
                homeLocationId: profileData.homeLocationId,
                selfDescribedHomeLocationName:
                    profileData.selfDescribedHomeLocationName ?? null,
                tags: profileData.tags ?? [],
                userRoles: data.userRoles.map((ur) => ur.role.name),
                disabledAt: data.disabledAt ? new Date(data.disabledAt) : null,
                updatedBy: data.updatedBy,
                internal: !!data.internal,
            },
        });
    }, [validId]);

    React.useEffect(() => {
        setErrorMessage("");
        fetchUser().catch((e: Error) => setErrorMessage(e.message));
    }, [fetchUser]);

    const onSubmit = React.useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            setSaveState(SaveState.Saving);
            setErrorMessage("");
            const payload: UserCreateJson = {
                firstName: state.firstName.trim(),
                lastName: state.lastName.trim(),
                email: state.email.trim(),
                uid: state.uid.trim(),
                internal: state.internal,
                profile: {
                    phone: state.phone.trim() || null,
                    birthday: state.birthday?.toISOString() ?? null,
                    gender: state.gender,
                    optedIntoMarketingAt:
                        state.optedIntoMarketingAt?.toISOString() ?? null,
                    aptaPlayerId: state.aptaPlayerId,
                    platformTennisIndex: state.platformTennisIndex,
                    homeLocationId:
                        (state.homeLocationId ?? -1) > 0
                            ? state.homeLocationId
                            : null,
                    selfDescribedHomeLocationName:
                        state.selfDescribedHomeLocationName,
                    tags: state.tags,
                },
                userRoles: state.userRoles.map((r: string) => ({
                    role: { name: r },
                })),
            };

            try {
                let result: UserWithRelations & { password?: string };
                if (state.id) {
                    result = await fetchApi(`/api/users/${state.id}`, "PUT", {
                        ...payload,
                        id: state.id,
                        username: state.username.trim(),
                    });
                } else {
                    result = await fetchApi("/api/users", "POST", {
                        ...payload,
                        uid: null,
                    });
                }
                setSaveState(SaveState.Saved);
                const featuresData = await fetchApi<string[]>(
                    `/api/users/${result.id}/features`,
                );
                setFeatures(featuresData);
                navigate(`../${result.id.toString()}`, {
                    replace: true,
                    state: { password: result?.password },
                });
            } catch (err: unknown) {
                const error = err as Error;
                setErrorMessage(error.message);
                setSaveState(SaveState.Unsaved);
            }
        },
        [state, navigate],
    );

    const onResetPassword = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            const sure = window.confirm(
                "Are you sure you want to reset the password for this user?",
            );
            if (sure) {
                try {
                    const { password } = await fetchApi<PasswordResetResponse>(
                        `/api/users/${state.id}/password`,
                        "PUT",
                    );
                    navigate("", { replace: true, state: { password } });
                } catch (err: unknown) {
                    const error = err as Error;
                    setErrorMessage(error.message);
                }
            }
        },
        [navigate, state.id],
    );

    const onDisable = React.useCallback(() => {
        const sure = window.confirm(
            "Are you sure you? Disabling this user will prevent them from logging in or using the trainer.",
        );
        if (sure) {
            fetchApi(`/api/users/${state.id}`, "DELETE")
                .then(() => fetchUser())
                .catch((e: Error) => setErrorMessage(e.message));
        }
    }, [state.id, fetchUser]);

    const onSOS = React.useCallback(async () => {
        const sure = window.confirm(
            "Are you sure you want to send an SOS to this user?",
        );
        if (sure) {
            try {
                await fetchApi(`/api/sos/users/${state.id}/send`, "POST");
            } catch (err: unknown) {
                const error = err as Error;
                setErrorMessage(error.message);
            }
        }
    }, [state.id]);

    const onReactivate = React.useCallback(() => {
        fetchApi(`/api/users/${state.id}/reactivate`, "PUT")
            .then(() => fetchUser())
            .catch((e: Error) => setErrorMessage(e.message));
    }, [state.id, fetchUser]);

    const isNew = !state.id;
    return (
        <Grid container spacing={3}>
            <Grid size={12}>
                <Paper
                    sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        "& > :not(style)": { m: 1 },
                    }}
                    component="form"
                    autoComplete="off"
                    onSubmit={onSubmit}
                >
                    <Grid container>
                        {!!state.disabledAt && (
                            <Grid size={12}>
                                <Typography color="warning.main">
                                    {`This user was disabled by ${state.updatedBy} ` +
                                        `on ${usDateFormat(state.disabledAt)} and cannot be edited.`}
                                </Typography>
                            </Grid>
                        )}
                        <Grid size={10}>
                            <Typography
                                component="h1"
                                variant="h2"
                                title={`ID: ${state.id || "(New)"}`}
                            >
                                {state.id
                                    ? `${state.firstName} ${state.lastName}`
                                    : "New User"}
                            </Typography>
                        </Grid>
                        <Grid sx={{ textAlign: "right" }} size={2}>
                            <IconButton
                                size="large"
                                component={RouterLink}
                                to=".."
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {(location.state as PasswordState)?.password && (
                        <>
                            <Typography>
                                Below is the password generated for the user. If
                                this user plans on signing in with this password
                                instead of Google, send it to them securely.
                                This password will only be visible to you once.
                            </Typography>
                            <pre style={{ textAlign: "center" }}>
                                {(location.state as PasswordState).password}
                            </pre>
                        </>
                    )}
                    {errorMessage && (
                        <Typography color="error.main">
                            {errorMessage}
                        </Typography>
                    )}
                    <TextField
                        id="firstName"
                        label="First Name"
                        value={state.firstName}
                        onChange={(e) =>
                            dispatch({
                                type: "firstName",
                                value: e.currentTarget.value,
                            })
                        }
                    />
                    <TextField
                        id="lastName"
                        label="Last Name"
                        value={state.lastName}
                        onChange={(e) =>
                            dispatch({
                                type: "lastName",
                                value: e.currentTarget.value,
                            })
                        }
                    />
                    <TextField
                        id="email"
                        type="email"
                        label="Email"
                        value={state.email}
                        onChange={(e) =>
                            dispatch({
                                type: "email",
                                value: e.currentTarget.value,
                            })
                        }
                    />
                    <TextField
                        id="username"
                        type="text"
                        label="Username"
                        value={state.username}
                        onChange={(e) =>
                            dispatch({
                                type: "username",
                                value: e.currentTarget.value,
                            })
                        }
                    />
                    <HomeLocationWithSelfDescribe
                        homeLocationId={state.homeLocationId}
                        onChange={(value) =>
                            dispatch({
                                type: "homeLocationId",
                                value: value?.homeLocation?.id ?? null,
                            })
                        }
                        selfDescribedName={state.selfDescribedHomeLocationName}
                        onChangeSelfDescribedName={(value) =>
                            dispatch({
                                type: "selfDescribedHomeLocationName",
                                value,
                            })
                        }
                        hasSelected
                    />
                    <FormControl>
                        <InputLabel id="tags">Club Tags</InputLabel>
                        <Select
                            labelId="tags"
                            id="tags"
                            value={state.tags.length ? state.tags[0] : ""}
                            label="Club Tags"
                            MenuProps={MenuProps}
                            onChange={({ target: { value } }) => {
                                dispatch({
                                    type: "tags",
                                    value: value ? [value] : [],
                                });
                            }}
                        >
                            {USER_TAG_OPTIONS.map((t) => (
                                <MenuItem key={t} value={t}>
                                    {t}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <MuiTelInput
                        autoComplete="tel"
                        defaultCountry="US"
                        onlyCountries={["US", "CA"]}
                        forceCallingCode
                        disableDropdown
                        id="phone-number"
                        label="Phone Number"
                        value={state.phone}
                        onChange={(value: string) =>
                            dispatch({ type: "phone", value })
                        }
                    />
                    <TextField
                        id="uid"
                        type="text"
                        disabled={isNew}
                        label="Firebase UID"
                        value={state.uid}
                        onChange={(e) =>
                            dispatch({
                                type: "uid",
                                value: e.currentTarget.value,
                            })
                        }
                    />
                    <FormControl>
                        <InputLabel id="userRolesLabel">Roles</InputLabel>
                        <Select
                            labelId="userRolesLabel"
                            id="userRoles"
                            multiple
                            value={state.userRoles}
                            label="Trainers"
                            MenuProps={MenuProps}
                            onChange={({ target: { value } }) => {
                                dispatch({
                                    type: "userRoles",
                                    value:
                                        typeof value === "string"
                                            ? value.split(",")
                                            : value,
                                });
                            }}
                        >
                            {roles.map((r: string) => (
                                <MenuItem key={r} value={r}>
                                    {r}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        id="aptaPlayerId"
                        label="APTA Player ID"
                        value={state.aptaPlayerId || ""}
                        onChange={(e) =>
                            dispatch({
                                type: "aptaPlayerId",
                                value: e.currentTarget.value,
                            })
                        }
                    />
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={!!state.internal}
                                    onChange={(e) =>
                                        dispatch({
                                            type: "internal",
                                            value: e.target.checked,
                                        })
                                    }
                                />
                            }
                            label="Internal"
                        />
                    </FormGroup>

                    {!isNew && (
                        <Box
                            component="div"
                            display="flex"
                            justifyContent={
                                desktopMatch ? "flex-end" : "center"
                            }
                        >
                            <Stack
                                spacing={desktopMatch ? 2 : undefined}
                                direction={desktopMatch ? "row" : "column"}
                            >
                                <Button component={RouterLink} to="thin-client">
                                    Live View
                                </Button>
                                <Button
                                    color="warning"
                                    onClick={onResetPassword}
                                >
                                    Reset Password
                                </Button>
                                {!state.disabledAt && (
                                    <Button color="error" onClick={onDisable}>
                                        Disable
                                    </Button>
                                )}
                                {state.disabledAt && (
                                    <Button
                                        color="warning"
                                        onClick={onReactivate}
                                    >
                                        Reactivate
                                    </Button>
                                )}
                                <Button
                                    color="error"
                                    variant="contained"
                                    onClick={onSOS}
                                >
                                    SOS
                                </Button>
                            </Stack>
                        </Box>
                    )}
                    <Divider />
                    <Box
                        component="div"
                        display="flex"
                        justifyContent={desktopMatch ? "flex-end" : "center"}
                    >
                        <Stack spacing={2} direction="row">
                            <AdminSaveButton
                                type="submit"
                                variant="contained"
                                saveState={saveState}
                            />
                            <Button component={RouterLink} to="..">
                                Cancel
                            </Button>
                        </Stack>
                    </Box>
                </Paper>
            </Grid>
            <Grid size={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <UserFeatureList features={features} />
                </Paper>
            </Grid>
            {!isNew && (
                <Grid size={12}>
                    <Paper
                        sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                        <UserSubscriptionTable userId={validId} />
                    </Paper>
                </Grid>
            )}
            {!isNew && (
                <Grid size={12}>
                    <Paper
                        sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                        <Grid container>
                            <Grid size={12}>
                                <Typography variant="h2">Sessions</Typography>
                            </Grid>
                            <Grid size={12}>
                                <Box
                                    component="div"
                                    p={2}
                                    display="flex"
                                    flexDirection="column"
                                >
                                    <SessionsTable
                                        userId={validId}
                                        initialRowsPerPage={10}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            )}
            {!isNew && (
                <Grid size={12}>
                    <Paper
                        sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                        <UserFeedbackTable userId={validId} />
                    </Paper>
                </Grid>
            )}
            {!isNew && (
                <Grid size={12}>
                    <Paper
                        sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                        <IssueReportTable userId={validId} />
                    </Paper>
                </Grid>
            )}
        </Grid>
    );
}
