import * as React from "react";

import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";

import { PartnerLocation } from "@volley/data";
import type { UserWithRelations } from "@volley/data";

import { usDateFormat } from "../../../../util";
import useDebounce from "../../../hooks/useDebounce";
import usePaginatedData from "../../../hooks/usePaginatedData";

import ProAddDialog from "./ProAddDialog";

interface Props {
    handleSubmitParent: (user: UserWithRelations) => void;
    pros: PartnerLocation[];
    locationName: string;
    initialRowsPerPage: 3 | 5 | 10;
}

export default function LocationProTable({
    handleSubmitParent,
    pros,
    locationName,
    initialRowsPerPage,
}: Props): React.JSX.Element {
    const [search, setSearch] = React.useState("");
    const debouncedSearch = useDebounce(search);
    const params = React.useMemo(
        () => ({
            q: debouncedSearch || undefined,
        }),
        [debouncedSearch],
    );
    const {
        rowsPerPage,
        onRowsPerPageChange,
        count,
        page,
        onPageChange,
        data: users,
    } = usePaginatedData<UserWithRelations>("/api/users", {
        id: "users",
        params,
        initialRowsPerPage,
    });

    const onChangeSearch = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (page > 0) {
                onPageChange(e, 0);
            }
            setSearch(e.currentTarget.value);
        },
        [page, onPageChange],
    );

    return (
        <>
            <Grid container spacing={3}>
                <Grid>
                    <TextField
                        type="search"
                        size="small"
                        label="Search"
                        value={search}
                        onChange={onChangeSearch}
                        slotProps={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            },
                        }}
                    />
                </Grid>
            </Grid>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Username</TableCell>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Roles</TableCell>
                            <TableCell>Created At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((u) => (
                            <TableRow key={u.id}>
                                <TableCell>
                                    {pros.filter((pro) => pro.userId === u.id)
                                        .length > 0 ? (
                                        <IconButton disabled>
                                            <AddIcon />
                                        </IconButton>
                                    ) : (
                                        <ProAddDialog
                                            handleSubmitParent={
                                                handleSubmitParent
                                            }
                                            user={u}
                                            locationName={locationName}
                                        />
                                    )}
                                </TableCell>
                                <TableCell>
                                    <>
                                        {u.username}
                                        {!!u.disabledAt && " (disabled)"}
                                    </>
                                </TableCell>
                                <TableCell>{u.firstName}</TableCell>
                                <TableCell>{u.lastName}</TableCell>
                                <TableCell>{u.email}</TableCell>
                                <TableCell>
                                    {u.userRoles
                                        .map((ur) => ur.role.name)
                                        .sort((a, b) => a.localeCompare(b))
                                        .join(", ")}
                                </TableCell>
                                <TableCell>
                                    {usDateFormat(new Date(u.createdAt))}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={7}
                                rowsPerPage={rowsPerPage}
                                count={count}
                                page={page}
                                onPageChange={onPageChange}
                                onRowsPerPageChange={onRowsPerPageChange}
                                rowsPerPageOptions={[3, 5, 10]}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
}
