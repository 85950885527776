import * as React from "react";
import { useParams } from "react-router";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { formatDuration, intervalToDuration } from "date-fns";

import { FinalResult, TestReport } from "@volley/shared/coach-models";

import { fetchApi } from "../../../util";

const humanReadableDuration = (ms: number) =>
    formatDuration(intervalToDuration({ start: 0, end: ms }));

function TestIcon({ passed }: { passed: boolean }) {
    return passed ? (
        <CheckCircleIcon style={{ color: "green" }} />
    ) : (
        <CancelIcon style={{ color: "red" }} />
    );
}

function TestResultRow({ result }: { result: FinalResult }) {
    return (
        <TableRow key={result.name}>
            <TableCell>
                <TestIcon passed={result.passed} />
            </TableCell>
            <TableCell>{result.name}</TableCell>
            <TableCell>
                {Array.isArray(result.targetValue)
                    ? result.targetValue.join(" - ")
                    : result.targetValue}
            </TableCell>
            <TableCell>{result.actualValue}</TableCell>
        </TableRow>
    );
}

export default function TestResultsPage(): React.JSX.Element {
    const { id } = useParams<"id">();
    const [state, setState] = React.useState<TestReport | null>(null);

    React.useEffect(() => {
        async function fetchTestResults() {
            if (!id) {
                return;
            }

            const data = await fetchApi<TestReport>(`/api/test-results/${id}`);
            setState(data);
        }
        void fetchTestResults();
    }, [id]);

    if (!state || state === null) {
        return <div>Loading...</div>;
    }

    const failures = state.results.filter((result) => !result.passed);

    return (
        <>
            <Typography variant="h4">Self Test Results</Typography>
            <Stack sx={{ my: 2 }} direction="row">
                <Typography variant="h3" sx={{ mr: 1 }}>
                    {state.outcome === "Passed" ? (
                        <CheckCircleIcon style={{ color: "green" }} />
                    ) : (
                        <CancelIcon style={{ color: "red" }} />
                    )}
                </Typography>
                <Typography variant="h3">{state.outcome}</Typography>
                {state.failure && (
                    <Link href="#failure" sx={{ ml: 1 }}>
                        View fault details
                    </Link>
                )}
            </Stack>
            <Grid container spacing={3}>
                <Grid
                    size={{
                        xs: 12,
                        md: 6,
                    }}
                >
                    <List>
                        <ListItem>
                            <ListItemText
                                primary="Client ID"
                                secondary={state.clientId}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Timestamp"
                                secondary={
                                    state.timestamp
                                        ? new Date(
                                              state.timestamp,
                                          ).toLocaleString()
                                        : ""
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Test Version"
                                secondary={state.testVersion}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Duration"
                                secondary={
                                    state.duration
                                        ? humanReadableDuration(state.duration)
                                        : 0
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="M18 A Level"
                                secondary={state.m18ALevel}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="M18 B Level"
                                secondary={state.m18BLevel}
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemText
                                primary="UPS Level"
                                secondary={state.upsLevel}
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary="Software Version"
                                secondary={state.softwareVersion}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="PCM Firmware"
                                secondary={state.pcmFirmware}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="PCM Hardware"
                                secondary={state.pcmHardware}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="HCM Firmware"
                                secondary={state.hcmFirmware}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="HCM Hardware"
                                secondary={state.hcmFirmware}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Billboard Firmware"
                                secondary={state.billboardFirmware}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Billboard Hardware"
                                secondary={state.billboardHardware}
                            />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            <Box sx={{ mb: 1 }} component="div">
                <Typography variant="h4">Failures</Typography>
            </Box>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Name</TableCell>
                            <TableCell>Target Value</TableCell>
                            <TableCell>Actual Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {failures?.map((result) => (
                            <TestResultRow result={result} key={result.name} />
                        ))}
                    </TableBody>
                </Table>
            </Paper>
            <Box sx={{ mt: 4, mb: 1 }} component="div">
                <Typography variant="h4">Full Results</Typography>
            </Box>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Name</TableCell>
                            <TableCell>Target Value</TableCell>
                            <TableCell>Actual Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.results.map((result) => (
                            <TestResultRow result={result} key={result.name} />
                        ))}
                    </TableBody>
                </Table>
            </Paper>
            {state.failure && (
                <>
                    <Box sx={{ mt: 4, mb: 1 }} id="failure" component="div">
                        <Typography variant="h4">Failure</Typography>
                    </Box>
                    <Typography variant="body2">
                        <pre>{JSON.stringify(state.failure, null, 2)}</pre>
                    </Typography>
                </>
            )}
        </>
    );
}
