import * as React from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { formatDuration, intervalToDuration } from "date-fns";

interface UpdateEvent {
    name: string;
    startTimestamp: string;
    endTimestamp: string | null;
}

function UpdateEventIcon({
    name,
    endTimestamp,
    startTimestamp,
}: UpdateEvent): React.JSX.Element {
    if (name.startsWith("Error")) {
        return <ErrorIcon color="error" />;
    }

    if (startTimestamp && !endTimestamp) {
        return <CircularProgress size={25} />;
    }

    return <CheckCircleIcon color="success" />;
}

interface Props {
    log: UpdateEvent[];
}

export default function TrainerStatusUpdateEventLog({
    log,
}: Props): React.JSX.Element {
    return (
        <Table>
            <TableBody>
                {log.map((e) => (
                    <TableRow key={e.name}>
                        <TableCell size="small">
                            <UpdateEventIcon {...e} />
                        </TableCell>
                        <TableCell size="medium">{e.name}</TableCell>
                        <TableCell
                            size="small"
                            title={`${e.startTimestamp} – ${e.endTimestamp ?? ""}`}
                        >
                            {formatDuration(
                                intervalToDuration({
                                    start: new Date(e.startTimestamp),
                                    end: e.endTimestamp
                                        ? new Date(e.endTimestamp)
                                        : new Date(),
                                }),
                            ) || "< 1 second"}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
