import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

import { TagUpdatePayload, TagCreatePayload } from "@volley/data";

export interface TagEditProps {
    onClose: () => void;
    onFinish: (tag: TagCreatePayload | TagUpdatePayload) => void;
    onUpdate: (tag: TagCreatePayload | TagUpdatePayload) => void;
    tag: TagCreatePayload | TagUpdatePayload | null;
}

export default function TagEdit({
    onClose,
    onFinish,
    onUpdate,
    tag,
}: TagEditProps): React.JSX.Element | null {
    if (tag === null) {
        return null;
    }
    return (
        <Dialog
            open={tag !== null}
            onClose={onClose}
            PaperProps={{
                component: "form",
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    onFinish(tag);
                },
            }}
        >
            <DialogTitle>
                {(tag as TagUpdatePayload).id ? tag.label : "New Tag"}
            </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="name"
                    label="Name"
                    fullWidth
                    variant="standard"
                    value={tag.name}
                    onChange={(e) => onUpdate({ ...tag, name: e.target.value })}
                />
                <TextField
                    required
                    margin="dense"
                    id="label"
                    name="label"
                    label="Label"
                    fullWidth
                    variant="standard"
                    value={tag.label}
                    onChange={(e) =>
                        onUpdate({ ...tag, label: e.target.value })
                    }
                />
                <TextField
                    margin="dense"
                    id="description"
                    name="description"
                    label="Description"
                    fullWidth
                    variant="standard"
                    value={tag.description}
                    onChange={(e) =>
                        onUpdate({ ...tag, description: e.target.value })
                    }
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit">
                    {(tag as TagUpdatePayload).id ? "Update" : "Create"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
