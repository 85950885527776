import * as React from "react";

import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";

import type {
    OffsetResult,
    WorkoutPlayWithRelations,
    WorkoutThrow,
} from "@volley/data";
import * as convert from "@volley/physics/dist/conversions";
import { PhysicsModelName, Sports } from "@volley/physics/dist/models";
import type { PlayMode } from "@volley/shared/apps/app-common-models";

import logger from "../../../log";
import { fetchApi } from "../../../util";
import { mirrorTrainer } from "../../Trainer/Position/util";
import ResizableWorkoutVisualizer from "../../common/Visualizer/ResizableWorkoutVisualizer";
import type { WorkoutForVisualizer } from "../../common/Visualizer/types";

interface Props {
    workoutPlay: WorkoutPlayWithRelations;
}

function isMirrored(params: WorkoutPlayWithRelations["params"]): boolean {
    if (params && Object.hasOwn(params as unknown as object, "playMode")) {
        return (params as { playMode?: PlayMode }).playMode === "mirror";
    } else if (
        params &&
        Object.hasOwn(params as unknown as object, "servePosition")
    ) {
        return (params as { servePosition?: string }).servePosition === "ad";
    }

    return false;
}

export default function WorkoutPlayVisualizer({
    workoutPlay,
}: Props): React.JSX.Element {
    const sport = Sports[workoutPlay.workout.sportId - 1];
    const [action, setAction] = React.useState<"play" | "pause" | "stop">(
        "stop",
    );

    const [progress, setProgress] = React.useState({
        thrown: 0,
        total: workoutPlay.throwCount,
    });
    const [playbackSpeed, setPlaybackSpeed] = React.useState(1);

    const [throws, setThrows] = React.useState<WorkoutThrow[]>([]);
    React.useEffect(() => {
        async function fetchData() {
            const throwsData = await fetchApi<OffsetResult<WorkoutThrow>>(
                `/api/workout-plays/${workoutPlay.id}/throws?limit=500&offset=0&sortField=id&sortDirection=asc`,
            );
            setThrows(throwsData.result);
        }
        fetchData().catch((err: Error) => {
            logger.warn(err.message);
        });
    }, [workoutPlay.id]);

    React.useEffect(() => {
        convert.setPhysicsModel(
            workoutPlay.workout.physicsModelName as PhysicsModelName,
            sport,
        );
    }, [workoutPlay.workout.physicsModelName, sport]);

    const visualizerWorkout: WorkoutForVisualizer = React.useMemo(() => {
        const mirrored = isMirrored(workoutPlay.params);
        let trainer = {
            x: workoutPlay.workout.positionX,
            y: workoutPlay.workout.positionY,
            yaw: workoutPlay.workout.positionYaw,
            heightIn: workoutPlay.workout.positionHeight,
        };
        if (mirrored) {
            trainer = mirrorTrainer(
                {
                    x: workoutPlay.workout.positionX,
                    y: workoutPlay.workout.positionY,
                    yaw: workoutPlay.workout.positionYaw,
                    heightIn: workoutPlay.workout.positionHeight,
                },
                sport,
            );
        }
        let previousLaunchTime: number | null = null;
        const data: WorkoutForVisualizer = {
            shots: throws.map((t) => {
                const launchTimeStr = t.actualLaunchTime as unknown as
                    | string
                    | null;
                const launchTime = launchTimeStr
                    ? new Date(launchTimeStr).getTime()
                    : null;
                const speedSpin = convert.rpm2speedspin({
                    left: t.actualRpmLeft ?? 0,
                    right: t.actualRpmRight ?? 0,
                    top: t.actualRpmTop ?? 0,
                });
                const shot = {
                    launchSpeed: speedSpin.speed,
                    spinDirection: speedSpin.spinAxis ?? undefined,
                    spinLevel: speedSpin.spinLevel ?? undefined,
                    spinSpeed: speedSpin.spin ?? undefined,
                    pan: t.actualPan ?? 0,
                    tilt: t.actualTilt ?? 0,
                    delayBefore:
                        launchTime && previousLaunchTime
                            ? launchTime - previousLaunchTime
                            : undefined,
                };
                previousLaunchTime = launchTime;
                return shot;
            }),
            trainer,
            player: [],
        };
        return data;
    }, [
        throws,
        sport,
        workoutPlay.params,
        workoutPlay.workout.positionX,
        workoutPlay.workout.positionY,
        workoutPlay.workout.positionYaw,
        workoutPlay.workout.positionHeight,
    ]);

    const animationConfig = React.useMemo(
        () => ({
            action,
            playbackSpeed,
            onProgressChange: (value: { thrown: number; total: number }) => {
                setProgress(value);
            },
            onComplete: () => {
                setProgress({ thrown: 0, total: throws.length });
                setAction("stop");
            },
        }),
        [action, playbackSpeed, throws.length],
    );

    return (
        <Stack spacing={2}>
            <ResizableWorkoutVisualizer
                workout={visualizerWorkout}
                maxHeight={600}
                positionProximity="Good"
                sport={sport}
                physicsModel={
                    workoutPlay.workout.physicsModelName as PhysicsModelName
                }
                animationConfig={animationConfig}
            />
            <Box component="div" display="flex" alignItems="center">
                <Box component="div" width="100%" mr={1}>
                    <LinearProgress
                        variant="determinate"
                        value={
                            progress.total
                                ? (progress.thrown / progress.total) * 100
                                : 0
                        }
                    />
                </Box>
                <Box component="div" minWidth={70}>
                    <Typography variant="body2" color="text.secondary">
                        {`${progress.thrown} / ${progress.total}`}
                    </Typography>
                </Box>
            </Box>
            <Stack spacing={1} alignItems="center">
                <Box width={300} display="flex" justifyContent="center">
                    <ButtonGroup>
                        <IconButton
                            disabled={action === "stop"}
                            onClick={() => setAction("stop")}
                        >
                            <StopIcon />
                        </IconButton>
                        {action === "pause" || action === "stop" ? (
                            <IconButton onClick={() => setAction("play")}>
                                <PlayArrowIcon />
                            </IconButton>
                        ) : (
                            <IconButton onClick={() => setAction("pause")}>
                                <PauseIcon />
                            </IconButton>
                        )}
                    </ButtonGroup>
                </Box>
                <ToggleButtonGroup
                    value={playbackSpeed}
                    exclusive
                    onChange={(_e, value: number) => setPlaybackSpeed(value)}
                >
                    {[1, 1.5, 2, 4, 8].map((n) => (
                        <ToggleButton
                            value={n}
                            key={n}
                        >{`${String(n)}×`}</ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Stack>
        </Stack>
    );
}
