import * as React from "react";
import { Link as RouterLink, useLocation } from "react-router";

import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import HomeIcon from "@mui/icons-material/Home";
import ListIcon from "@mui/icons-material/List";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PeopleIcon from "@mui/icons-material/People";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import SummarizeIcon from "@mui/icons-material/Summarize";
import TimerIcon from "@mui/icons-material/Timer";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

const SECTION_REGEX = /\/admin\/([a-z-]+)\/?/;

export default function AdminListItems(): React.JSX.Element {
    const { pathname } = useLocation();
    const sectionMatches = SECTION_REGEX.exec(pathname);
    const section = sectionMatches ? sectionMatches[1] : "";

    return (
        <>
            <ListItemButton
                selected={pathname === "/admin"}
                component={RouterLink}
                to="/admin"
            >
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItemButton>
            <ListItemButton
                selected={section === "users"}
                component={RouterLink}
                to="/admin/users"
            >
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
            </ListItemButton>
            <ListItemButton
                selected={section === "locations"}
                component={RouterLink}
                to="/admin/locations"
            >
                <ListItemIcon>
                    <LocationOnIcon />
                </ListItemIcon>
                <ListItemText primary="Locations" />
            </ListItemButton>
            <ListItemButton
                selected={section === "trainers"}
                component={RouterLink}
                to="/admin/trainers"
            >
                <ListItemIcon>
                    <SportsTennisIcon />
                </ListItemIcon>
                <ListItemText primary="Trainers" />
            </ListItemButton>
            <ListItemButton
                selected={section === "sessions"}
                component={RouterLink}
                to="/admin/sessions"
            >
                <ListItemIcon>
                    <TimerIcon />
                </ListItemIcon>
                <ListItemText primary="Sessions" />
            </ListItemButton>
            <ListItemButton
                selected={section === "app-workouts"}
                component={RouterLink}
                to="/admin/app-workouts"
            >
                <ListItemIcon>
                    <ListIcon />
                </ListItemIcon>
                <ListItemText primary="App Workouts" />
            </ListItemButton>
            <ListItemButton
                selected={section === "app-workout-tags"}
                component={RouterLink}
                to="/admin/app-workout-tags"
            >
                <ListItemIcon>
                    <LocalOfferIcon />
                </ListItemIcon>
                <ListItemText primary="App Workout Tags" />
            </ListItemButton>
            <ListItemButton
                selected={section === "features"}
                component={RouterLink}
                to="/admin/features"
            >
                <ListItemIcon>
                    <AppSettingsAltIcon />
                </ListItemIcon>
                <ListItemText primary="App Features" />
            </ListItemButton>
            <ListItemButton
                selected={section === "content-providers"}
                component={RouterLink}
                to="/admin/content-providers"
            >
                <ListItemIcon>
                    <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Content Providers" />
            </ListItemButton>
            <ListItemButton
                selected={section === "workout-plays"}
                component={RouterLink}
                to="/admin/workout-plays"
            >
                <ListItemIcon>
                    <CheckBoxIcon />
                </ListItemIcon>
                <ListItemText primary="Workout Plays" />
            </ListItemButton>
            <ListItemButton
                selected={section === "reports"}
                component={RouterLink}
                to="/admin/reports"
            >
                <ListItemIcon>
                    <SummarizeIcon />
                </ListItemIcon>
                <ListItemText primary="Reports" />
            </ListItemButton>
            <ListItemButton
                selected={section === "test-results"}
                component={RouterLink}
                to="/admin/test-results"
            >
                <ListItemIcon>
                    <SummarizeIcon />
                </ListItemIcon>
                <ListItemText primary="Self Test Results" />
            </ListItemButton>
            <ListItemButton
                selected={section === "campaign-clubs"}
                component={RouterLink}
                to="/admin/campaign-clubs"
            >
                <ListItemIcon>
                    <GroupsIcon />
                </ListItemIcon>
                <ListItemText primary="Campaign Clubs" />
            </ListItemButton>
            <ListItemButton component={RouterLink} to="/">
                <ListItemIcon>
                    <HomeIcon />
                </ListItemIcon>
                <ListItemText>App Home</ListItemText>
            </ListItemButton>
        </>
    );
}
