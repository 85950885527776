import * as React from "react";
import { Link as RouterLink, useParams } from "react-router";

import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import type { VisionSettings } from "@volley/data";

import { usDateFormat } from "../../../util";
import fetchApi from "../../../util/fetchApi";

const defaultState: VisionSettings = {
    id: 0,
    data: "",
    description: "",
    trainerId: 0,
    workoutId: 0,
    createdAt: new Date(),
    createdBy: "",
    updatedAt: new Date(),
    updatedBy: "",
};

export default function VisionSettingsView(): React.JSX.Element {
    const { id } = useParams<"id">();
    const [state, setState] = React.useState(defaultState);

    async function fetchVisionSettings(settingsId: number) {
        const data = await fetchApi<VisionSettings>(
            `/api/vision-settings/${settingsId}`,
        );
        setState(data);
    }

    React.useEffect(() => {
        const validId = parseInt(id ?? "", 10);
        if (validId) {
            void fetchVisionSettings(validId);
        }
    }, [id]);

    const handleCopyClick = async (obj: NonNullable<unknown>) => {
        await navigator.clipboard.writeText(
            obj ? JSON.stringify(obj, undefined, 2) : "",
        );
    };

    return (
        <Grid container spacing={3}>
            <Grid size={12}>
                <Paper
                    sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        "& > :not(style)": { m: 1 },
                    }}
                >
                    <Grid container>
                        <Grid size={10}>
                            <Typography
                                component="h1"
                                variant="h2"
                                title={`ID: ${state.id}`}
                            >
                                Vision Settings
                            </Typography>
                        </Grid>
                        <Grid sx={{ textAlign: "right" }} size={2}>
                            <IconButton
                                size="large"
                                component={RouterLink}
                                to="../.."
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container component="dl" spacing={2}>
                        <Grid
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <Typography component="dt" variant="h6">
                                Trainer ID
                            </Typography>
                            <Typography component="dd" variant="body2">
                                <Link
                                    component={RouterLink}
                                    to={`../../trainers/${state.trainerId}`}
                                >
                                    {state.trainerId}
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <Typography component="dt" variant="h6">
                                Workout
                            </Typography>
                            <Typography component="dd" variant="body2">
                                {!!state.workoutId && (
                                    <Link
                                        component={RouterLink}
                                        to={`../../app-workouts/${state.workoutId}`}
                                    >
                                        {state.workoutId}
                                    </Link>
                                )}
                            </Typography>
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <Typography component="dt" variant="h6">
                                Timestamp
                            </Typography>
                            <Typography
                                component="dd"
                                variant="body2"
                                title={String(state.createdAt)}
                            >
                                {usDateFormat(new Date(state.createdAt))}
                            </Typography>
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <Typography component="dt" variant="h6">
                                Created By
                            </Typography>
                            <Typography component="dd" variant="body2">
                                {state.createdBy}
                            </Typography>
                        </Grid>
                        <Grid size={12}>
                            <Typography component="dt" variant="h6">
                                Settings
                                <IconButton
                                    size="small"
                                    sx={{ ml: 1 }}
                                    onClick={() =>
                                        handleCopyClick(
                                            state.data as NonNullable<unknown>,
                                        )
                                    }
                                    title="Copy Settings to Clipboard"
                                >
                                    <ContentCopyIcon fontSize="small" />
                                </IconButton>
                            </Typography>
                            <Typography component="dd" variant="body2">
                                <pre>
                                    {JSON.stringify(state.data, undefined, 2)}
                                </pre>
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}
