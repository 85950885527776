import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

interface DeleteConfirmationProps {
    name?: string;
    onClose: () => void;
    onConfirm: () => void;
}

export default function DeleteConfirmation({
    name,
    onClose,
    onConfirm,
}: DeleteConfirmationProps): React.JSX.Element {
    return (
        <Dialog open={name !== undefined}>
            <DialogTitle>Are You Sure?</DialogTitle>
            <DialogContent>
                {`Are you sure you want to delete ${name}? Once deleted, you can't recover it.`}
            </DialogContent>
            <DialogActions>
                <Stack direction="row">
                    <Box
                        component="div"
                        sx={{
                            flexBasis: "50%",
                            padding: "0 5px 0 5px",
                        }}
                    >
                        <Button
                            fullWidth
                            onClick={() => onClose()}
                            variant="contained"
                        >
                            Cancel
                        </Button>
                    </Box>
                    <Box
                        component="div"
                        sx={{
                            flexBasis: "50%",
                            padding: "0 5px 0 5px",
                        }}
                    >
                        <Button
                            fullWidth
                            onClick={() => onConfirm()}
                            variant="contained"
                        >
                            Delete
                        </Button>
                    </Box>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}
