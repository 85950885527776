import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";

interface Props {
    features: string[];
}

export default function UserFeatureList({
    features,
}: Props): React.JSX.Element {
    return (
        <Grid container spacing={2}>
            <Grid size={12}>
                <Typography variant="h2">App Features</Typography>
            </Grid>
            <Grid size={12}>
                {features.length > 0 && (
                    <List>
                        {features.map((f) => (
                            <ListItem key={f}>
                                <Typography variant="h5">{f}</Typography>
                            </ListItem>
                        ))}
                    </List>
                )}
                {features.length === 0 && (
                    <Box>
                        <Typography variant="h5">
                            No Additional Features Enabled
                        </Typography>
                        <Typography variant="body1">
                            Add roles from the dropdown list above to enable
                            features for this user.
                        </Typography>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
}
