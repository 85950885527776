import * as React from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

import type { CaptureModeConfig } from "@volley/shared/vision-models";

import { pairedFetchApi } from "../../../util/fetchApi";

interface Props {
    onError: (message: string) => void;
    trainerId: number;
}

export default function TrainerSettings({
    trainerId,
    onError,
}: Props): React.JSX.Element {
    const [captureModeChecked, setCaptureModeChecked] = React.useState(false);
    const [captureModeLoading, setCaptureModeLoading] = React.useState(true);

    const [demoModeChecked, setDemoModeChecked] = React.useState(false);
    const [demoModeLoading, setDemoModeLoading] = React.useState(true);

    React.useEffect(() => {
        const fetchData = async () => {
            const res: CaptureModeConfig = await pairedFetchApi(
                trainerId,
                "/api/apps/workouts/capturemode",
                "GET",
            );
            setCaptureModeChecked(res.mode === "video");
        };
        fetchData().catch(() => {
            onError("Error retrieving capture mode config");
        });

        setCaptureModeLoading(false);
    }, [trainerId, onError]);

    React.useEffect(() => {
        const fetchData = async () => {
            const res: { enabled: boolean } = await pairedFetchApi(
                trainerId,
                "/api/demo-mode",
                "GET",
            );
            setDemoModeChecked(res.enabled);
        };

        fetchData().catch(() => {
            onError("Error retrieving demo mode config");
        });

        setDemoModeLoading(false);
    }, [trainerId, onError]);

    const handleDemoModeChange = React.useCallback(async () => {
        try {
            const body = { enable: !demoModeChecked };

            setDemoModeLoading(true);
            await pairedFetchApi(trainerId, "/api/demo-mode", "POST", body);
            setDemoModeLoading(false);
            setDemoModeChecked(!demoModeChecked);
        } catch (err: unknown) {
            if (err instanceof Error) {
                onError(err.message);
            } else {
                onError("Error Setting the Demo Mode");
            }
        }
    }, [demoModeChecked, onError, trainerId]);

    const handleCaptureModeChange = React.useCallback(async () => {
        setCaptureModeChecked(!captureModeChecked);
        try {
            const body: CaptureModeConfig = {
                mode: captureModeChecked ? "shot" : "video",
                duration: captureModeChecked ? 5 : 30,
            };

            setCaptureModeLoading(true);
            await pairedFetchApi(
                trainerId,
                "/api/apps/workouts/capturemode",
                "POST",
                body,
            );
            setCaptureModeLoading(false);
        } catch (err: unknown) {
            if (err instanceof Error) {
                onError(err.message);
            } else {
                onError("Error Setting the Recorder Mode");
            }
        }
    }, [captureModeChecked, onError, trainerId]);

    return (
        <Stack>
            <Typography variant="h2">Trainer Settings</Typography>
            <Stack direction="row" spacing={2}>
                <FormControlLabel
                    control={
                        <Switch
                            disabled={captureModeLoading}
                            name="Debug Mode"
                            color="warning"
                            checked={captureModeChecked}
                            onChange={handleCaptureModeChange}
                        />
                    }
                    label="Record Full Video Mode"
                />
                <FormControlLabel
                    control={
                        <Switch
                            disabled={demoModeLoading}
                            name="Demo Mode"
                            color="warning"
                            checked={demoModeChecked}
                            onChange={handleDemoModeChange}
                        />
                    }
                    label="Demo Mode"
                />
            </Stack>
        </Stack>
    );
}
