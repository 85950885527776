import * as React from "react";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import WorkoutPlaysTable from "./WorkoutPlayTable";

export default function WorkoutPlaysPage(): React.JSX.Element {
    return (
        <Stack spacing={3}>
            <Typography component="h1" variant="h2">
                Workout Plays
            </Typography>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <WorkoutPlaysTable />
            </Paper>
        </Stack>
    );
}
