import * as React from "react";
import { Route, Routes, Link as RouterLink } from "react-router";

import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import VolleyV from "../common/icons/VolleyV";

import AdminDashboard from "./AdminDashboard";
import AdminListItems from "./AdminListItems";
import AppWorkoutEdit from "./AppWorkouts/AppWorkoutEdit";
import AppWorkoutsTable from "./AppWorkouts/AppWorkoutsTable";
import CampaignClubEdit from "./CampaignClubs/CampaignClubEdit";
import CampaignClubsTable from "./CampaignClubs/CampaignClubsTable";
import ClipView from "./Clips/ClipView";
import ContentProviderEdit from "./ContentProviders/ContentProviderEdit";
import ContentProvidersTable from "./ContentProviders/ContentProvidersTable";
import FailureView from "./Failures/FailureView";
import FeatureEdit from "./Features/FeatureEdit";
import FeaturesTable from "./Features/FeaturesTable";
import LocationEdit from "./Locations/LocationEdit";
import LocationsTable from "./Locations/LocationsTable";
import ReportsPage from "./Reports";
import SessionDetails from "./Sessions/SessionDetails";
import SessionsPage from "./Sessions/SessionsPage";
import TestReportTable from "./TestResults/TestReportTable";
import TestResultsPage from "./TestResults/TestReportView";
import TrainerClientIdRedirect from "./Trainers/TrainerClientIdRedirect";
import TrainerEdit from "./Trainers/TrainerEdit";
import TrainerEventStream from "./Trainers/TrainerEventStream";
import TrainersPage from "./Trainers/TrainersPage";
import UserEdit from "./Users/UserEdit";
import UserThinClient from "./Users/UserThinClient";
import UsersTable from "./Users/UsersTable";
import VisionSettingsView from "./VisionSettings/VisionSettingsView";
import WorkoutPlayDetails from "./WorkoutPlays/WorkoutPlayDetails";
import WorkoutPlaysPage from "./WorkoutPlays/WorkoutPlaysPage";
import WorkoutTagEdit from "./WorkoutTags/WorkoutTagEdit";
import WorkoutTagsTable from "./WorkoutTags/WorkoutTagsTable";

function Copyright(props: TypographyProps) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href="https://getvolley.com/">
                Volley LLC
            </Link>{" "}
            {new Date().getFullYear()}.
        </Typography>
    );
}

const Drawer = styled(MuiDrawer)(({ theme }) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        width: theme.spacing(7),
        boxSizing: "border-box",
        overflowX: "hidden",
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(25),
        },
    },
}));

const DRAWER_WIDTH = 240;

export default function Admin(): React.JSX.Element {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    return (
        <Box sx={{ display: { md: "flex" } }}>
            <AppBar
                position="fixed"
                sx={{
                    width: { md: `calc(100% - ${DRAWER_WIDTH}px)` },
                    ml: { md: `${DRAWER_WIDTH}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={() => setMobileOpen(!mobileOpen)}
                        sx={{ mr: 2, display: { sm: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h3" component="div" noWrap>
                        Admin
                    </Typography>
                    <Box component="div" flexGrow="1" />
                    <IconButton color="inherit" component={RouterLink} to="/">
                        <VolleyV />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={() => setMobileOpen(!mobileOpen)}
                sx={{
                    mb: 12,
                    width: DRAWER_WIDTH,
                    flexShrink: 0,
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: DRAWER_WIDTH,
                    },
                }}
                ModalProps={{ keepMounted: true }}
            >
                <Toolbar />
                <Divider />
                <List onClick={() => setMobileOpen(!mobileOpen)}>
                    <AdminListItems />
                </List>
            </Drawer>
            <Drawer
                variant="permanent"
                anchor="left"
                sx={{
                    width: DRAWER_WIDTH,
                    flexShrink: 0,
                    display: { xs: "none", sm: "block" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: DRAWER_WIDTH,
                    },
                }}
            >
                <Toolbar />
                <Divider />
                <List>
                    <AdminListItems />
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                }}
            >
                <Toolbar />
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Routes>
                        <Route path="" element={<AdminDashboard />} />
                        <Route path="campaign-clubs">
                            <Route path="" element={<CampaignClubsTable />} />
                            <Route path=":id" element={<CampaignClubEdit />} />
                        </Route>
                        <Route path="clips">
                            <Route path=":id" element={<ClipView />} />
                        </Route>
                        <Route path="content-providers">
                            <Route
                                path=""
                                element={<ContentProvidersTable />}
                            />
                            <Route
                                path=":id"
                                element={<ContentProviderEdit />}
                            />
                        </Route>
                        <Route path="failures">
                            <Route path=":id" element={<FailureView />} />
                        </Route>
                        <Route path="locations">
                            <Route path="" element={<LocationsTable />} />
                            <Route path=":id" element={<LocationEdit />} />
                        </Route>
                        <Route path="sessions">
                            <Route path="" element={<SessionsPage />} />
                            <Route path=":id" element={<SessionDetails />} />
                        </Route>
                        <Route path="trainers">
                            <Route path="" element={<TrainersPage />} />
                            <Route
                                path="clientId/:clientId"
                                element={<TrainerClientIdRedirect />}
                            />
                            <Route path=":id" element={<TrainerEdit />} />
                        </Route>
                        <Route path="trainer-event-streams">
                            <Route
                                path=":clientId"
                                element={<TrainerEventStream />}
                            />
                        </Route>
                        <Route path="users">
                            <Route path="" element={<UsersTable />} />
                            <Route path=":id" element={<UserEdit />} />
                            <Route
                                path=":id/thin-client"
                                element={<UserThinClient />}
                            />
                        </Route>
                        <Route path="features">
                            <Route path="" element={<FeaturesTable />} />
                            <Route path=":id" element={<FeatureEdit />} />
                        </Route>
                        <Route path="app-workouts">
                            <Route path="" element={<AppWorkoutsTable />} />
                            <Route path=":id" element={<AppWorkoutEdit />} />
                        </Route>
                        <Route path="app-workout-tags">
                            <Route path="" element={<WorkoutTagsTable />} />
                            <Route path=":id" element={<WorkoutTagEdit />} />
                        </Route>
                        <Route path="workout-plays">
                            <Route path="" element={<WorkoutPlaysPage />} />
                            <Route
                                path=":id"
                                element={<WorkoutPlayDetails />}
                            />
                        </Route>
                        <Route path="reports">
                            <Route path="" element={<ReportsPage />} />
                        </Route>
                        <Route path="test-results">
                            <Route path="" element={<TestReportTable />} />
                            <Route path=":id" element={<TestResultsPage />} />
                        </Route>
                        <Route path="vision-settings">
                            <Route
                                path=":id"
                                element={<VisionSettingsView />}
                            />
                        </Route>
                    </Routes>
                    <Copyright sx={{ pt: 4 }} />
                </Container>
            </Box>
        </Box>
    );
}
