import * as React from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router";

import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Typography from "@mui/material/Typography";

import type {
    AppWorkoutCreatePayload,
    AppWorkoutUpdatePayload,
    AppWorkoutWithSummary,
} from "@volley/data";
import { PhysicsModelName, Sports } from "@volley/physics/dist/models";
import type { CuratedWorkoutConfig } from "@volley/shared/dist/apps/curated-workout-models";
import type { SingleShotConfig } from "@volley/shared/dist/apps/single-shot-models";

import { round } from "../../../util";
import fetchApi, { logFetchError } from "../../../util/fetchApi";
import ResizableWorkoutVisualizer from "../../common/Visualizer/ResizableWorkoutVisualizer";
import { workoutToVisualizer } from "../../common/Visualizer/utils";
import { useCurrentUser } from "../../hooks/currentUser";
import AdminLoading from "../AdminLoading";
import AdminSaveButton, { SaveState } from "../AdminSaveButton";
import VisionSettingsTable from "../VisionSettings/VisionSettingsTable";

import CollaboratorsDialog from "./CollaboratorsDialog";

export type AppWorkoutState = "BUILD" | "RELEASE" | "TEST" | "SOFT_DELETE";
export type AppWorkoutShotType = "LOB" | "SERVE" | "VOLLEY" | "SCREEN";
export type AppWorkoutDifficulty =
    | "BEGINNER"
    | "INTERMEDIATE"
    | "ADVANCED"
    | "PRO";

const publishStateItems = [
    { value: "BUILD", label: "Building" },
    { value: "TEST", label: "Ready to Test" },
    { value: "RELEASE", label: "Ready for Players" },
    { value: "SOFT_DELETE", label: "Hidden" },
];

interface Form {
    name: string;
    appName: string;
    description: string;
    overview: string;
    sportName: string;
    state: AppWorkoutState;
}

type Action =
    | { type: "name"; value: string }
    | { type: "appName"; value: string }
    | { type: "description"; value: string }
    | { type: "overview"; value: string }
    | { type: "state"; value: AppWorkoutState }
    | { type: "set"; value: AppWorkoutWithSummary };

function reducer(state: Form, action: Action): Form {
    switch (action.type) {
        case "name":
            return { ...state, name: action.value };
        case "appName":
            return { ...state, appName: action.value };
        case "description":
            return { ...state, description: action.value };
        case "overview":
            return { ...state, overview: action.value };
        case "state":
            return { ...state, state: action.value };
        case "set":
            return {
                name: action.value.name,
                appName: action.value.appName,
                description: action.value.description,
                overview: action.value.overview,
                sportName: action.value.sport.name,
                state: action.value.state,
            };
        default:
            throw new Error("Invalid form action type");
    }
}

const defaultState: Form = {
    name: "",
    appName: "",
    description: "",
    overview: "",
    sportName: "PLATFORM_TENNIS",
    state: "BUILD",
};

export default function AppWorkoutEdit(): React.JSX.Element {
    const { id } = useParams<"id">();
    const { currentUser } = useCurrentUser();
    const navigate = useNavigate();
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    const [workout, setWorkout] = React.useState<AppWorkoutWithSummary | null>(
        null,
    );
    const [saveState, setSaveState] = React.useState(SaveState.Unsaved);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [collaboratorsOpen, setCollaboratorsOpen] = React.useState(false);

    React.useEffect(() => {
        async function fetchWorkout(workoutId: number) {
            const data = await fetchApi<AppWorkoutWithSummary>(
                `/api/app-workouts/${workoutId}`,
            );
            setWorkout(data);
            dispatch({ type: "set", value: data });
        }

        const validId = parseInt(id ?? "", 10);
        if (validId) {
            fetchWorkout(validId).catch((e: Error) =>
                setErrorMessage(e.message),
            );
        }
    }, [id]);

    const onSubmit = React.useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (!workout) return;
            setSaveState(SaveState.Saving);
            const payload: AppWorkoutUpdatePayload = {
                id: workout.id,
                name: state.name.trim(),
                description: state.description.trim(),
                appName: state.appName,
                state: state.state,
                sport: { name: state.sportName },
                appId: workout.appId,
                extendedData: workout.extendedData ?? {},
                config: workout.config,
                overview: workout.overview,
                positionHeight: workout.positionHeight,
                positionX: workout.positionX,
                positionY: workout.positionY,
                positionYaw: workout.positionYaw,
                contentProviderId: null,
                copiedFromAppWorkoutId: workout.copiedFromAppWorkoutId,
                physicsModelName: workout.physicsModelName,
            };
            try {
                await fetchApi(
                    `/api/app-workouts/${workout.id}`,
                    "PUT",
                    payload,
                );
                setSaveState(SaveState.Saved);
            } catch {
                setSaveState(SaveState.Unsaved);
            }
        },
        [state, workout],
    );

    const onDelete = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            if (!workout) return;

            const sure = window.confirm(
                `Are you sure you want to delete the workout '${workout.name}'?`,
            );
            if (sure) {
                await fetchApi(`/api/app-workouts/${workout.id}`, "DELETE");
                navigate("..", { replace: true });
            }
        },
        [navigate, workout],
    );

    const handleDuplicate = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            if (!workout) return;

            const copyName = `[ADMIN COPY] ${workout.name}`;

            const sure = window.confirm(
                `Are you sure you want a copy of the workout added to your library as '${copyName}'?`,
            );
            if (sure) {
                let originalAppId = workout.appId;
                if (originalAppId === 7) {
                    try {
                        originalAppId = await fetchApi<number>(
                            `/api/app-workouts/original/${workout.id}`,
                        );
                    } catch (ex) {
                        logFetchError(
                            ex,
                            `Error fetching original app id for ${workout.id}`,
                        );
                        window.alert(
                            "Unable to copy the workout at this time.",
                        );
                        return;
                    }
                }
                const copy: AppWorkoutCreatePayload = {
                    appId: originalAppId,
                    appName: workout.appName,
                    config: workout.config,
                    contentProviderId: null,
                    copiedFromAppWorkoutId: workout.id,
                    description: workout.description,
                    extendedData: workout.extendedData,
                    overview: workout.overview,
                    physicsModelName: workout.physicsModelName,
                    positionHeight: workout.positionHeight,
                    positionX: workout.positionX,
                    positionY: workout.positionY,
                    positionYaw: workout.positionYaw,
                    sport: { name: workout.sport.name },
                    state: workout.state,
                    name: copyName,
                };
                const saved = await fetchApi<AppWorkoutWithSummary>(
                    "/api/app-workouts",
                    "POST",
                    copy,
                );
                if (!saved) {
                    window.alert("Unable to copy the workout at this time.");
                    return;
                }
                const navaway = window.confirm(
                    `Workout '${copyName}' copied successfully. Would you like to view it now?`,
                );
                if (navaway) {
                    navigate(
                        `/content/apps/workouts/plugin/play/${saved.appId}/${saved.id}`,
                        { replace: true },
                    );
                }
            }
        },
        [navigate, workout],
    );

    const shots =
        React.useMemo(() => {
            if (!workout) return [];
            // The only workouts that have shots are freeplay and curated
            if (workout.appId === 4) {
                const { config } = workout;
                return (config as unknown as CuratedWorkoutConfig).shots;
            }

            if (workout.appId === 1 || workout.appId === 5) {
                const { config } = workout;
                const shotMaybe = (config as unknown as SingleShotConfig).shot;

                if (shotMaybe) {
                    return [shotMaybe];
                }

                return [];
            }

            return [];
        }, [workout]) ?? [];

    const title = React.useMemo(() => {
        if (!workout) return null;
        if (workout.contentProvider) {
            return `${workout.name} (${workout.contentProvider.name})`;
        }
        return workout.name;
    }, [workout]);

    const visualizerWorkout = React.useMemo(() => {
        if (!workout) return undefined;
        return workoutToVisualizer(
            workout,
            "standard",
            Sports[workout.sportId - 1],
        );
    }, [workout]);

    if (errorMessage.indexOf("Not Found") > -1) {
        return (
            <Box display="flex" flexDirection="column" alignItems="center">
                <Typography color="error" gutterBottom>
                    {errorMessage}
                </Typography>
                <Box>
                    <Link component={RouterLink} to="..">
                        Go back
                    </Link>
                </Box>
            </Box>
        );
    }

    if (!workout) {
        return <AdminLoading page="App Workout" />;
    }

    return (
        <Grid container spacing={3}>
            <Grid size={12}>
                <Paper
                    sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        "& > :not(style)": { m: 1 },
                    }}
                    component="form"
                    autoComplete="off"
                    onSubmit={onSubmit}
                >
                    <Grid container>
                        <Grid size={10}>
                            <Typography
                                component="h1"
                                variant="h2"
                                title={`ID: ${workout.id}`}
                            >
                                {title}
                            </Typography>
                        </Grid>
                        <Grid sx={{ textAlign: "right" }} size={2}>
                            <IconButton
                                size="large"
                                component={RouterLink}
                                to=".."
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {errorMessage && (
                        <Typography color="error.main">
                            {errorMessage}
                        </Typography>
                    )}
                    <Grid container spacing={2} sx={{ pr: 4 }}>
                        <Grid
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <TextField
                                id="name"
                                label="Name"
                                value={state.name}
                                onChange={(e) =>
                                    dispatch({
                                        type: "name",
                                        value: e.currentTarget.value,
                                    })
                                }
                                fullWidth
                            />
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <TextField
                                id="appName"
                                label="App Name"
                                value={state.appName}
                                onChange={(e) =>
                                    dispatch({
                                        type: "appName",
                                        value: e.currentTarget.value,
                                    })
                                }
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid size={12}>
                            <TextField
                                id="overview"
                                label="Overview"
                                fullWidth
                                multiline
                                minRows={2}
                                maxRows={2}
                                value={state.overview}
                                onChange={(e) =>
                                    dispatch({
                                        type: "overview",
                                        value: e.currentTarget.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid size={12}>
                            <TextField
                                id="description"
                                label="Description"
                                fullWidth
                                multiline
                                minRows={3}
                                maxRows={3}
                                value={state.description}
                                onChange={(e) =>
                                    dispatch({
                                        type: "description",
                                        value: e.currentTarget.value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <FormControl fullWidth>
                                <TextField
                                    id="sportId"
                                    disabled
                                    fullWidth
                                    label="Sport"
                                    value={state.sportName}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <FormControl fullWidth>
                                <InputLabel>Visibility</InputLabel>
                                <Select
                                    id="appVisibility"
                                    fullWidth
                                    label="Visibility"
                                    value={state.state}
                                    disabled={workout.appId === 1}
                                    onChange={(e) =>
                                        dispatch({
                                            type: "state",
                                            value: e.target
                                                .value as AppWorkoutState,
                                        })
                                    }
                                >
                                    {publishStateItems.map((opt) => (
                                        <MenuItem
                                            key={opt.value}
                                            value={opt.value}
                                        >
                                            {opt.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid size={12}>
                            <Typography variant="h3">
                                Associated Tags
                            </Typography>
                        </Grid>
                        <Grid size={12}>
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                flexWrap="wrap"
                            >
                                {workout.workoutTags.map((tag) => (
                                    <Chip
                                        key={tag.tag.id}
                                        variant="outlined"
                                        label={`${tag.tag.tagCategory.label}: ${tag.tag.label}`}
                                    />
                                ))}
                                {workout.workoutTags.length === 0 && (
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        No Tags Applied
                                    </Typography>
                                )}
                            </Stack>
                        </Grid>
                        <Grid size={12}>
                            <Typography component="h2" variant="h3">
                                Position
                            </Typography>
                        </Grid>
                        <Grid
                            size={{
                                xs: 6,
                                md: 3,
                            }}
                        >
                            <TextField
                                type="number"
                                id="positionHeight"
                                label="Height (inches)"
                                fullWidth
                                value={workout.positionHeight.toFixed(2)}
                                disabled
                                slotProps={{
                                    htmlInput: { min: 0, max: 120 },
                                }}
                            />
                        </Grid>
                        <Grid
                            size={{
                                xs: 6,
                                md: 3,
                            }}
                        >
                            <TextField
                                type="number"
                                id="positionX"
                                label="X (mm)"
                                fullWidth
                                value={workout.positionX.toFixed(2)}
                                disabled
                            />
                        </Grid>
                        <Grid
                            size={{
                                xs: 6,
                                md: 3,
                            }}
                        >
                            <TextField
                                type="number"
                                id="positionY"
                                label="Y (mm)"
                                fullWidth
                                value={workout.positionY.toFixed(2)}
                                disabled
                            />
                        </Grid>
                        <Grid
                            size={{
                                xs: 6,
                                md: 3,
                            }}
                        >
                            <TextField
                                type="number"
                                id="positionYaw"
                                label="Yaw (deg)"
                                fullWidth
                                value={(
                                    workout.positionYaw *
                                    (180 / Math.PI)
                                ).toFixed(2)}
                                disabled
                                slotProps={{
                                    htmlInput: { min: -40, max: 40 },
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Stack spacing={2} direction="row">
                            <Button
                                variant="contained"
                                onClick={() => setCollaboratorsOpen(true)}
                            >
                                Manage Collaborators
                            </Button>
                            {workout.createdBy !== currentUser?.email && (
                                <Button
                                    variant="contained"
                                    onClick={handleDuplicate}
                                >
                                    Copy to My Workouts
                                </Button>
                            )}
                            <AdminSaveButton
                                type="submit"
                                variant="contained"
                                saveState={saveState}
                            />
                            <Button component={RouterLink} to="..">
                                Cancel
                            </Button>
                            {workout.state !== "SOFT_DELETE" && (
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={onDelete}
                                >
                                    Delete
                                </Button>
                            )}
                        </Stack>
                    </Box>
                    {visualizerWorkout && (
                        <ResizableWorkoutVisualizer
                            workout={visualizerWorkout}
                            maxHeight={600}
                            positionProximity="Good"
                            sport={Sports[workout.sportId - 1]}
                            physicsModel={
                                workout.physicsModelName as PhysicsModelName
                            }
                        />
                    )}
                    {shots.length > 0 && (
                        <Grid container spacing={2} sx={{ pr: 4 }}>
                            <Grid size={12}>
                                <Typography component="h2" variant="h3">
                                    Shots
                                </Typography>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Pan</TableCell>
                                                <TableCell>Tilt</TableCell>
                                                <TableCell>Speed</TableCell>
                                                <TableCell>Spin</TableCell>
                                                <TableCell>SpinLevel</TableCell>
                                                <TableCell>
                                                    Spin Direction
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {shots.map((shot) => (
                                                <TableRow key={shot.id}>
                                                    <TableCell>
                                                        User Configured
                                                    </TableCell>
                                                    <TableCell>
                                                        {`${shot.pan}°`}
                                                    </TableCell>
                                                    <TableCell>
                                                        {`${shot.tilt}°`}
                                                    </TableCell>
                                                    <TableCell>
                                                        {`${round(shot.launchSpeed, 4)}mps`}
                                                    </TableCell>
                                                    <TableCell>
                                                        {`${shot.spinSpeed ?? "null "}rpm`}
                                                    </TableCell>
                                                    <TableCell>
                                                        {`${shot.spinLevel ?? "null "}`}
                                                    </TableCell>
                                                    <TableCell>
                                                        {`${shot.spinDirection}°`}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container>
                        <Grid size={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography variant="h3">
                                        Workout Configuration (Raw JSON)
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack spacing={2}>
                                        <Stack spacing={2}>
                                            <Typography variant="h4">
                                                Config
                                            </Typography>
                                            <TextareaAutosize
                                                value={JSON.stringify(
                                                    workout.config,
                                                    null,
                                                    4,
                                                )}
                                                style={{
                                                    width: "100%",
                                                    border: "none",
                                                }}
                                            />
                                        </Stack>
                                        {workout.extendedData && (
                                            <Stack spacing={2}>
                                                <Typography variant="h4">
                                                    Extended Data
                                                </Typography>
                                                <TextareaAutosize
                                                    value={JSON.stringify(
                                                        workout.extendedData,
                                                        null,
                                                        4,
                                                    )}
                                                    style={{
                                                        width: "100%",
                                                        border: "none",
                                                    }}
                                                />
                                            </Stack>
                                        )}
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography variant="h3">
                                        Vision Settings
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack spacing={2}>
                                        <VisionSettingsTable
                                            entity="workout"
                                            id={workout.id}
                                        />
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <CollaboratorsDialog
                open={collaboratorsOpen}
                onClose={() => setCollaboratorsOpen(false)}
                workout={workout}
            />
        </Grid>
    );
}
