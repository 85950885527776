import * as React from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router";

import CloseIcon from "@mui/icons-material/Close";
import StreamIcon from "@mui/icons-material/Stream";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
    type OffsetResult,
    type LocationWithRelations,
    type SoftwareTagWithRelations,
    type TrainerCreatePayload,
    type TrainerModel,
    type TrainerStatusSnapshot,
    type OutOfOrderResponse,
    Platform,
} from "@volley/data";
import type { CoachStatus } from "@volley/shared/coach-models";

import { usDateFormat } from "../../../util";
import fetchApi, {
    logFetchError,
    pairedFetchApi,
} from "../../../util/fetchApi";
import AlertDialog from "../../Dialog/common/ConfirmDialog";
import LocationAutocomplete from "../../common/LocationAutocomplete";
import useInterval from "../../hooks/useInterval";
import AdminLoading from "../AdminLoading";
import AdminSaveButton, { SaveState } from "../AdminSaveButton";
import FailuresTable from "../Failures/FailuresTable";
import IssueReportTable from "../IssueReportTable";
import SessionsTable from "../Sessions/SessionsTable";
import SnapshotsGrid from "../Snapshots/SnapshotsGrid";
import TestReportTable from "../TestResults/TestReportTable";
import VisionSettingsTable from "../VisionSettings/VisionSettingsTable";

import TrainerEditControls from "./TrainerEditControls";
import TrainerEditMute from "./TrainerEditMute";
import TrainerNetworkConfigure from "./TrainerNetworkConfigure";
import TrainerSelfTestControls from "./TrainerSelfTestControls";
import TrainerSettings from "./TrainerSettings";
import TrainerSoftwareEdit from "./TrainerSoftwareEdit";
import TrainerStatsView from "./TrainerStatsView";
import TrainerStatusDetails from "./TrainerStatusDetails";
import TrainerStatusIcon from "./TrainerStatusIcon";
import TrainerStatusSnapshotDetails from "./TrainerStatusSnapshotDetails";
import TrainerUpdateDownloadStatus from "./TrainerUpdateDownloadStatus";
import { FormState, TrainerForm, TrainerWithStatus } from "./types";

type SoftwareTagActive = SoftwareTagWithRelations & { active: boolean };

type TrainerState = TrainerWithStatus & {
    controlTags: SoftwareTagActive[];
    visionTags: SoftwareTagActive[];
};

const AVAILABILITIES = [
    "Contracted",
    "Partner",
    "Trial",
    "Available (field)",
    "Available",
    "Needs Repair",
    "In House/Admin",
];

type Action =
    | { type: "name"; value: string }
    | { type: "clientId"; value: number }
    | { type: "referenceNumber"; value: string }
    | { type: "modelId"; value: number | null }
    | { type: "availability"; value: string | null }
    | { type: "notes"; value: string }
    | { type: "mutedUntil"; value: Date | null }
    | { type: "locationId"; value: number }
    | { type: "controlPlatformId"; value: string }
    | { type: "visionPlatformId"; value: string }
    | { type: "controlTagIds"; value: number[] }
    | { type: "activeControlTagId"; value: number | null }
    | { type: "activeVisionTagId"; value: number | null }
    | {
          type: "statuses";
          value: {
              status: CoachStatus | null;
              statusSnapshot: TrainerStatusSnapshot | null;
          };
      }
    | { type: "set"; value: TrainerState }
    | { type: "loading"; value: boolean }
    | { type: "saveState"; value: SaveState }
    | { type: "errorMessage"; value: string | null };

function reducer(state: TrainerForm, { type, value }: Action): TrainerForm {
    let error: string | undefined;
    switch (type) {
        case "name": {
            if (!value || value.length < 3) {
                error = "Must be at least 3 characters";
            }
            return { ...state, name: { value, error } };
        }
        case "clientId": {
            if (Number.isNaN(value) || value < 1) {
                error = "Must be a valid number";
            }
            return { ...state, clientId: { value, error } };
        }
        case "referenceNumber":
            return { ...state, referenceNumber: { value, error } };
        case "modelId":
            return { ...state, modelId: { value, error } };
        case "availability":
            return { ...state, availability: { value, error } };
        case "notes":
            return { ...state, notes: { value, error } };
        case "mutedUntil":
            return { ...state, mutedUntil: { value, error } };
        case "locationId": {
            if (!value) {
                error = "Please select a location";
            }
            return { ...state, locationId: { value, error } };
        }
        case "controlPlatformId":
            return { ...state, controlPlatformId: { value, error } };
        case "visionPlatformId":
            return { ...state, visionPlatformId: { value, error } };
        case "controlTagIds": {
            if (value.length === 0) {
                error = "At least one must be selected";
            }
            return {
                ...state,
                controlTagIds: { value, error },
                activeControlTagId: {
                    value: state.activeControlTagId.value || value[0],
                },
            };
        }
        case "activeControlTagId": {
            if (!value) {
                error = "Select an active software tag";
            } else if (
                !state.controlTagIds.value.filter((tagId) => tagId === value)
                    .length
            ) {
                error = "Invalid software tag selected";
            }
            return { ...state, activeControlTagId: { value, error } };
        }
        case "activeVisionTagId": {
            if (!value) {
                error = "Select a vision software tag";
            }
            return { ...state, activeVisionTagId: { value, error } };
        }
        case "statuses": {
            return {
                ...state,
                status: value.status,
                statusSnapshot: value.statusSnapshot,
            };
        }
        case "set": {
            const trainer: TrainerState = value;
            const mutedUntil = trainer.mutedUntil
                ? new Date(trainer.mutedUntil)
                : null;
            return {
                ...state,
                name: { value: trainer.name },
                clientId: { value: trainer.clientId },
                referenceNumber: { value: trainer.referenceNumber ?? "" },
                modelId: { value: trainer.trainerModelId },
                notes: { value: trainer.notes ?? "" },
                mutedUntil: {
                    value:
                        mutedUntil && mutedUntil > new Date()
                            ? mutedUntil
                            : null,
                },
                locationId: { value: trainer.locationId },
                availability: { value: trainer.availability },
                controlPlatformId: { value: trainer.controlPlatformId },
                visionPlatformId: { value: trainer.visionPlatformId },
                controlTagIds: { value: trainer.controlTags.map((t) => t.id) },
                activeControlTagId: {
                    value:
                        trainer.controlTags.find((t) => t.active)?.id ?? null,
                },
                activeVisionTagId: {
                    value: trainer.visionTags.find((t) => t.active)?.id ?? null,
                },
                status: trainer.status,
                statusSnapshot: trainer.statusSnapshot,
                deletedAt: trainer.deletedAt
                    ? new Date(trainer.deletedAt)
                    : null,
                updatedBy: trainer.updatedBy,
            };
        }
        case "loading":
            return { ...state, loading: value };
        case "saveState":
            return { ...state, saveState: value };
        case "errorMessage":
            return {
                ...state,
                errorMessage: value,
                saveState: SaveState.Error,
            };
        default:
            throw new Error("Invalid form action type");
    }
}

const defaultForm: TrainerForm = {
    name: { value: "" },
    clientId: { value: 0 },
    referenceNumber: { value: "" },
    modelId: { value: 0 },
    notes: { value: "" },
    mutedUntil: { value: null },
    locationId: { value: 0 },
    availability: { value: null },
    controlPlatformId: { value: "4" }, // Atom Raspberry Pi CM4
    visionPlatformId: { value: "5" }, // Atom Neousys AGX
    controlTagIds: { value: [] },
    activeControlTagId: { value: null },
    activeVisionTagId: { value: 0 },
    deletedAt: null,
    updatedBy: "",
    status: null,
    statusSnapshot: null,
    loading: true,
    saveState: SaveState.Unsaved,
    errorMessage: null,
};

function statusRefreshTime(status: CoachStatus | null): number | null {
    if (status?.update) {
        return 2_000;
    }

    if (status) {
        return 10_000;
    }

    return null;
}

export default function TrainerEdit(): React.JSX.Element {
    const { id: idString } = useParams<"id">();
    const id = parseInt(idString ?? "", 10);
    const isNew = !id;
    const navigate = useNavigate();

    // Select options
    const [models, setModels] = React.useState<TrainerModel[]>([]);
    const [controlPlatforms, setControlPlatforms] = React.useState<Platform[]>(
        [],
    );
    const [visionPlatforms, setVisionPlatforms] = React.useState<Platform[]>(
        [],
    );

    // Form and validation state
    const [form, dispatch] = React.useReducer(reducer, defaultForm);
    const { status, statusSnapshot } = form;
    const [showSessions, setShowSessions] = React.useState(false);
    const [showFailures, setShowFailures] = React.useState(false);
    const [showTestReports, setShowTestReports] = React.useState(false);
    const [showVisionSettings, setShowVisionSettings] = React.useState(false);
    const [showIssueReport, setShowIssueReport] = React.useState(false);
    const disabled = !!form.deletedAt;
    const [showSettings, setShowSettings] = React.useState(false);

    // Out of order state
    const [outOfOrderOpen, setOutOfOrderOpen] = React.useState<
        boolean | undefined
    >(undefined);
    const [outOfOrderAlert, setOutOfOrderAlert] = React.useState(false);
    const [outOfOrderBy, setOutOfOrderBy] = React.useState<string | null>(null);
    const [outOfOrderAt, setOutOfOrderAt] = React.useState<Date | null>(null);
    const [outOfOrderSubmitError, setOutOfOrderSubmitError] = React.useState<
        string | null
    >(null);

    const theme = useTheme();
    const desktopMatch = useMediaQuery(theme.breakpoints.up("md"));

    const refreshStatus = React.useCallback(async () => {
        if (!id) return;
        const data = await fetchApi<TrainerWithStatus>(`/api/trainers/${id}`);
        dispatch({
            type: "statuses",
            value: { status: data.status, statusSnapshot: data.statusSnapshot },
        });
    }, [id]);

    const onError = React.useCallback((value: string) => {
        dispatch({ type: "errorMessage", value });
    }, []);

    useInterval(() => {
        refreshStatus().catch(() => {});
    }, statusRefreshTime(status));

    React.useEffect(() => {
        async function fetchTrainer() {
            if (!id) {
                dispatch({ type: "loading", value: false });
                return;
            }
            const trainerData = await fetchApi<TrainerWithStatus>(
                `/api/trainers/${id}`,
            );
            const [controlTagsData, visionTagsData] = await Promise.all([
                fetchApi<SoftwareTagActive[]>(
                    `/api/trainers/${id}/software-tags/${trainerData.controlPlatformId}`,
                ),
                fetchApi<SoftwareTagActive[]>(
                    `/api/trainers/${id}/software-tags/${trainerData.visionPlatformId}`,
                ),
            ]);
            dispatch({
                type: "set",
                value: {
                    ...trainerData,
                    controlTags: controlTagsData,
                    visionTags: visionTagsData,
                },
            });
            dispatch({ type: "loading", value: false });
            setOutOfOrderOpen(!!trainerData.outOfOrderBy);
            setOutOfOrderBy(trainerData.outOfOrderBy);
            setOutOfOrderAt(
                trainerData.outOfOrderAt
                    ? new Date(trainerData.outOfOrderAt)
                    : null,
            );
        }

        fetchTrainer().catch((error: Error) => {
            dispatch({ type: "loading", value: false });
            dispatch({
                type: "errorMessage",
                value: `Error fetching trainer: ${error.message}`,
            });
        });
    }, [id]);

    React.useEffect(() => {
        async function fetchData() {
            if (status?.clientId) {
                const show = await pairedFetchApi<string[]>(
                    status.clientId,
                    "/api/features",
                );
                setShowSettings(show.includes("debugRecorder"));
            }
        }

        fetchData().catch(() => {
            dispatch({
                type: "errorMessage",
                value: "Error fetching features",
            });
        });
    }, [status?.clientId]);

    React.useEffect(() => {
        async function fetchData() {
            const [
                trainerModelsData,
                controlPlatformsData,
                visionPlatformsData,
            ] = await Promise.all([
                fetchApi<OffsetResult<TrainerModel>>(
                    "/api/trainers/models?limit=100&offset=0",
                ),
                fetchApi<Platform[]>("/api/trainers/platforms?type=control"),
                fetchApi<Platform[]>("/api/trainers/platforms?type=vision"),
            ]);
            setModels(trainerModelsData.result);
            setControlPlatforms(controlPlatformsData);
            setVisionPlatforms(visionPlatformsData);
        }

        fetchData().catch((error: Error) => {
            dispatch({
                type: "errorMessage",
                value: `Error fetching data: ${error.message}`,
            });
        });
    }, []);

    const onClickRefreshStatus = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            await refreshStatus();
        },
        [refreshStatus],
    );

    const onSubmit = React.useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            dispatch({ type: "errorMessage", value: null });

            const errors = Object.values(form).filter(
                (v) =>
                    typeof v === "object" &&
                    v !== null &&
                    (v as FormState<unknown>).error,
            );
            if (errors.length) {
                dispatch({ type: "saveState", value: SaveState.Error });
                return;
            }

            dispatch({ type: "saveState", value: SaveState.Saving });
            const softwareTagTrainers = form.controlTagIds.value.map(
                (softwareTagId) => ({
                    softwareTagId,
                    active: softwareTagId === form.activeControlTagId.value,
                }),
            );
            if (form.activeVisionTagId.value) {
                softwareTagTrainers.push({
                    softwareTagId: form.activeVisionTagId.value,
                    active: true,
                });
            }
            const payload: TrainerCreatePayload = {
                clientId: form.clientId.value,
                referenceNumber: form.referenceNumber.value?.trim() || null,
                trainerModel: form.modelId.value
                    ? { id: form.modelId.value }
                    : null,
                notes: form.notes.value?.trim() || null,
                name: form.name.value.trim(),
                location: { id: form.locationId.value },
                availability: form.availability.value,
                controlPlatform: { id: form.controlPlatformId.value },
                visionPlatform: { id: form.visionPlatformId.value },
                softwareTagTrainers,
            };
            let result: TrainerWithStatus;
            try {
                if (id) {
                    result = await fetchApi(`/api/trainers/${id}`, "PUT", {
                        ...payload,
                        id,
                    });
                } else {
                    result = await fetchApi("/api/trainers", "POST", payload);
                }
                dispatch({ type: "saveState", value: SaveState.Saved });
                navigate(`../${result.id.toString()}`, { replace: true });
            } catch (error: unknown) {
                dispatch({
                    type: "errorMessage",
                    value: (error as Error).message,
                });
            }
        },
        [navigate, id, form],
    );

    const onDeactivate = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            const sure = window.confirm(
                `Are you sure you want to deactivate trainer #${form.clientId.value}? ` +
                    "This will prevent the trainer from operating and cannot be undone without Volley support intervention.",
            );
            if (sure && id) {
                await fetchApi(`/api/trainers/${id}`, "DELETE");
                navigate("../", { replace: true });
            }
        },
        [navigate, id, form.clientId.value],
    );

    const setActiveControlTagId = React.useCallback(
        (value: number | null) =>
            dispatch({ type: "activeControlTagId", value }),
        [],
    );

    const setActiveVisionTagId = React.useCallback(
        (value: number | null) =>
            dispatch({ type: "activeVisionTagId", value }),
        [],
    );

    const showErrors = form.saveState === SaveState.Error;

    const handleOutOfOrderSubmit = () => {
        const outOfOrder = !outOfOrderOpen;
        fetchApi<OutOfOrderResponse>(
            `/api/trainers/${id}/out-of-order`,
            "POST",
            { outOfOrder },
        )
            .then((res) => {
                setOutOfOrderAlert(false);
                setOutOfOrderOpen(outOfOrder);
                setOutOfOrderSubmitError(null);
                setOutOfOrderBy(res.outOfOrderBy);
                setOutOfOrderAt(
                    res.outOfOrderAt ? new Date(res.outOfOrderAt) : null,
                );
            })
            .catch((error) => {
                setOutOfOrderSubmitError(
                    error instanceof Error ? error.message : "error",
                );
                logFetchError(
                    error,
                    `POST /api/trainers/${id}/out-of-order failed setting outOfOrder=${outOfOrder.toString()}`,
                );
            });
    };

    if (form.loading) {
        return <AdminLoading page="Trainer" />;
    }

    return (
        <Stack spacing={2}>
            <Paper
                sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    "& > :not(style)": { m: 1 },
                }}
                component="form"
                autoComplete="off"
                onSubmit={onSubmit}
            >
                <Stack spacing={2}>
                    {!!form.deletedAt && (
                        <Typography color="warning.main">
                            {`This trainer was deactivated by ${form.updatedBy} ` +
                                `on ${usDateFormat(form.deletedAt)} and cannot be edited.`}
                        </Typography>
                    )}
                    <Stack direction="row" justifyContent="space-between">
                        <Typography
                            component="h1"
                            variant="h2"
                            title={`ID: ${id || "(New)"}`}
                        >
                            <IconButton
                                size="large"
                                onClick={onClickRefreshStatus}
                                sx={{ verticalAlign: "inherit" }}
                            >
                                <TrainerStatusIcon status={status} />
                            </IconButton>
                            &nbsp;
                            {id
                                ? `${form.name.value} (#${form.clientId.value ?? "?"})`
                                : "New Trainer"}
                        </Typography>
                        <IconButton size="large" component={RouterLink} to="..">
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </Stack>
                {showErrors && form.errorMessage && (
                    <Typography color="error.main">
                        {form.errorMessage}
                    </Typography>
                )}
                <TextField
                    id="name"
                    label="Name"
                    value={form.name.value}
                    disabled={disabled}
                    helperText={showErrors && form.name.error}
                    error={showErrors && !!form.name.error}
                    onChange={(e) =>
                        dispatch({
                            type: "name",
                            value: e.currentTarget.value,
                        })
                    }
                />
                <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                    <TextField
                        type="number"
                        fullWidth
                        id="clientId"
                        label="Trainer ID"
                        value={form.clientId.value}
                        disabled={disabled}
                        helperText={showErrors && form.clientId.error}
                        error={showErrors && !!form.clientId.error}
                        onChange={(e) =>
                            dispatch({
                                type: "clientId",
                                value: parseInt(e.currentTarget.value, 10),
                            })
                        }
                    />
                    <TextField
                        type="text"
                        fullWidth
                        id="referenceNumber"
                        label="Reference Number"
                        value={form.referenceNumber.value}
                        disabled={disabled}
                        helperText={showErrors && form.referenceNumber.error}
                        error={showErrors && !!form.referenceNumber.error}
                        onChange={(e) =>
                            dispatch({
                                type: "referenceNumber",
                                value: e.currentTarget.value || "",
                            })
                        }
                    />
                </Stack>
                <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                    <LocationAutocomplete
                        value={form.locationId.value || null}
                        onChange={(value: LocationWithRelations | null) =>
                            dispatch({
                                type: "locationId",
                                value: value?.id || 0,
                            })
                        }
                        error={showErrors ? form.locationId.error : undefined}
                        fullWidth
                    />
                    <FormControl fullWidth disabled={disabled}>
                        <InputLabel id="availabilityLabel">
                            Availability
                        </InputLabel>
                        <Select
                            labelId="availabilityLabel"
                            id="availability"
                            value={form.availability.value || ""}
                            label="Availability"
                            onChange={({ target: { value } }) =>
                                dispatch({
                                    type: "availability",
                                    value: value || null,
                                })
                            }
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {AVAILABILITIES.map((a) => (
                                <MenuItem key={a} value={a}>
                                    {a}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                    <FormControl fullWidth disabled={disabled}>
                        <InputLabel id="modelIdLabel">Model</InputLabel>
                        <Select
                            labelId="modelIdLabel"
                            id="modelId"
                            value={
                                models.length ? form.modelId.value || "" : ""
                            }
                            label="Model"
                            onChange={({ target: { value } }) =>
                                dispatch({
                                    type: "modelId",
                                    value:
                                        typeof value === "string"
                                            ? parseInt(value, 10)
                                            : value,
                                })
                            }
                        >
                            {models.map((m) => (
                                <MenuItem
                                    key={m.id}
                                    value={m.id}
                                    title={m.description}
                                >
                                    {`${m.name} (${m.description})`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth disabled={disabled}>
                        <InputLabel id="controlPlatformIdLabel">
                            Control Platform
                        </InputLabel>
                        <Select
                            labelId="controlPlatformIdLabel"
                            id="controlPlatformId"
                            value={
                                controlPlatforms.length
                                    ? form.controlPlatformId.value
                                    : ""
                            }
                            label="Control Platform"
                            onChange={({ target: { value } }) =>
                                dispatch({
                                    type: "controlPlatformId",
                                    value,
                                })
                            }
                        >
                            {controlPlatforms.map((p) => (
                                <MenuItem key={p.id} value={p.id}>
                                    {p.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth disabled={disabled}>
                        <InputLabel id="visionPlatformIdLabel">
                            Vision Platform
                        </InputLabel>
                        <Select
                            labelId="visionPlatformIdLabel"
                            id="visionPlatformId"
                            value={
                                visionPlatforms.length
                                    ? form.visionPlatformId.value
                                    : ""
                            }
                            label="Vision Platform"
                            onChange={({ target: { value } }) =>
                                dispatch({
                                    type: "visionPlatformId",
                                    value,
                                })
                            }
                        >
                            {visionPlatforms.map((p) => (
                                <MenuItem key={p.id} value={p.id}>
                                    {p.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
                <TrainerSoftwareEdit
                    form={form}
                    disabled={disabled}
                    setControlTagIds={(value) =>
                        dispatch({ type: "controlTagIds", value })
                    }
                    setActiveControlTagId={setActiveControlTagId}
                    setActiveVisionTagId={setActiveVisionTagId}
                />
                <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                    <TextField
                        id="notes"
                        label="Notes"
                        value={form.notes.value}
                        disabled={disabled}
                        helperText={showErrors && form.notes.error}
                        error={showErrors && !!form.notes.error}
                        onChange={(e) =>
                            dispatch({
                                type: "notes",
                                value: e.currentTarget.value,
                            })
                        }
                        multiline
                        fullWidth
                        maxRows={4}
                    />
                    {!isNew && (
                        <Stack
                            spacing={2}
                            direction={{ xs: "column", sm: "row" }}
                            sx={{ width: "100%" }}
                            alignItems="center"
                            justifyContent="space-around"
                        >
                            <TrainerEditMute
                                trainerId={id}
                                mutedUntil={form.mutedUntil.value}
                                disabled={disabled}
                                onChangeMutedUntil={(value) =>
                                    dispatch({
                                        type: "mutedUntil",
                                        value,
                                    })
                                }
                                onError={(value) =>
                                    dispatch({
                                        type: "errorMessage",
                                        value,
                                    })
                                }
                            />
                            <Button
                                sx={{ mt: 1 }}
                                startIcon={<StreamIcon />}
                                component={RouterLink}
                                to={`../../trainer-event-streams/${form.clientId.value}`}
                            >
                                Live View
                            </Button>
                        </Stack>
                    )}
                </Stack>
                <Stack
                    spacing={desktopMatch ? 2 : undefined}
                    direction={desktopMatch ? "row" : "column"}
                    alignItems={desktopMatch ? "center" : undefined}
                    justifyContent="flex-end"
                >
                    <AdminSaveButton
                        type="submit"
                        variant="contained"
                        saveState={form.saveState}
                        disabled={disabled}
                    />
                    <Button component={RouterLink} to="..">
                        Cancel
                    </Button>
                    {!isNew && !form.deletedAt && (
                        <Button
                            variant="contained"
                            color="warning"
                            onClick={onDeactivate}
                        >
                            Deactivate
                        </Button>
                    )}
                    {!isNew && !form.deletedAt && (
                        <>
                            <FormControlLabel
                                value="top"
                                control={
                                    <Switch
                                        checked={outOfOrderOpen}
                                        disabled={outOfOrderOpen === undefined}
                                        color="error"
                                        size="medium"
                                        onChange={() =>
                                            setOutOfOrderAlert(!outOfOrderAlert)
                                        }
                                    />
                                }
                                label={
                                    outOfOrderOpen ? (
                                        <Tooltip
                                            title={
                                                <span
                                                    style={{
                                                        fontSize: "1.5em",
                                                    }}
                                                >
                                                    {`${outOfOrderBy} on ${usDateFormat(outOfOrderAt ?? 0)}`}
                                                </span>
                                            }
                                        >
                                            <Typography
                                                sx={{
                                                    borderBottom:
                                                        "1px dotted #000",
                                                }}
                                            >
                                                Out of order
                                            </Typography>
                                        </Tooltip>
                                    ) : (
                                        "Out of Order"
                                    )
                                }
                                labelPlacement="top"
                                sx={{
                                    minWidth: 163,
                                    marginTop: desktopMatch ? 0 : 1,
                                }}
                            />
                            <AlertDialog
                                open={outOfOrderAlert}
                                dialogContentText={
                                    outOfOrderOpen
                                        ? "This will clear out of order. " +
                                          "Users will be able to connect to their trainer."
                                        : "This will prevent any users from " +
                                          "connecting with this trainer. Are you sure?"
                                }
                                dialogTitleText="Trainer Out of Order"
                                handleClose={() => {
                                    setOutOfOrderAlert(false);
                                    setOutOfOrderSubmitError(null);
                                }}
                                handleSubmit={handleOutOfOrderSubmit}
                                errorMsg={outOfOrderSubmitError}
                            />
                        </>
                    )}
                </Stack>
            </Paper>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <TrainerNetworkConfigure trainerId={id} status={status} />
            </Paper>
            {status && (
                <>
                    <Paper
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <TrainerEditControls {...status} />
                    </Paper>
                    {status.ready === "MAINTENANCE" && (
                        <Paper
                            sx={{
                                p: 2,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <TrainerSelfTestControls {...status} />
                        </Paper>
                    )}
                    <Paper
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <TrainerStatusDetails {...status} />
                    </Paper>
                </>
            )}
            {status && showSettings && (
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <TrainerSettings
                        trainerId={status.clientId}
                        onError={onError}
                    />
                </Paper>
            )}
            {!status && statusSnapshot && (
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <TrainerStatusSnapshotDetails
                        status={statusSnapshot.data as Partial<CoachStatus>}
                        updatedAt={new Date(statusSnapshot.updatedAt)}
                    />
                </Paper>
            )}
            {!isNew && (
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <TrainerUpdateDownloadStatus trainerId={id} />
                </Paper>
            )}
            {!isNew && (
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <TrainerStatsView trainerId={id} />
                </Paper>
            )}
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <SnapshotsGrid
                    entity="trainer"
                    id={form.clientId.value}
                    title="Snapshots"
                />
            </Paper>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                {showSessions && id ? (
                    <Stack spacing={1}>
                        <Typography variant="h2">Sessions</Typography>
                        <SessionsTable trainerId={id} initialRowsPerPage={10} />
                    </Stack>
                ) : (
                    <Button color="info" onClick={() => setShowSessions(true)}>
                        View Sessions
                    </Button>
                )}
            </Paper>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                {showFailures && id ? (
                    <FailuresTable entity="trainer" id={id} />
                ) : (
                    <Button color="info" onClick={() => setShowFailures(true)}>
                        View Failures
                    </Button>
                )}
            </Paper>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                {showTestReports && id ? (
                    <TestReportTable trainerId={id} />
                ) : (
                    <Button
                        color="info"
                        onClick={() => setShowTestReports(true)}
                    >
                        View Self Test Results
                    </Button>
                )}
            </Paper>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                {showVisionSettings && id ? (
                    <VisionSettingsTable entity="trainer" id={id} />
                ) : (
                    <Button
                        color="info"
                        onClick={() => setShowVisionSettings(true)}
                    >
                        View Vision Settings
                    </Button>
                )}
            </Paper>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                {showIssueReport && id ? (
                    <IssueReportTable trainerId={id} />
                ) : (
                    <Button
                        color="info"
                        onClick={() => setShowIssueReport(true)}
                    >
                        View User Issue Reports
                    </Button>
                )}
            </Paper>
        </Stack>
    );
}
