import * as React from "react";
import { Link as RouterLink, useParams } from "react-router";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import type {
    SessionWithDeepRelations,
    User,
    ClipWithRelationsJson,
    ImageWithRelationsJson,
} from "@volley/data";
import type { CoachStatus, FailureType } from "@volley/shared/coach-models";
import type { TrainerEvent } from "@volley/shared/events/trainer-event";

import logger from "../../../log";
import { fetchApi, usDateFormat } from "../../../util";
import * as buildLabel from "../../../util/buildLabel";
import { logFetchError } from "../../../util/fetchApi";

import TrainerCellularStatus from "./TrainerCellularStatus";
import TrainerSoftwareVersionDisplay from "./TrainerSoftwareVersionDisplay";
import TrainerStatusDetails from "./TrainerStatusDetails";
import TrainerStatusIcon from "./TrainerStatusIcon";
import TrainerWeatherConditions from "./TrainerWeatherConditions";
import TrainerWifiStatus from "./TrainerWifiStatus";
import { TrainerWithStatus } from "./types";

interface EventRowProps {
    event: TrainerEvent;
}

function ClipEventDisplay({ id }: { id: number }): React.JSX.Element {
    const [clip, setClip] = React.useState<ClipWithRelationsJson | null>(null);
    React.useEffect(() => {
        fetchApi<ClipWithRelationsJson>(`/api/clips/${id}`)
            .then((data) => {
                setClip(data);
            })
            .catch((err) => logFetchError(err));
    }, [id]);

    if (!clip?.objectStoreFile.url) {
        return (
            <Box
                component="div"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                py={2}
            >
                <CircularProgress size={60} sx={{ my: 2 }} />
            </Box>
        );
    }

    return (
        <video
            autoPlay
            loop
            muted
            controls
            src={clip.objectStoreFile.url}
            style={{
                marginLeft: 8,
                marginRight: 8,
                aspectRatio: "16 / 9",
                maxWidth: "100%",
                border: "1px solid #EAECF2",
                objectFit: "fill",
            }}
        />
    );
}

function ImageEventDisplay({ id }: { id: number }): React.JSX.Element {
    const [image, setImage] = React.useState<ImageWithRelationsJson | null>(
        null,
    );
    React.useEffect(() => {
        fetchApi<ImageWithRelationsJson>(`/api/images/${id}`)
            .then((data) => {
                setImage(data);
            })
            .catch((err) => logFetchError(err));
    }, [id]);

    if (!image?.objectStoreFile.url) {
        return (
            <Box
                component="div"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                py={2}
            >
                <CircularProgress size={60} sx={{ my: 2 }} />
            </Box>
        );
    }

    return (
        <img
            src={image.objectStoreFile.url}
            alt="Snapshot"
            style={{
                marginLeft: 8,
                marginRight: 8,
                maxWidth: "100%",
                border: "1px solid #EAECF2",
                objectFit: "fill",
            }}
        />
    );
}

function SessionEventDisplay({
    id,
    users,
}: {
    id: number;
    users: string[];
}): React.JSX.Element {
    return (
        <>
            <Typography gutterBottom>
                <Link component={RouterLink} to={`/admin/sessions/${id}`}>
                    View Session
                </Link>
            </Typography>
            <Typography gutterBottom>
                <strong>Users: </strong>
                {users.join(", ")}
            </Typography>
        </>
    );
}

function getExpandedEventDisplay(
    event: TrainerEvent,
): React.JSX.Element | null {
    switch (event.data.type) {
        case "clip":
            return <ClipEventDisplay id={event.data.id} />;
        case "failure":
            return (
                <>
                    <Typography gutterBottom>
                        <strong>Message: </strong>
                        {event.data.message}
                    </Typography>
                    <Typography>
                        <Link
                            component={RouterLink}
                            to={`/admin/failures/${event.data.id}`}
                        >
                            View Failure
                        </Link>
                    </Typography>
                </>
            );
        case "sessionEnd":
        case "sessionStart":
            return (
                <SessionEventDisplay
                    id={event.data.id}
                    users={event.data.users}
                />
            );
        case "setHeight":
            return <Typography>{event.data.height.toFixed(2)}</Typography>;
        case "throw":
            return (
                <Grid container spacing={2}>
                    <Grid
                        size={{
                            xs: 6,
                            md: 2,
                        }}
                    >
                        <Typography>
                            <strong>Top RPM: </strong>
                            {event.data.actualRpmTop ?? ""}
                        </Typography>
                    </Grid>
                    <Grid
                        size={{
                            xs: 6,
                            md: 2,
                        }}
                    >
                        <Typography>
                            <strong>Left RPM: </strong>
                            {event.data.actualRpmLeft ?? ""}
                        </Typography>
                    </Grid>
                    <Grid
                        size={{
                            xs: 6,
                            md: 2,
                        }}
                    >
                        <Typography>
                            <strong>Right RPM: </strong>
                            {event.data.actualRpmRight ?? ""}
                        </Typography>
                    </Grid>
                    <Grid
                        size={{
                            xs: 6,
                            md: 2,
                        }}
                    >
                        <Typography>
                            <strong>Pan: </strong>
                            {event.data.actualPan ?? ""}
                        </Typography>
                    </Grid>
                    <Grid
                        size={{
                            xs: 6,
                            md: 2,
                        }}
                    >
                        <Typography>
                            <strong>Tilt: </strong>
                            {event.data.actualTilt ?? ""}
                        </Typography>
                    </Grid>
                    <Grid
                        size={{
                            xs: 6,
                            md: 2,
                        }}
                    >
                        <Typography>
                            <strong>X/Y/Yaw: </strong>
                            {event.data.positionX &&
                            event.data.positionY &&
                            event.data.positionYaw
                                ? `${event.data.positionX.toFixed(1)}/${event.data.positionY.toFixed(1)}/${event.data.positionYaw.toFixed(1)}`
                                : ""}
                        </Typography>
                    </Grid>
                </Grid>
            );
        case "workoutStart":
            return (
                <>
                    <Typography gutterBottom>
                        <strong>Workout: </strong>
                        {event.data.name}
                    </Typography>
                    <Typography>
                        <Link
                            component={RouterLink}
                            to={`/admin/workout-plays/${event.data.id}`}
                        >
                            View Workout
                        </Link>
                    </Typography>
                </>
            );
        case "image":
            return <ImageEventDisplay id={event.data.id} />;
        case "localization-pass":
            return <ImageEventDisplay id={event.data.id} />;
        case "localization-fail":
            return <ImageEventDisplay id={event.data.id} />;
        case "sos":
            return event.data.user ? (
                <Typography>Sent by: {event.data.user}</Typography>
            ) : null;
        case "calibrate":
        case "faultClear":
        case "lowPower":
        case "lowerHead":
        case "powerOff":
        case "powerUp":
        case "workoutPause":
        case "workoutResume":
        case "workoutStop":
        default:
            return null;
    }
}

function getTypeDisplay(data: TrainerEvent["data"]): React.JSX.Element {
    const { type } = data;

    switch (type) {
        case "calibrate":
            return <Chip color="default" label="Calibrate" />;
        case "clip":
            return <Chip color="info" label="Video Clip" />;
        case "failure":
            switch (data.failureType as FailureType) {
                case "BallBinEmpty":
                case "EStop":
                case "RemovedBattery":
                case "HardwareShutdown":
                case "LowBattery":
                case "SafetyIssue":
                    return <Chip color="warning" label={data.failureType} />;
                default:
                    return <Chip color="error" label={data.failureType} />;
            }
        case "faultClear":
            return <Chip color="warning" label="Fault Clear" />;
        case "image":
            return <Chip color="info" label="Image" />;
        case "localization-pass":
            return <Chip color="success" label="Localization: Pass" />;
        case "localization-fail":
            return <Chip color="error" label="Localization: Fail" />;
        case "lowPower":
            return <Chip color="default" label="Low Power Mode" />;
        case "lowerHead":
            return <Chip color="default" label="Lower Head" />;
        case "powerOff":
            return <Chip color="warning" label="Power Off" />;
        case "powerUp":
            return <Chip color="success" label="Power Up" />;
        case "sessionEnd":
            return <Chip color="default" label="Session End" />;
        case "sessionStart":
            return <Chip color="success" label="Session Start" />;
        case "setHeight":
            return <Chip color="default" label="Height Set" />;
        case "sos":
            return <Chip color="error" label={`SOS - ${data.action}`} />;
        case "throw":
            return <Chip color="primary" label="Throw" />;
        case "workoutPause":
            if (data?.source === "ui") {
                return <Chip color="success" label="Workout Pause - UI" />;
            }
            if (data?.source === "gesture") {
                return <Chip color="success" label="Workout Pause - Gesture" />;
            }
            if (data?.source === "hardware") {
                return <Chip color="success" label="Workout Pause - Button" />;
            }
            if (data?.source === "error") {
                return <Chip color="error" label="Workout Pause - Error" />;
            }

            return (
                <Chip
                    color="warning"
                    label={`Workout Pause - ${data.source}`}
                />
            );

        case "workoutResume":
            if (data?.source === "ui") {
                return <Chip color="success" label="Workout Resume - UI" />;
            }
            if (data?.source === "gesture") {
                return (
                    <Chip color="success" label="Workout Resume - Gesture" />
                );
            }
            if (data?.source === "hardware") {
                return <Chip color="success" label="Workout Resume - Button" />;
            }

            return (
                <Chip
                    color="warning"
                    label={`Workout Resume - ${data.source}`}
                />
            );

        case "workoutStart":
            return <Chip color="success" label="Workout Start" />;
        case "workoutStop":
            return <Chip color="warning" label="Workout Stop" />;
        default:
            return <Chip color="default" label={type} />;
    }
}

function EventRow({ event }: EventRowProps): React.JSX.Element {
    const { data, timestamp } = event;
    const [open, setOpen] = React.useState(false);
    const expandedDisplay = getExpandedEventDisplay(event);

    if (!expandedDisplay) {
        return (
            <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell />
                <TableCell>{getTypeDisplay(data)}</TableCell>
                <TableCell title={timestamp.toISOString()}>
                    <Typography variant="body2">
                        {usDateFormat(timestamp)}
                    </Typography>
                </TableCell>
            </TableRow>
        );
    }

    return (
        <>
            <TableRow
                aria-label="expand-row"
                role="button"
                onClick={() => setOpen(!open)}
                sx={{
                    cursor: "pointer",
                    "& > *": { borderBottom: "unset" },
                }}
            >
                <TableCell>
                    <IconButton size="small">
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell>{getTypeDisplay(data)}</TableCell>
                <TableCell title={timestamp.toISOString()}>
                    <Typography variant="body2">
                        {usDateFormat(timestamp)}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box component="div">{expandedDisplay}</Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

function TrainerDetails({
    clientId,
    status: liveStatus,
}: {
    clientId: number;
    status: CoachStatus | null;
}): React.JSX.Element {
    const [open, setOpen] = React.useState(false);
    const [trainer, setTrainer] = React.useState<TrainerWithStatus | null>(
        null,
    );
    const [users, setUsers] = React.useState<User[]>([]);
    const [weatherOpen, setWeatherOpen] = React.useState(false);

    const refreshData = React.useCallback(async () => {
        if (!clientId) return;
        const trainerData = await fetchApi<TrainerWithStatus>(
            `/api/trainers/clientId_${clientId}`,
        );
        setTrainer(trainerData);

        if (trainerData.status?.session?.id) {
            const sessionData = await fetchApi<SessionWithDeepRelations>(
                `/api/sessions/${trainerData.status.session.id}`,
            );
            setUsers(sessionData.sessionUsers.map((su) => su.user));
        }
    }, [clientId]);

    React.useEffect(() => {
        refreshData().catch(() => {});
    }, [refreshData]);

    const onSendSOS = React.useCallback(async () => {
        const sure = window.confirm("Are you sure you want to send an SOS?");
        if (sure && trainer?.id) {
            await fetchApi(`/api/sos/trainers/${trainer.id}/send`, "POST");
        }
    }, [trainer?.id]);

    const onCaptureDebugClip = React.useCallback(async () => {
        if (!trainer?.clientId) return;

        await fetchApi(
            `/trainer/${trainer.clientId}/api/vision/capture`,
            "POST",
            { duration: 10 },
        );
    }, [trainer?.clientId]);

    const status = liveStatus ?? trainer?.status ?? null;

    if (!trainer) {
        return <Typography>Loading trainer info…</Typography>;
    }

    return (
        <Paper sx={{ p: 2, mb: 2 }}>
            <Typography gutterBottom variant="h2">
                <IconButton
                    size="large"
                    onClick={() => refreshData()}
                    sx={{ verticalAlign: "inherit" }}
                >
                    <TrainerStatusIcon status={status} />
                </IconButton>
                <Link
                    component={RouterLink}
                    to={`/admin/trainers/clientId/${clientId}`}
                    title={`View trainer #${clientId}`}
                >
                    {`Trainer #${clientId}`}
                </Link>
            </Typography>
            <Grid container>
                <Grid
                    size={{
                        xs: 12,
                        md: 3,
                    }}
                >
                    <Typography>
                        <strong>User: </strong>
                        {users.map((user) => (
                            <Link
                                key={user.id}
                                component={RouterLink}
                                to={`/admin/users/${user.id}`}
                                title={`View user ${user.username}`}
                            >
                                {`${user.firstName} ${user.lastName}`}
                            </Link>
                        ))}
                    </Typography>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        md: 3,
                    }}
                >
                    <Typography>
                        <strong>Location: </strong>
                        <Link
                            component={RouterLink}
                            to={`/admin/locations/${trainer.locationId}`}
                            title={`View location ${trainer.location.name}`}
                        >
                            {trainer.location.name}
                        </Link>
                    </Typography>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        md: 3,
                    }}
                >
                    <Typography>
                        <strong>Model: </strong>
                        <span title={trainer.trainerModel?.description}>
                            {trainer.trainerModel?.name}
                        </span>
                    </Typography>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        md: 3,
                    }}
                >
                    <Typography>
                        <strong>Control: </strong>
                        <TrainerSoftwareVersionDisplay
                            label={buildLabel.expand(
                                status?.softwareBuildLabel ??
                                    (
                                        trainer.statusSnapshot as Partial<CoachStatus>
                                    )?.softwareBuildLabel,
                            )}
                            truncate
                        />
                    </Typography>
                </Grid>
                <Accordion
                    onChange={() => setWeatherOpen(!weatherOpen)}
                    expanded={weatherOpen}
                    slotProps={{ transition: { unmountOnExit: true } }}
                    sx={{ width: "100%" }}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="button">
                            Current Weather
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TrainerWeatherConditions
                            locationId={trainer.locationId}
                        />
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid container sx={{ mt: 2 }}>
                <Grid
                    mt={1}
                    size={{
                        xs: 12,
                        md: 2,
                    }}
                >
                    <Button
                        variant="contained"
                        color="error"
                        onClick={onSendSOS}
                    >
                        Send SOS
                    </Button>
                </Grid>
                <Grid
                    mt={1}
                    size={{
                        xs: 12,
                        md: 3,
                    }}
                >
                    <Tooltip
                        title={
                            status?.vision.serviceState !== "Running"
                                ? "Vision Service is not running"
                                : ""
                        }
                    >
                        <span>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onCaptureDebugClip}
                                disabled={
                                    status?.vision.serviceState !== "Running"
                                }
                            >
                                Capture Debug Clip
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
            {status && (
                <>
                    <Button
                        aria-label="expand details"
                        size="small"
                        onClick={() => setOpen(!open)}
                        startIcon={
                            open ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )
                        }
                        sx={{ py: 2 }}
                        fullWidth
                    />

                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Stack spacing={2}>
                            <TrainerWifiStatus
                                wifi={status.system.wifi}
                                activeInterface={status.system.activeInterface}
                            />
                            <TrainerCellularStatus
                                cellular={status.system.cellular}
                                activeInterface={status.system.activeInterface}
                            />
                            <TrainerStatusDetails {...status} />
                        </Stack>
                    </Collapse>
                </>
            )}
        </Paper>
    );
}

function MostRecentImage({
    id,
    timestamp,
}: {
    id: number;
    timestamp: Date;
}): React.JSX.Element {
    const [mostRecentImageOpen, setMostRecentImageOpen] = React.useState(false);
    return (
        <Paper
            sx={{
                p: 2,
                mb: 2,
                display: "flex",
                flexDirection: "column",
                justify: "center",
                alignItems: "center",
            }}
        >
            <Button
                aria-label="expand row"
                size="small"
                onClick={() => setMostRecentImageOpen(!mostRecentImageOpen)}
                startIcon={
                    mostRecentImageOpen ? (
                        <KeyboardArrowUpIcon />
                    ) : (
                        <KeyboardArrowDownIcon />
                    )
                }
                sx={{ p: 2, mb: 1 }}
                fullWidth
            >
                <Typography variant="h4">
                    {`Snapshot from ${timestamp.toLocaleString()}`}
                </Typography>
            </Button>
            <Collapse in={mostRecentImageOpen} timeout="auto" unmountOnExit>
                <ImageEventDisplay id={id} />
            </Collapse>
        </Paper>
    );
}

export default function TrainerEventStream(): React.JSX.Element {
    const { clientId: rawClientId } = useParams<"clientId">();
    const clientId = parseInt(rawClientId ?? "", 10);
    const [events, setEvents] = React.useState<TrainerEvent[]>([]);
    const [status, setStatus] = React.useState<CoachStatus | null>(null);
    const [reconnectAttempts, setReconnectAttempts] = React.useState(0);
    const [mostRecentImage, setMostRecentImage] =
        React.useState<TrainerEvent | null>(null);
    const [errorMessage, setErrorMessage] = React.useState("");

    React.useEffect(() => {
        let eventSrc: EventSource | undefined;
        if (clientId) {
            eventSrc = new EventSource(`/api/events/trainer/${clientId}`);
            eventSrc.onopen = () => {
                setEvents([]);
            };
            eventSrc.onmessage = (evt: MessageEvent<string>) => {
                const parsedData = JSON.parse(evt.data) as TrainerEvent;
                parsedData.timestamp = new Date(parsedData.timestamp);

                const { type } = parsedData.data;

                if (type === "status") {
                    setStatus(parsedData.data.status);
                } else if (type === "close") {
                    eventSrc?.close();
                } else {
                    if (type === "image") {
                        setMostRecentImage(parsedData);
                    }

                    setEvents((current) => {
                        if (current.length >= 100) {
                            current.pop();
                        }
                        return [parsedData, ...current].sort(
                            (a, b) =>
                                b.timestamp.getTime() - a.timestamp.getTime(),
                        );
                    });
                }
            };
            eventSrc.onerror = () => {
                eventSrc?.close();
                if (reconnectAttempts < 5) {
                    setTimeout(
                        () => {
                            logger.info(
                                "Attempting to reconnect to trainer event stream",
                            );
                            setReconnectAttempts((current) => current + 1);
                        },
                        reconnectAttempts + 1 * 2000,
                    );
                } else {
                    setErrorMessage(
                        "Connection to event stream loss. Please refresh the page to try again.",
                    );
                    logger.warn(
                        "Could not reconnect to trainer event stream after 5 attempts",
                    );
                }
            };
        }
        return () => {
            eventSrc?.close();
        };
    }, [clientId, reconnectAttempts]);

    return (
        <>
            <TrainerDetails clientId={clientId} status={status} />
            {mostRecentImage?.data.type === "image" && (
                <MostRecentImage
                    id={mostRecentImage.data.id}
                    timestamp={mostRecentImage.timestamp}
                />
            )}
            <Paper sx={{ p: 2 }}>
                <Typography gutterBottom variant="h2">
                    Events
                </Typography>
                {errorMessage && (
                    <Typography gutterBottom color="error.main" align="center">
                        {errorMessage}
                    </Typography>
                )}
                {events.length ? (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Event</TableCell>
                                    <TableCell>Time</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {events.map((event) => (
                                    <EventRow key={event.id} event={event} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography align="center">
                        Waiting for events from trainer…
                    </Typography>
                )}
            </Paper>
        </>
    );
}
