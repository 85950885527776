import * as React from "react";
import { Link } from "react-router";

import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import type { ContentProvider } from "@volley/data";

import { usDateFormat } from "../../../util";
import usePaginatedData from "../../hooks/usePaginatedData";

export default function ContentProvidersTable(): React.JSX.Element {
    const {
        rowsPerPage,
        onRowsPerPageChange,
        count,
        page,
        onPageChange,
        data: contentProviders,
    } = usePaginatedData<ContentProvider>("/api/content-providers", {
        id: "content-providers",
    });

    return (
        <Grid container spacing={3}>
            <Grid size={12}>
                <Typography component="h1" variant="h2">
                    Content Providers
                </Typography>
            </Grid>
            <Grid size={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <Box component="div">
                        <Button
                            variant="contained"
                            component={Link}
                            to="new"
                            startIcon={<AddIcon />}
                        >
                            New Content Provider
                        </Button>
                    </Box>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Name</TableCell>
                                    <TableCell>Label</TableCell>
                                    <TableCell>Workout Count</TableCell>
                                    <TableCell>Created By</TableCell>
                                    <TableCell>Created At</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {contentProviders.map((f) => (
                                    <TableRow key={f.id}>
                                        <TableCell>
                                            <Button
                                                component={Link}
                                                to={f.id.toString()}
                                            >
                                                View
                                            </Button>
                                        </TableCell>
                                        <TableCell>{f.name}</TableCell>
                                        <TableCell>{f.label}</TableCell>
                                        <TableCell>
                                            {f._count.appWorkouts}
                                        </TableCell>
                                        <TableCell>{f.createdBy}</TableCell>
                                        <TableCell>
                                            {usDateFormat(
                                                new Date(f.createdAt),
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        colSpan={6}
                                        rowsPerPage={rowsPerPage}
                                        count={count}
                                        page={page}
                                        onPageChange={onPageChange}
                                        onRowsPerPageChange={
                                            onRowsPerPageChange
                                        }
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        </Grid>
    );
}
