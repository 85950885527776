import * as React from "react";

import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { endOfDay, startOfDay, subMonths } from "date-fns";
import { toZonedTime, fromZonedTime } from "date-fns-tz";

import ReportLocationParameter from "./ReportsLocationParameter";

type ExtraParameterName = "locationId";
type ExtraParameterValue = string | null;
type ExtraParameters = Record<ExtraParameterName, ExtraParameterValue>;

interface ExtraParameterProps {
    value: ExtraParameterValue;
    onChange: (value: ExtraParameterValue) => void;
}

interface ExtraParameter {
    name: ExtraParameterName;
    Component: React.FunctionComponent<ExtraParameterProps>;
    required?: boolean;
}

interface ReportType {
    id: string;
    name: string;
    url: string;
    note?: string;
    extraParameters?: ExtraParameter[];
}

const REPORT_TYPES: ReportType[] = [
    {
        id: "locations",
        name: "Locations",
        url: "/api/reports/locations",
    },
    {
        id: "user",
        name: "Users",
        url: "/api/reports/users",
    },
    {
        id: "workout-play",
        name: "Workout Plays",
        url: "/api/reports/workout-plays",
        note: "This report will return only the first 1,000 results. Please contact engineering if you need more data.",
    },
    {
        id: "Feedback",
        name: "User Feedback",
        url: "/api/reports/feedback",
    },
    {
        id: "invites",
        name: "User Invites",
        url: "/api/reports/invites",
    },
    {
        id: "shared-workouts",
        name: "Shared Workouts",
        url: "/api/reports/shared-workouts",
    },
    {
        id: "PTI Feedback",
        name: "PTI Feedback",
        url: "/api/reports/feedback-pti",
    },
    {
        id: "pti-users",
        name: "PTI Users",
        url: "/api/reports/pti-users",
    },
    {
        id: "platform-players",
        name: "Platform Players",
        url: "/api/reports/platform-players",
    },
    {
        id: "location-sessions",
        name: "Location Sessions",
        url: "/api/reports/location-sessions",
        extraParameters: [
            {
                name: "locationId",
                Component: ReportLocationParameter,
                required: true,
            },
        ],
    },
    {
        id: "issue-reports",
        name: "User Reported Issues",
        url: "/api/reports/issue-reports",
    },
];

/**
 * Treat selected date as Chicago time and get ISO string for API
 *
 * For reporting we're using Chicago time zone as our common time zone. We need
 * to treat the user selected time as if it were Chicago time but because JS
 * will always create the time in the user's system time zone we need to do a
 * conversion. First treat date as Chicago and convert to UTC then convert
 * back to local time to get equivalent Chicago date in local tz.
 *
 *   e.g. user selects 2022-07-07 00:00:00 we want to get:
 *      Eastern -> 2022-07-07 01:00:00
 *      Pacific -> 2022-07-06 22:00:00
 *      UTC     -> 2022-07-07 05:00:00 (during daylight savings) * @param date
 */
const transformDate = (date: Date): string => {
    const currentUserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const baseTz = "America/Chicago";

    const utc = fromZonedTime(date, baseTz);
    const localTime = toZonedTime(utc, currentUserTz);

    return localTime.toISOString();
};

export default function ReportsPage(): React.JSX.Element {
    const [selectedReportTypeId, setSelectedReportTypeId] = React.useState(
        REPORT_TYPES[0].id,
    );

    const [startDate, setStartDate] = React.useState<Date>(
        startOfDay(subMonths(new Date(), 1)),
    );
    const [endDate, setEndDate] = React.useState<Date>(endOfDay(new Date()));
    const [extraParameters, setExtraParameters] = React.useState(
        {} as ExtraParameters,
    );

    const selectedReportType = REPORT_TYPES.find(
        (rt) => rt.id === selectedReportTypeId,
    );

    const reportUrl = React.useMemo<string | null>(() => {
        if (!selectedReportType) return null;

        const filteredExtraParameters = Object.entries(extraParameters).reduce(
            (acc, entry) => {
                const [k, v] = entry;
                if (v !== null && v.length) {
                    acc[k] = v;
                }
                return acc;
            },
            {} as Record<string, string>,
        );
        const extraParametersCount = Object.keys(
            filteredExtraParameters,
        ).length;
        const requiredExtraParametersCount =
            selectedReportType.extraParameters?.filter((rt) => rt.required)
                .length ?? 0;
        if (extraParametersCount < requiredExtraParametersCount) return null;

        const params = new URLSearchParams({
            startDate: transformDate(startDate),
            endDate: transformDate(endDate),
            ...filteredExtraParameters,
        });
        return `${selectedReportType?.url}?${params.toString()}`;
    }, [selectedReportType, startDate, endDate, extraParameters]);

    const onDateChange = (type: "start" | "end", date: Date) => {
        if (type === "start") {
            const start = startOfDay(date);
            setStartDate(start);
        } else {
            const end = endOfDay(date);
            setEndDate(end);
        }
    };

    const onExtraParameterChange = (
        name: ExtraParameterName,
        value: string | null,
    ) => {
        setExtraParameters((current) => ({
            ...current,
            [name]: value,
        }));
    };

    return (
        <Stack spacing={3}>
            <Typography component="h1" variant="h2">
                Reports
            </Typography>
            <Paper
                sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    "& > :not(style)": { m: 1 },
                }}
                component="form"
                autoComplete="off"
            >
                <FormControl>
                    <InputLabel id="reportTypeLabel">Report Type</InputLabel>
                    <Select
                        labelId="reportTypeLabel"
                        id="reportType"
                        label="Report Type"
                        onChange={({ target: { value } }) => {
                            setExtraParameters({} as ExtraParameters);
                            setSelectedReportTypeId(value);
                        }}
                        value={selectedReportTypeId}
                    >
                        {REPORT_TYPES.map((report) => (
                            <MenuItem key={report.id} value={report.id}>
                                {report.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Typography variant="caption">
                    (dates are in Chicago time)
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Start Date"
                        value={startDate}
                        maxDate={endDate}
                        onChange={(value?: Date | null) =>
                            onDateChange("start", value ?? new Date())
                        }
                    />
                    <DatePicker
                        label="End Date"
                        value={endDate}
                        minDate={startDate}
                        onChange={(value?: Date | null) =>
                            onDateChange("end", value ?? new Date())
                        }
                    />
                </LocalizationProvider>
                {selectedReportType?.extraParameters?.map(
                    ({ name, Component }) => (
                        <Component
                            key={name}
                            value={extraParameters[name]}
                            onChange={(value) =>
                                onExtraParameterChange(name, value)
                            }
                        />
                    ),
                )}
                {selectedReportType?.note && (
                    <Typography variant="caption" color="warning.main">
                        {selectedReportType.note}
                    </Typography>
                )}
                <Button
                    component="a"
                    variant="contained"
                    disabled={reportUrl === null}
                    href={reportUrl ?? undefined}
                    download
                >
                    Generate
                </Button>
            </Paper>
        </Stack>
    );
}
