import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface Props {
    title: string;
    children: React.ReactNode;
    onClose: (result: boolean) => void;
    open?: boolean;
    confirmButtonText?: string;
    cancelButtonText?: string;
}

export default function ConfirmationDialog({
    title,
    children,
    onClose,
    open = false,
    confirmButtonText = "Yes",
    cancelButtonText = "No",
}: Props): React.JSX.Element {
    return (
        <Dialog
            open={open ?? false}
            onClose={() => onClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(false)}>
                    {cancelButtonText}
                </Button>
                <Button onClick={() => onClose(true)}>
                    {confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
