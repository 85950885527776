import * as React from "react";
import { useNavigate } from "react-router";

import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

import { CursorResult } from "@volley/data";
import type { TrainerSearchResult } from "@volley/data";

import { fetchApi } from "../../../util";
import { logFetchError } from "../../../util/fetchApi";
import useDebounce from "../../hooks/useDebounce";

export default function TrainerSearch(): React.JSX.Element {
    const navigate = useNavigate();
    const [q, setQ] = React.useState("");
    const debouncedQ = useDebounce(q, 400);
    const [loading, setLoading] = React.useState(false);
    const [trainers, setTrainers] = React.useState<TrainerSearchResult[]>([]);

    React.useEffect(() => {
        async function searchTrainers() {
            setLoading(true);
            try {
                const res = await fetchApi<CursorResult<TrainerSearchResult>>(
                    `/api/trainers/search?take=10&q=${encodeURIComponent(debouncedQ.trim())}`,
                );
                setTrainers(res.result);
            } finally {
                setLoading(false);
            }
        }

        if (debouncedQ.length) {
            searchTrainers().catch((err: Error) => logFetchError(err));
        } else {
            setTrainers([]);
        }
    }, [debouncedQ]);

    return (
        <Autocomplete
            id="trainer-search"
            autoHighlight
            loading={loading}
            options={trainers}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            getOptionLabel={(o) => `#${o.clientId} ${o.location}`}
            filterOptions={(v) => v}
            noOptionsText="Begin typing to search"
            onChange={(e, value) => {
                if (value) navigate(`/admin/trainers/${value.id}`);
            }}
            onInputChange={(_, v) => setQ(v)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Search for trainers"
                    slotProps={{
                        input: {
                            ...params.InputProps,
                            type: "search",
                            endAdornment: (
                                <>
                                    {loading ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : (
                                        <SearchIcon />
                                    )}
                                </>
                            ),
                        },
                    }}
                />
            )}
        />
    );
}
