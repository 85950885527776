import * as React from "react";
import { Link as RouterLink } from "react-router";

import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { usDateFormat } from "../../../util";
import usePaginatedData from "../../hooks/usePaginatedData";

import { sessionFromJson } from "./util";

interface Props {
    userId?: number;
    locationId?: number;
    trainerId?: number;
    initialRowsPerPage?: number;
}

export default function SessionsTable({
    userId,
    locationId,
    trainerId,
    initialRowsPerPage,
}: Props): React.JSX.Element {
    const params = React.useMemo(() => {
        const newParams: {
            userId?: string;
            locationId?: string;
            trainerId?: string;
        } = {};
        if (userId) newParams.userId = String(userId);
        if (locationId) newParams.locationId = String(locationId);
        if (trainerId) newParams.trainerId = String(trainerId);
        return Object.keys(newParams).length > 0 ? newParams : undefined;
    }, [userId, locationId, trainerId]);

    const {
        rowsPerPage,
        onRowsPerPageChange,
        count,
        page,
        onPageChange,
        data: sessions,
    } = usePaginatedData("/api/sessions", {
        id: "sessions",
        params,
        initialRowsPerPage: initialRowsPerPage ?? 50,
        mapFn: sessionFromJson,
    });

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Location</TableCell>
                        <TableCell>Start</TableCell>
                        <TableCell>End</TableCell>
                        <TableCell>Created By</TableCell>
                        <TableCell>Created At</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sessions.map((s) => (
                        <TableRow key={s.id}>
                            <TableCell>
                                <Button
                                    component={RouterLink}
                                    to={`/admin/sessions/${s.id.toString()}`}
                                >
                                    View
                                </Button>
                            </TableCell>
                            <TableCell>{s.location?.name ?? ""}</TableCell>
                            <TableCell>{usDateFormat(s.startTime)}</TableCell>
                            <TableCell>
                                {s.endTime ? usDateFormat(s.endTime) : ""}
                            </TableCell>
                            <TableCell>{s.createdBy}</TableCell>
                            <TableCell>{usDateFormat(s.createdAt)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            colSpan={6}
                            rowsPerPage={rowsPerPage}
                            count={count}
                            page={page}
                            onPageChange={onPageChange}
                            onRowsPerPageChange={onRowsPerPageChange}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}
